import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationService } from '../../services';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { OrganizationStateList } from '../../../entity';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';

@Component({
  selector: 'app-admin-organizations-list',
  templateUrl: './admin-organizations-list.component.html',
  styleUrls: ['./admin-organizations-list.component.scss'],
  providers: [OrganizationService]
})
export class AdminOrganizationsListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private organizationsService: OrganizationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,

    private router: Router) { }
  datasource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns = ["name", "state", "remove"];
  stateList = OrganizationStateList;
  loadOrganizations() {

    this.organizationsService.getAllAdmin().then((data: any) => {
      this.datasource.data = data;

    }).catch(error => console.log(error));
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.loadOrganizations();
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }

  deleteOrganization(organizationId) {

    const dialogRef = this.dialog.open(ConfirmationDialog, { disableClose: false });
    dialogRef.componentInstance.confirmMessage = "Are you sure to delete the organization...?";

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.organizationsService.deleteOrganization(organizationId).subscribe(() => {
          this.snackBar.open("Organization deleted", null, {
            duration: 1000,
            verticalPosition: 'top'
          });
          this.loadOrganizations()
        }, () => {
          this.snackBar.open("Failed to delete organization", null, {
            duration: 1000,
            verticalPosition: 'top'
          });
        });
    });
  }

  addOrganization() {
 
    this.organizationsService.createEmpty().subscribe((org) => {
      this.snackBar.open("Organization created", null, {
        duration: 1000,
        verticalPosition: 'top'
      });

      this.router.navigate(["/admin/organization/edit/" + org.id]);
    }, () => {
      this.snackBar.open("Failed to create organization", null, {
        duration: 1000,
        verticalPosition: 'top'
      });

    });


  }

  showOrganizationForm(organizationId) {
    this.router.navigate(["/admin/organization/edit/" + organizationId]);
  }

}
