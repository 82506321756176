import { Component, OnInit } from "@angular/core";
import { TechnologyCategoryService } from "../../../services/TechnologyCategoryService";
import { ShowroomViewModel } from "./showroom.view-model";

@Component({
	selector: "showroom-view",
	templateUrl: "./showroom.component.html",
	styleUrls: ["./showroom.component.scss"],
})
export class ShowroomComponent implements OnInit {

	public flexbasis = "50%";
	public viewModel: ShowroomViewModel = new ShowroomViewModel();

	private constructor(private technologyCategoryService: TechnologyCategoryService) {}

	public ngOnInit(): void {
		this.ngOnInitAsync().catch((reason: Error): void => {
			console.error(reason);
		});
	}

	public async ngOnInitAsync(): Promise<void> {
		this.viewModel = await this.technologyCategoryService.getShowroomComponent();
	}

}
