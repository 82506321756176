import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { List } from "linqts";
import { ApplicationType } from "../../entity/ApplicationType";
import { Technology } from "../../entity/Technology";
import { TechnologyCategory } from "../../entity/TechnologyCategory";

import { HomeCarouselViewModel } from "../components/home/home-carousel/home-carousel.view-model";

import { environment } from '../../environments/environment';
import { AbstractCRUDService } from "./AbstractCRUDService";

@Injectable()
export class TechnologyService extends AbstractCRUDService<Technology, number> {

  public static actionUrl: string= environment.api_server + 'technologies';

  constructor(private http: HttpClient) {
    super(http);
  }

	public async getAll(): Promise<Technology[]> {
		return await 
			this.http.get<Technology[]>(TechnologyService.actionUrl + '?projection=technologyInline')
        .pipe(map((result:any) => {
          return result
        }))
        .toPromise();
	}

	public async getItem(id: number): Promise<Technology> {
		return await this.http.get<Technology>(TechnologyService.actionUrl+`/${id}?projection=technologyInline`).toPromise();
	}

	public async getAllByTechnologyCategory(technologyCategory: TechnologyCategory): Promise<Technology[]> {
		return (await this.getAll()).filter((value: Technology) => {
			return value.technologyCategories.filter((value2: TechnologyCategory) => { 
				return value2.id === technologyCategory.id
			}).length > 0;
		});
	}

	public async getAllByApplicationType(applicationType: ApplicationType): Promise<Technology[]> {
		return (await this.getAll()).filter((value: Technology) => {
			value.applicationTypes = value.applicationTypes.filter((value2: ApplicationType) =>
				value2.id === applicationType.id);
			return value.applicationTypes.length > 0;
		});
	}

	public async getByTechnologyCategoryApplicationTypeIdsArray(technologyCategory: TechnologyCategory, applicationTypeIds: number[]): Promise<Technology[]> {
		const request: Technology[] = await this.getAllByTechnologyCategory(technologyCategory);
		return new List(request).Where((value: Technology) => {
			for (const i of value.applicationTypes) {
				for (const int of applicationTypeIds) {
					if (i.id === int) { return true; }
				}
			}
			return false;
		}).ToArray().sort((a: Technology, b: Technology) => b.applicationTypes.length - a.applicationTypes.length);
	}

	public async getByApplicationTypeTechnologyCategoryIdsArray(applicationType: ApplicationType, technologyCategoryIds: number[]): Promise<Technology[]> {
		const request: Technology[] = await this.getAllByApplicationType(applicationType);
		return new List(request).Where((value: Technology) => {
			for (const i of value.technologyCategories) {
				for (const int of technologyCategoryIds) {
					if (i.id === int) { return true; }
				}
			}
			return false;
		}).ToArray().sort((a: Technology, b: Technology) => b.technologyCategories.length - a.technologyCategories.length);
	}

	public async getHomeCarouselComponent(): Promise<HomeCarouselViewModel[]> {
		let result: Technology[] = await this.getAll();
		result = result.filter((value: Technology): boolean => value.showInCarousel && value.carouselImageUrl !== undefined);
		return result.map((technology: Technology): HomeCarouselViewModel =>
			({
				carouselImageUrl: technology.carouselImageUrl,
				technologyCategoryId: technology.technologyCategories[0].id,
			}));
	}

	public createItem(): Observable<Technology> {
		return this.http.post<any>(environment.api_server + 'technologies', {name: '', 'cardImage': '/assets/images/no_image.jpg'});
	}

	public updateItem(technology: Technology): Observable<Technology> {
		return this.http.put<any>(TechnologyService.actionUrl + '/' + technology.id, technology);
	}
		
	public deleteItem(id: number): Observable<any> {
		return this.http.delete(TechnologyService.actionUrl + '/' + id);
	}

	public async getImages(id: number): Promise<string[]> {
		return await this.http.get<Technology>(`${TechnologyService.actionUrl}/${id}?projection=technologyInline`)
			.pipe(map((result: Technology) => { return result.images })).toPromise();
	}
	
	public addImage(id: number, img: string): Observable<any> {
		return this.http.put(TechnologyService.actionUrl + '/' + id + '/image/'+img,null);
	}
	
	public deleteImage(id: number, img: string): Observable<any> {
		return this.http.delete(TechnologyService.actionUrl + '/' + id + '/image?img=' +  encodeURIComponent(img));
	}
	
}
