<h1>Edit organization</h1>
<mat-card>
	<mat-card-header>
		<div class="state" [ngStyle]="{ 'border-color': status_color }" *ngIf="!isAdmin">			
			Organization status is: {{status_msg}}
		</div>

		<!-- <div class="state" [ngStyle]="{ 'border-color': status_color }" *ngIf="isAdmin && !isNew" style="display: flex; -->
		<div class="state" [ngStyle]="{ 'border-color': status_color }" *ngIf="isAdmin" style="display: flex;
		justify-content: space-between;">



		<mat-form-field class="detail-field ninety-percent" style="    width: 70%;">
				<mat-label>Status:</mat-label>
				<mat-select [(value)]="status">
					<mat-option *ngFor="let option of stateList; let i = index" [value]="option.status">
						{{option.msg}}
					</mat-option>
				</mat-select>
		</mat-form-field>


		<button (click)="changeStatus()" class="button"> Change status</button>

		</div>

	</mat-card-header>
	<mat-card-content>
		<mat-tab-group selectedIndex="0">

			<mat-tab label="Details">
				<form class="detail-form" [formGroup]="profileForm">

					<div style="min-height: 625px">
						<span class="left-side">

							<mat-form-field class="detail-field ninety-percent">
								<input matInput placeholder="Name" formControlName="name" required>
							</mat-form-field>

							<mat-form-field class="detail-field ninety-percent">

									<label class="custom-label">Logo</label>
									<input matInput placeholder="" formControlName="logo"  class="uploadFile upload-label" readonly (click)="logoFile.click()">           
									<button class="button" (click)="logoFile.click()">Add image</button>         
									  <input #logoFile id="logoFile" type="file" class="uploadFile" (change)="addLogoTypeImage()" />
									  <img class="img-preview" [src]="organizationService.dynamicUri(profileForm.get('logo').value)" />


							</mat-form-field>
						
							
						

						</span>
						<span class="right-side">

							<mat-form-field class="detail-field ninety-percent">
								<mat-label>Typologies:</mat-label>
								<mat-select formControlName="typologies" multiple>
									<mat-option *ngFor="let option of typologiesList; let i = index" [value]="option">
										{{option}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</span>

						<mat-form-field class="detail-field ninety-five-percent">
							<textarea matInput placeholder="Description" formControlName="description" style="min-height: 5em"></textarea>
						</mat-form-field>

					</div>
					<mat-action-list>
						<button mat-list-item class="button" (click)="updateDetails()"> Update </button>
					</mat-action-list>
	
				</form>
	
			</mat-tab>

			<mat-tab label="Contacts">
				<div style="min-height: 635px">
					<mat-table #table [dataSource]="contactList" matSort slimScroll width="auto" height="450px" color="rgba(0,0,0,0.3)" size="3px">
						<!-- modelName Column -->
						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
						</ng-container>

						<!-- Position Column -->
						<ng-container matColumnDef="position">
							<mat-header-cell *matHeaderCellDef mat-sort-header> Position </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.position}}</mat-cell>
						</ng-container>

						<!-- Email Column -->
						<ng-container matColumnDef="email">
							<mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
						</ng-container>

						<!-- Phone Column -->
						<ng-container matColumnDef="phone">
							<mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.phone}}</mat-cell>
						</ng-container>

						<!-- Delete Column -->
						<ng-container matColumnDef="remove">
							<mat-header-cell *matHeaderCellDef>
							</mat-header-cell>
							<mat-cell *matCellDef="let row; let i = index;">
								<mat-icon *ngIf="i > 0" (click)="$event.stopPropagation(); deleteContact(i)">delete</mat-icon>
							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedContactColumns"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedContactColumns; let i = index;" class="contact-row" (click)="editContact(i)"></mat-row>
					</mat-table>
				</div>
				<mat-action-list>
					<button mat-list-item class="button" (click)="addContact()"> Add </button>
				</mat-action-list>
		</mat-tab>


		<mat-tab label="Locations">
				<div style="min-height: 635px">
					<mat-table  [dataSource]="locationList" matSort slimScroll width="auto" height="450px" color="rgba(0,0,0,0.3)" size="3px">
						<!-- modelName Column -->
						<ng-container matColumnDef="city">
							<mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.city}}</mat-cell>
						</ng-container>

						<!-- Position Column -->
						<ng-container matColumnDef="country">
							<mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
							<mat-cell *matCellDef="let element">{{element.country}}</mat-cell>
						</ng-container>


						<!-- Delete Column -->
						<ng-container matColumnDef="remove">
							<mat-header-cell *matHeaderCellDef>
							</mat-header-cell>
							<mat-cell *matCellDef="let row; let i = index;">
								<mat-icon (click)="$event.stopPropagation(); deleteLocation(row.id)">delete</mat-icon>
							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedLocationColumns"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedLocationColumns; let i = index;" class="contact-row" (click)="editLocation(i)"></mat-row>
					</mat-table>
				</div>
				<mat-action-list>
					<button mat-list-item class="button" (click)="addLocation()"> Add </button>
				</mat-action-list>
		</mat-tab>
	
			<mat-tab label="Products" class="tpanel">
				<div class="flex-container">
					<div class="element-item" *ngFor="let option of products;">
						<mat-icon (click)="$event.stopPropagation(); deleteTechnology(option.id)">cancel</mat-icon>
						<app-technology-editable-card [technology]="option" ></app-technology-editable-card>
					</div>
				</div>
				<mat-action-list>
					<button mat-list-item class="button"  (click)="addTechnology()"> Add </button>
				</mat-action-list>
			</mat-tab>

			<mat-tab label="Services" class="tpanel">
					<div class="flex-container">
						<div class="element-item" *ngFor="let option of services;">
							<mat-icon (click)="$event.stopPropagation(); deleteService(option.id)">cancel</mat-icon>
							<app-service-editable-card [service]="option" ></app-service-editable-card>
						</div>
					</div>
					<mat-action-list>
						<button mat-list-item  class="button"   (click)="addService()"> Add </button>
					</mat-action-list>
				</mat-tab>
		</mat-tab-group>
	</mat-card-content>
</mat-card>
