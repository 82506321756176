
<h1>Organizations list</h1>

<div class="table-container">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      
<table mat-table [dataSource]="datasource" matSort class="mat-elevation-z8" matSort   matSortActive="name" matSortDirection="asc">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
    <td mat-cell *matCellDef="let element"> {{stateList.get(element.state)?stateList.get(element.state).msg:'?'}} </td>
  </ng-container>

  <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell  *matCellDef="let element">
        <mat-icon (click)="$event.stopPropagation(); deleteOrganization(element.id)">delete</mat-icon>
      </mat-cell>
    </ng-container>

  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showOrganizationForm(row.id)"></tr>
</table>
<mat-paginator [pageSizeOptions]="[15, 40, 80]" showFirstLastButtons></mat-paginator>
<mat-action-list>
    <button mat-list-item class="button" (click)="addOrganization()"> Add </button>
  </mat-action-list>
</div>