import { Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Technology } from "../../../../entity/Technology";
import { OrganizationService } from '../../../services';

@Component({
	selector: "application-type-card-view",
	templateUrl: "./application-type-card.component.html",
	styleUrls: ["./application-type-card.component.scss"],
})
export class ApplicationTypeCardComponent {

	@Input()
	public technology: Technology;

	private constructor(private router: Router, public organizationService: OrganizationService) { }

	@HostListener("click")
	public async onClick(): Promise<void> {
		await this.router.navigate([`/technology/${this.technology.id}`]);
	}

}
