import { HttpClientModule } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CarouselModule } from "ngx-bootstrap";
import { AppRoutingModule } from "../../app-routing.module";
import { ApplicationTypeService } from "../../services/ApplicationTypeService";
import { ImageService } from "../../services/ImageService";
import { TechnologyCategoryService } from "../../services/TechnologyCategoryService";
import { TechnologyService } from "../../services/TechnologyService";
import { BrokerageComponent } from "./brokerage/brokerage";
import { CommunityComponent } from "./community/community";
import { HomeCarouselComponent } from "./home-carousel/home-carousel.component";
import { HomeComponent } from "./home.component";
import { MarketplaceComponent } from "./marketplace/marketplace.component";
import { ShowroomModule } from "./showroom/showroom.module";
import { SharedModule } from "../../shared/shared.module";
import { MaterialsModule } from '../../materials.module';

@NgModule({
	schemas:  [ CUSTOM_ELEMENTS_SCHEMA ],
	declarations: [
		BrokerageComponent,
		CommunityComponent,
		HomeComponent,
		HomeCarouselComponent,
		MarketplaceComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		CarouselModule.forRoot(),
		HttpClientModule,
		MaterialsModule,
		ShowroomModule,
		SharedModule,
	],
	exports: [
	],
	providers: [
		ApplicationTypeService,
		ImageService,
		TechnologyService,
		TechnologyCategoryService,
	],
} as NgModule)
export class HomeModule implements NgModule { }
