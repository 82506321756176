import { Contact } from "./Contact";
import { Location } from "./Location";
import { Technology } from "./Technology";
import { Typology } from "./Typology";
import { Service } from "./Service";

export enum OrganizationState {
    PENDING_EMAIL_VERIFICATIONS = "PENDING_EMAIL_VERIFICATIONS",
    PENDING_ADMIN_AUTHORIZATIONS = "PENDING_ADMIN_AUTHORIZATIONS",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE" ,
    DELETED = "DELETED"
}

export interface IMessageColor { 
	msg: string,
	color: string
}

export const OrganizationStateList: Map<OrganizationState, IMessageColor> = new Map([ 
	[OrganizationState.PENDING_EMAIL_VERIFICATIONS, {msg: 'Pending email verification', color: 'red', status:OrganizationState.PENDING_EMAIL_VERIFICATIONS}],
	[OrganizationState.PENDING_ADMIN_AUTHORIZATIONS, {msg: 'Pending Administration Authorization', color: 'red', status:OrganizationState.PENDING_ADMIN_AUTHORIZATIONS}],	
	[OrganizationState.ACTIVE, {msg: 'Active', color: 'green', status:OrganizationState.ACTIVE}],
	[OrganizationState.INACTIVE, {msg: 'Inactive', color: 'gray', status:OrganizationState.INACTIVE}],
	[OrganizationState.DELETED, {msg: 'Deleted', color: 'gray', status:OrganizationState.DELETED}],
]);

export class Organization {

	public id: number;

	public logo: string;

	public name: string;

	public state: OrganizationState;

	public description: string;

	public contacts: Contact[];

	public typologies: Typology[];

	public locations: Location[];

	public technologies: Technology[];

	public services: Service[];

}
