import { Component, Inject, ViewChild, HostListener, Input } from "@angular/core";

import { AuthenticationService } from "../../services";

import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from "@angular/router";

@Component({
	selector: "app-authentication",
	templateUrl: "./authentication.component.html",
	styleUrls: ["./authentication.component.scss"],
})
export class AuthenticationComponent {
    @Input() active: boolean;
    @ViewChild('auth') authComponent;

    private __position = { left: '0', top: '0'};
    @HostListener('window:resize') onResize() {
        if (this.authComponent) this.setPosition();
    }

    private id;
    private name;
    private logotype;
    private isAdmin = false;
    
    private constructor(private authentication: AuthenticationService, private loginPopup: MatDialog, private router:Router) {
        this.cleanOrganizationInfo();
        this.setInfo();
    }

    ngAfterViewInit() {
        if (this.authComponent) this.setPosition();
    }

    private setPosition(): void {
        const rect = this.authComponent.nativeElement.getBoundingClientRect();
        this.__position = { left: `${rect.left - 140}px`, top: `${rect.bottom - 20}px` };
    }
    
	isAuthenticated() {
		return this.authentication.isAuthenticated();
	}
  
	openDialog(): void {
		const dialogRef = this.loginPopup.open(LoginPopupDialog, { 
            position: this.__position, 
        });
	
		dialogRef.afterClosed().subscribe(result => {
            if (result && result !== undefined) {
                this.setInfo();
            }
        });
    }

    private cleanOrganizationInfo() {
        this.id = undefined;
        this.name = '';
        this.logotype = '/assets/images/no_image.jpg';
        this.isAdmin = false;
    }

    logout() {
        this.cleanOrganizationInfo();
        this.authentication.logout();
        this.router.navigateByUrl("/");
    }



    private setInfo() {

        this.isAdmin = this.authentication.isAdmin();
        this.authentication.getInfo()
        .then (
            data => { 
                this.id = data.id;
                this.name = data.name;
                this.logotype = data.logo;
            },
            error => { }
        );
    }
}

@Component({
	selector: 'app-authentication-login',
    templateUrl: './login-popup.html',
    styles: [
        '.mat-icon { margin-left: 15px; cursor: pointer; }',
        '.mat-form-field { margin-left: 20px }',
        '.error { color: red; font-weight: bold; }'
    ]
})
export class LoginPopupDialog {
    
    msg = "";
    hide = true;
    loading = false;

	constructor(
        private loginPopup: MatDialogRef<LoginPopupDialog>,
        @Inject(AuthenticationService) private auth: AuthenticationService) {}
    
    login(username, password) {
        this.msg = "";
        this.loading = true;
        this.auth.login(username, password)
        .subscribe(
            data => { this.loginPopup.close(data); },
            error => {
                this.msg = "Can't authenticated!!!";
                this.loading = false;
                console.log(error);
            });
    }
    
}
