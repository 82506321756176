import { Component, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material";
import { ActivatedRoute, Params } from "@angular/router";
import { List } from "linqts";
import { ApplicationType } from "../../../entity/ApplicationType";
import { Technology } from "../../../entity/Technology";
import { TechnologyCategory } from "../../../entity/TechnologyCategory";
import { ApplicationTypeService } from "../../services/ApplicationTypeService";
import { TechnologyCategoryService } from "../../services/TechnologyCategoryService";
import { TechnologyService } from "../../services/TechnologyService";

@Component({
	selector: "technologies-view",
	templateUrl: "./technologies.component.html",
	styleUrls: ["./technologies.component.scss"],
})
export class TechnologiesComponent implements OnInit {

	public modelView: {
		sideNavMode: string;
		sideNavOpened: boolean;
	} = {
		sideNavMode : "side",
		sideNavOpened : false,
	};

	public applicationTypes: ApplicationType[];
	public technologies: Technology[];
	public technologyCategory: TechnologyCategory;

	private applicationTypeIds: number[] = [];

	private constructor(private activatedRoute: ActivatedRoute,
		private applicationTypeService: ApplicationTypeService,
		private technologyService: TechnologyService,
		private technologyCategoryService: TechnologyCategoryService) { }

	public async onCheckboxChange($event: MatCheckboxChange): Promise<void> {
		const applicationTypeId: number = parseInt($event.source.name, 10);
		if ($event.checked) {
			this.applicationTypeIds.push(applicationTypeId);
		} else {
			this.applicationTypeIds = new List(this.applicationTypeIds).Where((id: number) => id !== applicationTypeId).ToArray();
		}
		this.technologies = await this.technologyService.getByTechnologyCategoryApplicationTypeIdsArray(this.technologyCategory, this.applicationTypeIds);
		this.technologies.forEach(t=>t.applicationTypes.sort((a,b)=>a.id-b.id));
	}

	public ngOnInit(): void {
		this.onWindowScreenResize();
		window.addEventListener("resize", this.onWindowScreenResize.bind(this));

		this.activatedRoute.params.subscribe((params: Params) => {
			const technologyCategoryId: number = parseInt(params.id, 10);
			this.ngOnInitAsync(technologyCategoryId).catch((reason: Error) => console.error(reason));
		});
	}

	private async ngOnInitAsync(technologyCategoryId: number): Promise<void> {
		
		this.technologyCategoryService.getItem(technologyCategoryId).then( entity => {
			
			this.technologyCategory = entity
			
			this.applicationTypeService.getItems(this.technologyService, this.technologyCategory).then( entities => {
				this.applicationTypes = entities.sort((a,b)=>a.id-b.id);

				this.applicationTypeIds = this.applicationTypes.map((app: ApplicationType) => app.id);
			}).catch((reason: Error) => console.error(reason));

			// Filter by technologies that have the same TechnologyCategory
			this.technologyService.getAllByTechnologyCategory(this.technologyCategory).then( entities => {
				this.technologies = entities
				this.technologies.forEach(t=>t.applicationTypes.sort((a,b)=>a.id-b.id));
			}).catch((reason: Error) => console.error(reason));
		
		}).catch((reason: Error) => console.error(reason));
	}

	private onWindowScreenResize(): void {
		if (window.screen.width >= 1000) {
			this.modelView.sideNavMode = "side";
			this.modelView.sideNavOpened = true;
		} else {
			this.modelView.sideNavMode = "over";
			this.modelView.sideNavOpened = true;
		}
	}
}
