<mat-dialog-content>
    <h1>Location</h1>
    <form class="detail-form" [formGroup]="locationForm">
      <mat-form-field class="detail-field ninety-percent" style="display: inline">
          <mat-label>Country:</mat-label>
          <mat-select formControlName="country">
            <mat-option *ngFor="let option of locationsList; let i = index" [value]="option.iso_A2">
              {{option.name}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field style="display: inline">
        <input matInput formControlName="city" placeholder="City" required>
      </mat-form-field>
      
      <div style="display: inline">
        <p>Double click on the map to change coordinates</p>
        <agm-map style="	height: 300px;" [latitude]="locationForm.get('latitude').value" (mapClick)="changePickupMarkerLocation($event)" [longitude]="locationForm.get('longitude').value" [disableDefaultUI]="false" [zoomControl]="true" >
          <agm-marker [latitude]="locationForm.get('latitude').value" [longitude]="locationForm.get('longitude').value">
          </agm-marker>
        </agm-map>
      </div>
        
      <!-- 
      <mat-form-field>
        <br>
        <div style="display: inline">
          <p>Double click on the map to change coordinates</p>
          <agm-map style="	height: 300px;" [latitude]="locationForm.get('latitude').value" (mapClick)="changePickupMarkerLocation($event)" [longitude]="locationForm.get('longitude').value" [disableDefaultUI]="false" [zoomControl]="true" >
            <agm-marker [latitude]="locationForm.get('latitude').value" [longitude]="locationForm.get('longitude').value">
            </agm-marker>
          </agm-map>
        </div>
      </mat-form-field> -->
    </form>		
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{id: data.index, form: locationForm}" [disabled]="!locationForm.valid" cdkFocusInitial>Ok</button>
  <button mat-button (click)="cancel()">Cancel</button>
</div>