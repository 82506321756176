import { Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { OrganizationService } from "../../../services/OrganizationService";
import { Service } from "../../../../entity";

@Component({
	selector: "service-card-view",
	templateUrl: "./service-card.component.html",
	styleUrls: ["./service-card.component.scss"],
})
export class ServiceCardComponent {

	@Input()
	public service: Service;

	private constructor(private router: Router, public organizationService: OrganizationService) { }

	@HostListener("click")
	public async onClick(): Promise<void> {
		await this.router.navigate([`/service/${this.service.id}`]);
	}
}
