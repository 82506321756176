import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Image } from "../../entity/Image";
import { environment } from '../../environments/environment';
import { AbstractCRUDService } from "./AbstractCRUDService";

@Injectable()
export class ImageService extends AbstractCRUDService<Image, string> {

  private actionUrl: string;

  constructor(private http: HttpClient) {
    super(http);
    this.actionUrl = environment.api_server + 'images';
	}
	
 	public async getAll(): Promise<Image[]> {
		return await 
			this.http.get<Image[]>(this.actionUrl + '/')
        .pipe(map((result:any) => { return result._embedded.images }))
			  .toPromise();
	}

	public async getItem(id: string): Promise<Image> {
		return await this.http.get<Image>(`${this.actionUrl}?${id}`).toPromise();
	}
}
