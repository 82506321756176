<!-- PAGE 1 -->
<div class="title-img">
  <div class="img-content" style="text-align:right">
    <div class="headers">
      <h1 class="title">Community</h1>
      <h2 class="subtitle">Key figures and trends 2017</h2>
    </div>
    <div class="row">
      <div class="row-item col-1"><img src="/assets/media/automotive.svg" alt="car-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/building.svg" alt="building-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/electronics.svg" alt="electronics-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/energy.svg" alt="energy-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/light.svg" alt="light-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/logistics.svg" alt="transport-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/medical.svg" alt="health-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/packaging.svg" alt="package-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/printing.svg" alt="production-icon"></div>
      <div class="row-item col-1"><img src="/assets/media/textiles.svg" alt="textil-icon"></div>
    </div>
  </div>
</div>

<!-- PDF veresion -->
<a class="download-pdf" href="/assets/media/plaquette_A4_smartees_hd.pdf" download>
  <span class="download-font">PDF version</span>
  <a>
    <img class="pdf-button" src="/assets/media/download_as_pdf.svg" alt="download_as_pdf" style="max-width: 3em;">
  </a>
</a>


<!-- PAGE 2 -->
<div class="smartees-logo">
  <img src="/assets/media/smartees-logo-2.png" alt="smartees-logo-text" style="max-width: 16em; position: absolute; top: 7em; right: 3em">
  <img src="/assets/media/smartees-logo-only.png" alt="smartees-logo-only" style="width: 22em; position: absolute; top: 11em; right: 6em">
</div>
<div class="text-block" style="margin-right: 0">
  <h3 class="text-block-subtitle">ABOUT SmartEEs</h3>
  <ul class="arrow">
    <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
      The European <b>Digital Innovation Hubs network</b> to support the European Industry 
      reinforcing its competitive advantage by providing acceleration support for the 
      integration of <b>Flexible Electronics</b>.</li>
  </ul>
  <table class="tg">
    <tr style="border-bottom: solid 2px #cfcfcf">
      <th class="tg-zv4m"><span style="font-weight:bold">Innovation Action</span></th>
      <th class="tg-zv4m"><span style="font-weight:bold">Acceleration program for SMEs, start-ups, midcaps</span><br>Call: ICT- 04-2017<br>Topic: Smart Anything Everywhere Initiative</th>
    </tr>
    <tr style="border-bottom: solid 2px #cfcfcf">
      <td class="tg-zv4m"><span style="font-weight:bold">Number of partners</span></td>
      <td class="tg-zv4m">11 partners from 8 EU countries<br></td>
    </tr>
    <tr style="border-bottom: solid 2px #cfcfcf">
      <td class="tg-zv4m"><span style="font-weight:bold">Budget</span></td>
      <td class="tg-zv4m">4,5M€</td>
    </tr>
    <tr style="border-bottom: solid 2px #cfcfcf">
      <td class="tg-zv4m"><span style="font-weight:bold">Duration</span></td>
      <td class="tg-zv4m">36 months</td>
    </tr>
    <tr>
      <td class="tg-zv4m"><span style="font-weight:bold">Expected results</span></td>
      <td class="tg-zv4m">Supporting 20 selected companies to develop new<br>products, business and growth</td>
    </tr>
  </table>
  <br/>
  <ul class="arrow">
    <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
      An original European <b>acceleration program</b> dedicated to help innovative
      companies to access new markets and grow their business in adopting
      flexible electronics in new products lines</li>
      <br/>
      <ul class="inner-ul" style="color: #ff7d23">
        <li>Dedicated team of technical experts and business managers</li>
        <li>Access to financial resources and investors</li>
        <li>Unique European Digital Innovation Hub onFlexible Electronics</li>
      </ul>
  </ul>
  <br/>
  <ul class="arrow">
    <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
    A <b>community</b> of <b>60 organizations</b> at the start of the project</li>
  </ul>
</div>

<div class="flex-container container-responsive-right" style="width: 100%; justify-content: space-between; margin-bottom: 5em;">
  <div class="responsive-optional" style="width: 30%;">
    <img style="margin: 5em auto auto 5vw; max-width: 20em;" src="/assets/media/projects.png" alt="projects-image">
  </div>
  <div class="text-block-right">
    <h3 class="text-block-subtitle-right">ABOUT Flexible electronics</h3>
    <ul class="arrow">
      <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
        Flexible (or organic) electronics stands for a revolutionary new type of
        electronics – also called “emerging electronics” – which is thin,
        lightweight, flexible, robust, and produced at low cost (Source OE-A),
        with a focus on 4 main sectors in the SmartEES project</li>
      <br/>
      <ul class="inner-ul">
        <li>Bringing <b>Light</b> to your products with <b>OLED and novel lighting</b></li>
        <li>Bringing <b>Interaction</b> to your products with <b>OLED displays</b></li>
        <li>Bringing <b>Energy</b> to your products with <b>organic photovoltaics</b></li>
        <li>Bringing <b>Intelligence</b> to your products with <b>novel electronics & components
          and integrated smart systems</b>.</li>
      </ul>
    </ul>
    <br/>
    <ul class="arrow">
      <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
        <b>An estimated 62 billion euros market by 2027 (source OE-A)</b></li>
    </ul>
    <table class="tg">
      <tr class="spaced">
        <th class="tg-ttqg zoom" (click)="getApplicationType(1)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/automotive.svg" alt="car-icon"></div>
            <div class="cell-text">Automotive</div>
          </div>
        </th>
        <th class="tg-ttqg zoom" (click)="getApplicationType(7)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/medical.svg" alt="health-icon"></div>
            <div class="cell-text">Medical &amp; Pharmaceutical</div>
          </div>
        </th>
      </tr>
      <tr class="spaced">
        <td class="tg-ttqg zoom" (click)="getApplicationType(2)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/building.svg" alt="building-icon"></div>
            <div class="cell-text">Building &amp; architecture</div>
          </div>
        </td>
        <td class="tg-ttqg zoom" (click)="getApplicationType(8)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/packaging.svg" alt="package-icon"></div>
            <div class="cell-text">Packaging</div>
          </div>
        </td>
      </tr>
      <tr class="spaced">
        <td class="tg-ttqg zoom" (click)="getApplicationType(3)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/electronics.svg" alt="electronics-icon"></div>
            <div class="cell-text">Consumer electronics</div>
          </div>
        </td>
        <td class="tg-ttqg zoom" (click)="getApplicationType(9)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/printing.svg" alt="production-icon"></div>
            <div class="cell-text">Printing &amp; graphic arts</div>
          </div>
        </td>
      </tr>
      <tr class="spaced">
        <td class="tg-ttqg zoom" (click)="getApplicationType(4)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/energy.svg" alt="energy-icon"></div>
            <div class="cell-text">Energy</div>
          </div>
        </td>
        <td class="tg-ttqg zoom" (click)="getApplicationType(10)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/textiles.svg" alt="textil-icon"></div>
            <div class="cell-text">Textile &amp; fashion</div>
          </div>
        </td>
      </tr>
      <tr class="spaced">
        <td class="tg-ttqg zoom" (click)="getApplicationType(5)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/light.svg" alt="light-icon"></div>
            <div class="cell-text">Lighting</div>
          </div>
        </td>
        <td class="tg-ttqg zoom" (click)="getApplicationType(6)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/logistics.svg" alt="transport-icon"></div>
            <div class="cell-text">Logistics</div>
          </div>
        </td>
      </tr>
      <tr class="spaced">
        <td class="tg-ttqg zoom" (click)="getApplicationType(11)">
          <div class="flex-container">
            <div><img src="/assets/media/icons/other.svg" alt="other-icon"></div>
            <div class="cell-text">Others</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<!-- PAGE 3 -->

<div class="text-block text-block-wide text-block-widest">
  <h3 class="text-block-subtitle">Enter the acceleration program!</h3>
  <img class="accel-prog" src="../../../assets/media/Accel_program.svg" alt="Acceleration_program">
  <table class="tg">
    <tr style="display: flex; justify-content: flex-start; flex-wrap: wrap">
      <th class="tg-lghr-top col-6" style="min-width: 50em;">
        <ul class="arrow">
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
            SmartEES: The European acceleration program to <b>help innovative companies to access 
            new markets and grow their business by integrating flexible electronics into products 
            and services.</b>
          </li>
          <br/>
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
            More than 35 product concepts available to design your customized solution
          </li>
          <br/>
          <div class="flex-container-tags">
            <div style="padding: 0.5em">
              <a class="button4" href="https://twitter.com/hashtag/designfreedom" style="background-color:#ff7d23">#design freedom</a>
            </div>
            <div style="padding: 0.5em">
              <a class="button4" href="https://twitter.com/hashtag/functionintegration" style="background-color:#ff7d23">#function integration</a>
            </div>
            <div style="padding: 0.5em">
              <a class="button4" href="https://twitter.com/hashtag/production%20agility" style="background-color:#ff7d23">#production agility</a>
            </div>
          </div>
          <table class="tg">
            <tr>
              <th class="tg-ttqg bottom"><img class="col-img" src="/assets/media/productA.png" alt="opv-module"></th>
              <th class="tg-ttqg bottom"><img class="col-img" src="/assets/media/productB.jpg" alt="opv-module"></th>
              <th class="tg-ttqg bottom"><img class="col-img" src="/assets/media/productC.jpg" alt="opv-module"></th>
              <th class="tg-ttqg bottom"><img class="col-img" src="/assets/media/productD.png" alt="opv-module"></th>
            </tr>
            <tr>
              <td class="tg-lghr-top" style="text-align:center"><b>Flexible OPV module:</b><br>Solutions for</td>
              <td class="tg-lghr-top" style="text-align:center"><b>OPV for interior design:</b><br>Solutions for</td>
              <td class="tg-lghr-top" style="text-align:center"><b>Organic Solar Cells - Photovoltaic for interior design:</b><br>Solutions for</td>
              <td class="tg-lghr-top" style="text-align:center"><b>Flexible Solar modules:</b><br>Solutions for<br></td>
            </tr>
            <tr class="noselect">
              <td class="tg-lghr-top" style="text-align:center; padding-top: 1em;">
                <img class="icon" src="/assets/media/icons/building.svg" alt="building-icon">
                <img class="icon" src="/assets/media/icons/electronics.svg" alt="electronics-icon">
                <img class="icon" src="/assets/media/icons/energy.svg" alt="energy-icon">
                <img class="icon" src="/assets/media/icons/packaging.svg" alt="packaging-icon">
              </td>
              <td class="tg-lghr-top" style="text-align:center; padding-top: 1em;">
                <img class="icon" src="/assets/media/icons/building.svg" alt="building-icon">
                <img class="icon" src="/assets/media/icons/electronics.svg" alt="electronics-icon">
                <img class="icon" src="/assets/media/icons/energy.svg" alt="energy-icon">
                <img class="icon" src="/assets/media/icons/packaging.svg" alt="packaging-icon">
              </td>
              <td class="tg-lghr-top" style="text-align:center; padding-top: 1em;">
                <img class="icon" src="/assets/media/icons/building.svg" alt="building-icon">
                <img class="icon" src="/assets/media/icons/electronics.svg" alt="electronics-icon">
                <img class="icon" src="/assets/media/icons/energy.svg" alt="energy-icon">
              </td>
              <td class="tg-lghr-top" style="text-align:center; padding-top: 1em;">
                <img class="icon" src="/assets/media/icons/building.svg" alt="building-icon">
                <img class="icon" src="/assets/media/icons/energy.svg" alt="energy-icon">
              </td>
            </tr>
          </table>
        </ul>
        <ul class="arrow">
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
            To apply get online at: <a href="https://smartees.eu/smartees-call-for-projects/">https://smartees.eu/smartees-call-for-projects/</a>
          </li>
        </ul>
      </th>
      <th class="tg-lghr-top col-6">
        <ul class="arrow">
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
          20 projects to be supported</li>
        </ul>
        <ul class="arrow">
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
          Up to 165k€ investment per project: </li>
          <ul class="inner-ul col-space">
            <li>80k € (max.) worth of technical and non-technical services delivered directly by SmartEEs.</li>
            <li>60k € (max.) Voucher assigned to the Innovative Company.</li>
            <li>25k € (max.) In-kind contribution (own funding) from the Innovative Company. Can be the input of labour, and materials time to the Project.</li>
          </ul>
        </ul>
        <ul class="arrow">
          <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
          Call for projects – 5 cut off dates </li>
          <ul class="inner-ul col-space">
            <li><b style="color: #ff7d23">19th March 2018</b></li>
            <li><b style="color: #ff7d23">20th July 2018</b></li>
            <li><b style="color: #ff7d23">16th November 2018</b></li>
            <li><b style="color: #ff7d23">19th April 2019</b></li>
            <li><b style="color: #ff7d23">20th September 2019</b></li>
          </ul>
        </ul>
      </th>
    </tr>
  </table>
</div>

<div class="table-container IE-table-style" style="display: flex;justify-content: space-between;">
  <div class="left-svg" style="margin-top: 14em;">
    <img src="/assets/media/smartees-logo.png" alt="smartees-logo-text" style="margin: auto auto auto 4em; max-width: 18em;">  
  </div>
  <div class="table-right">
    <h3 class="text-block-subtitle-right">SmartEES 2017 Community key figures!</h3>
    <!-- Responsive Table -->
    <div class="Rtable Rtable--3cols Rtable--collapse">

      <!-- ROW 1 -->
      <div class="Rtable-cell Rtable-cell--head cell-top-left">
        <img class="bullet-sm" src="/assets/media/bullets/one.svg" alt="bullet"><span style="font-weight:bold">Project partners</span>
        <br>
        <span class="img-txt" style="font-size: 17px !important;">10 partners</span>
        <br/>
        <span class="img-txt" style="font-size: 17px !important;"> 8 EU countries</span>
        <br>
      </div>
      <div class="Rtable-cell cell-top-mid">
        <img class="img-responsive" src="/assets/media/map.PNG" alt="map-image" style="border-radius: 5px; max-width: 15em; width: 90%;">
      </div>
      <div class="Rtable-cell Rtable-cell--foot cell-top-right">
        <img class="img-responsive" src="/assets/media/brands.PNG" alt="brands-image" style="border-radius: 5px; max-width: 15em; width: 90%;">
      </div>

      <!-- ROW 2 -->
      <div class="Rtable-cell Rtable-cell--head cell-mid-left">
        <img class="bullet-sm" src="/assets/media/bullets/two.svg" alt="bullet"><span style="font-weight:bold;">Community members</span>
      </div>
      <div class="Rtable-cell cell-mid-mid" style="font-size: 17px !important;">
        <span style="font-weight:bold; font-size: 17px !important">60 organizations:</span> 2 associations / 34 clusters 18 industrials / 6 investors
      </div>
      <div class="Rtable-cell Rtable-cell--foot cell-mid-right mrcl">
        <span style="font-weight:bold; font-size: 17px !important">100 professionals Portfolio of 360 private investors</span>
      </div>

      <!-- ROW 3 -->
      <div class="Rtable-cell Rtable-cell--head cell-bot-left">
        <img class="bullet-sm" src="/assets/media/bullets/three.svg" alt="bullet"><span style="font-weight:bold;">Clusters</span>
        <br/>
        <span class="img-txt" style="font-size: 17px !important;">34 clusters</span>
        <br/>
        <span class="img-txt" style="font-size: 17px !important;">3865 companies/members<br><br></span>
        <br/>
        <span class="img-txt" style="font-weight:bold; font-size: 17px !important">Clusters countries:</span>
        <br/>
        <span class="img-txt" style="font-size: 17px !important;">
          Belgium:1, Estonia:1, EU:1, Finland:1, France:4, Germany:5, Hungary:1, Italy:1, Lituania:1, Nederlands:1, Poland:1, Romania:1, Serbia:1, Slovenia:1, Spain:8, UK:3
        </span>
      </div>
      <div class="Rtable-cell cell-bot-mid" style="align-content: center">
        <img src="/assets/media/smartees-graph-1.png" alt="graphic" class="smartees-graph" style="max-width: 15em; width: 90%;">
      </div>
      <div class="Rtable-cell Rtable-cell--foot cell-bot-right">
        <img src="/assets/media/smartees-graph-2.png" alt="graphic" class="smartees-graph" style="margin-top: 1.5em; max-width: 15em; width: 90%">
      </div>
    </div>
  </div>
</div>

<!-- PAGE 4 -->
<div class="text-block text-block-widest flex-container container-responsive-left" style="flex-wrap: wrap; justify-content: flex-start">
  <div class="responsive" style="width: 60%; text-align: left;">
    <h3 class="text-block-subtitle">Join the Community!</h3>
    <ul class="arrow">
      <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
        The SmartEEs community brings together 62 representatives of associations,
        clusters, investors, European Technology Platforms, EU networks, suppliers
        and end-users.
      </li>
      <br/>
      <li><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet">
        The community will be involved in the promotion of the project and in the
        selection, the support and the follow-up of 20 application experiments.
      </li>
    </ul>
    <div class="inner-text">
      This network is wide opened and new stakeholders are welcome as long as they are all active in various application fields, technologies, markets and services where Flexible Electronic technologies are expected to bring added-value.
      <br/>
      <br/>
      <b>To receive all the updates and news and be added to the directory, joint us and stay in touch!</b>
    </div>
    <ul class="arrow">
      <li>
        <a href="https://www.linkedin.com/company/smartees-eu"><img class="bullet" src="/assets/media/linkedin-brands.svg" alt="bullet"></a>SmartEEsEU
      </li>
      <br/>
      <li>
        <a href="https://twitter.com/smarteeseu"><img class="bullet" src="/assets/media/twitter-square-brands.svg" alt="bullet"></a>@SmartEEsEU
      </li>
      <br/>
      <li>
        <a href="mailto:jerome.gavillet@cea.fr"><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet"></a>SmartEEs project coordinator: Jérôme GAVILLET - jerome.gavillet@cea.fr
      </li>
      <br/>
      <li>
        <a href="mailto:maric@amires.eu"><img class="bullet" src="/assets/media/arrow-right-solid.svg" alt="bullet"></a>SmartEEs project manager: Minja MARIC - maric@amires.eu
      </li>
    </ul>
  </div>
  <div class="right-img" style="width: 40%">
    <img src="/assets/media/social.svg" alt="social-image" style="max-width: 35em; position: absolute; right: 7vw; margin-top: 4em;">
  </div>
</div>

<div class="flex-container container-responsive-center" style="flex-wrap: wrap; justify-content: space-between">
  <div class="smartees-footer" style="vertical-align: bottom; width: 40%; text-align: center; min-width: 20em;">
    <img src="/assets/media/smartees-logo-2.png" alt="smartees-logo-text" class="smartees-footer-content" style="margin-top:4em; max-width: 30em;">  
  </div>
  <div style="vertical-align: bottom; width: 40%; min-width: 20em; text-align: center">
    <img src="/assets/media/footer.png" alt="smartees-logo" class="smartees-footer-content" style="margin-top: 5.5em !important; margin-bottom: -10px;">
  </div>
</div>

