<mat-card *ngIf="organization">
	<mat-card-title>
		<div style="align-items:center;display:flex;justify-content:center;">
			<img style="    max-width: 20vw;" [src]="organizationService.dynamicUri(organization.logo)" />
			<div style="border-left:1px solid rgba(0,0,0,.1); height:300px;"></div>
			<h1>{{organization.name}}</h1>
		</div>
	</mat-card-title>
	<mat-card-content>
		<div *ngIf="markdownContent && markdownContent.length>0">
			<h2>Description</h2>
			<hr />
			<markdown class="markdown-body" >{{markdownContent}}</markdown>
		</div>
		<div *ngIf="organization.technologies && organization.technologies.length>0">
			<h2>Products modif</h2>
			<hr />
			<div class="flex-container">
				<technology-card-view *ngFor="let technology of organization.technologies" [technology]="technology">
					<h1>Technology: {{technology}}</h1>
				</technology-card-view>
			</div>
		</div>
		<div *ngIf="organization.services && organization.services.length>0">
				<h2>Services</h2>
				<hr />
				<div class="flex-container">
					<service-card-view *ngFor="let service of organization.services" [service]="service"></service-card-view>
				</div>
			</div>
		<div *ngIf="isAuthenticated() && organization.contacts && organization.contacts.length>0">
			<h2>Contacts</h2>
			<hr />
			<div class="flex-container">
				<contact-card *ngFor="let contact of organization.contacts" [contact]="contact"></contact-card>
			</div>
		</div>
	</mat-card-content>
</mat-card>
