import { Image } from "../../../../../entity/Image";

export class ShowroomMatCardViewModel {

	public technologyCategoryDescription: string;

	public technologyCategoryId: number;

	public technologyCategoryName: string;

	public technologyCategorySubTitle: string;

	public technologyCategoryImage: Image;

}
