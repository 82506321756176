import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Organization } from "../../../../entity/Organization";
import { OrganizationService } from "../../../services";

@Component({
	selector: "organization-card-view",
	templateUrl: "./organization-card.component.html",
	styleUrls: ["./organization-card.component.scss"],
})
export class OrganizationCardComponent implements OnChanges {

	@Input()
	public organization: Organization;

	logo:any;

	private constructor(private router: Router, private organizationService: OrganizationService) {}

	public ngOnChanges(changes:SimpleChanges)
	{
		if(this.organization!=null) this.logo = this.organizationService.dynamicUri(this.organization.logo);
	}

	@HostListener("click")
	public async onClick(): Promise<void> {
		await this.router.navigate([`/organization/${this.organization.id}`]);
	}

}
