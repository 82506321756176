<div style="width:100%;display: inline-block; flex-wrap: wrap;margin-bottom: 0px;">
	<a style="width:200px; height:100px" routerLink="/organizations/" class="leftmenu">
		<p class="myP">Find a member</p>
	</a>
</div>

<div style="margin-top: 0px;">

	<h1>SmartEEs Community</h1>

	<div class="map-text-container mat-card">
			<app-organization-map [organizations]=null></app-organization-map>
	<div class="text-container">
  

	<p>
        The SmartEEs community brings together 233 representatives of associations, clusters, investors, ETP, EU networks, suppliers and end-users.
	</p>

	<p>
		This network is wide opened and new stakeholders are welcome as long as they are all active in various application field,
		technologies, markets and services where LAEs are expected to bring added-value.
	</p>

	<p>
		To know more about the community members and trends download the SmartEEs Community Leaflet
	</p>

	<p>
		To join the community and be added to the directory, don’t hesitate to contact us at <a href="mailto:contact@smartees.eu" target="_top">contact@smartees.eu</a>
	</p>
</div>
</div>
	<div class="flex-container">
		
		<mat-card routerLink="/keyFigures" routerLinkActive="false">
			<mat-card-header>
				<mat-card-title>
					<h4>Anual key figures and facts SmartEEs Community</h4>
				</mat-card-title>
				<mat-card-subtitle>
					<b></b>
				</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<figure>
					<img src="/assets/images/community/key_figures.jpg" />
				</figure>
			</mat-card-content>
		</mat-card>


	
		<mat-card routerLink="/organizations" routerLinkActive="">
			<mat-card-header>
				<mat-card-title>
					<h4>Qualified directory</h4>
				</mat-card-title>
				<mat-card-subtitle>
					<b></b>
				</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<figure>
					<img src="/assets/images/community/circuit.png" />
				</figure>
			</mat-card-content>
		</mat-card>
	
	</div>



</div>
