import { Component, Input, OnInit } from "@angular/core";
import { OrganizationService } from "../../services/OrganizationService";
import { ApplicationTypeService } from "../../services/ApplicationTypeService";

import { Organization } from "../../../entity/Organization";
import { Typology } from "../../../entity/Typology";
import { OrganizationFilter } from "../../../entity/OrganizationFilterOptions";
import { Router } from "@angular/router";
import { ServiceType } from "../../../entity";

@Component({
	selector: "organizations-view",
	templateUrl: "./organizations.component.html",
	styleUrls: ["./organizations.component.scss"]
})
export class OrganizationsComponent implements OnInit {
	@Input() title: boolean;
	@Input() showOrgNameFilter: boolean;
	@Input() showTechTypeFilter: boolean;
	@Input() showServiceTypeFilter: boolean;

	public organizations: Organization[];

	public filter: OrganizationFilter;
	public countries: Object[] = [];
	public cities: Object[] = [];
	public applicationTypes: Object[] = [];
	public serviceTypes: Object[] = [
		{ id: ServiceType.TECHNICAL, name: 'Technical' },
		{ id: ServiceType.NETWORKING, name: 'Networking' },
		{ id: ServiceType.TRAINING, name: 'Training' },
		{ id: ServiceType.LEGAL, name: 'Legal / IP' },
		{ id: ServiceType.FINANCE, name: 'Finance' },
		//{ id: ServiceType., name: 'Busines development' },
		{ id: ServiceType.OTHER, name: 'Other' },
	];

	public serviceSelected: number;

	public organizationTypes: Object[] = [
		{ id: Typology.NCP, name: "NCP" },
		{ id: Typology.CLUSTER, name: "Cluster" },
		{ id: Typology.INDUSTRIAL, name: "Industrial" },
		{ id: Typology.RESEARCH_DEVELOPMENT, name: "Reasearch/Development" },
		{ id: Typology.TECHNOLOGY, name: "Technology" },
		{ id: Typology.HIGH_EDUCATION_UNIVERSITY, name: "High education/University" },
		{ id: Typology.ASSOCIATION, name: "Association" },
		{ id: Typology.THINK_TANK, name: "Think-tank" },
		{ id: Typology.DESIGNERS, name: "Designers" },
		{ id: Typology.SMARTEES_PARTNER, name: "Smartees partners" },
		{ id: Typology.INVESTOR, name: "Investor" }
	];

	public productOrTechnology: Object = [
		{ id: "PRODUCT", name: "Product" },
		{ id: "TECHNOLOGY", name: "Technology" }
	];

	public freeText: string;
	public organizationName: string;

	private constructor(
		private organizationService: OrganizationService,
		private router: Router,
		private applicationTypeService: ApplicationTypeService
	) { }

	/** Init filters from local storage if any and it's not older than 1minute*/
	public initFilters() {
		if (localStorage.getItem(this.router.url)) {
			let obj = JSON.parse(localStorage.getItem(this.router.url));
			if (obj.timestamp && new Date().getTime() - obj.timestamp < 60000) {
				this.filter = obj.value;
				return;
			}
		}
		this.clearFilter();
	}

	public ngOnInit(): void {
		this.initFilters();
		if (this.title === undefined)
			this.title = true;

		this.organizationService.filterAsync(this.filter).then(entities => {
			this.organizations = entities
			this.organizations = this.organizations.sort((a: any, b: any) => a.name.localeCompare(b.name));
		}).catch((reason: Error) => console.error(reason));

		this.organizationService.getCountries().then(entities => {
			this.countries = entities;
			this.countries = this.countries.sort((a: any, b: any) => a.name.localeCompare(b.name));
		}).catch((reason: Error) => console.error(reason));


		this.organizationService.getCities().then(entities => {
			this.cities = entities.map(entity => { return { id: entity, name: entity } });
			this.cities = this.cities.sort((a: any, b: any) => a.name.localeCompare(b.name));
		}).catch((reason: Error) => console.error(reason));

		this.applicationTypeService.getAll().then(entities => {
			this.applicationTypes = entities.map(entity => { return { id: entity.id, name: entity.name } });
			this.applicationTypes = this.applicationTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
		}).catch((reason: Error) => console.error(reason));



		this.organizationTypes = this.organizationTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));


	}

	public filterOrganizations() {
		localStorage.setItem(this.router.url, JSON.stringify({value:this.filter,timestamp:new Date().getTime()}));
		this.organizationService.filterAsync(this.filter).then(entities => {
			this.organizations = entities
			this.organizations = this.organizations.sort((a: any, b: any) => a.name.localeCompare(b.name));
		}).catch((reason: Error) => console.error(reason));


	}

	public clearFilterButFreeText() {
		this.filter.organizationName = null;
		this.filter.country = [];
		this.filter.city = [];
		this.filter.serviceType = [];
		this.filter.productOrTechnology = [];
		this.filter.technologyType = [];
		this.filter.companyType = [];

	}

	public clearFilter() {
		this.filter = <OrganizationFilter>{
			organizationName: null,
			text: null,
			country: [],
			city: [],
			serviceType: [],
			productOrTechnology: [],
			technologyType: [],
			companyType: []

		};
	}

	public selectAll(filterOption: string, options: any, accessor) {
		this.filter[filterOption] = [];

		options.forEach(o => this.filter[filterOption].push(o[accessor]));
		this.filterOrganizations();
	}

	public deselectAll(filterOption: string) {
		this.filter[filterOption] = [];
		this.filterOrganizations();
		this.filter[filterOption] = [...this.filter[filterOption]];
	}


	public showTitle() {
		return this.title;
	}
}
