import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-register-config-dialog',
  templateUrl: './register-config-dialog.html',
  styles: [
    '.default { color: #fff; background-color: #153961; }'
  ]
})
export class RegisterConfirmDialog {
  constructor(public dialogRef: MatDialogRef<RegisterConfirmDialog>) {}


}
