import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Location } from '@angular/common';

import { HttpClient } from "@angular/common/http";

import { MatSnackBar } from "@angular/material";

import { Technology } from "../../../entity/Technology";
import { TechnologyService } from "../../services/TechnologyService";

@Component({
	selector: "technology-view",
	templateUrl: "./technology.component.html",
	styleUrls: ["./technology.component.scss"],
})
export class TechnologyComponent implements OnInit {

	public technology: Technology;
	public markdownContent: string;

	private constructor(private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private http: HttpClient, 
		private location: Location,
		private technologyService: TechnologyService) {	}

	public ngOnInit(): void {
		this.ngOnInitAsync().catch((reason: Error) => {
			console.error(reason);
			this.snackBar.open(reason.name, "Close", {
				duration: 5000,
			});
		});
	}

	private back()
	{   this.location.back();

	}

	private async ngOnInitAsync(): Promise<void> {
		this.activatedRoute.params.subscribe(async (params: Params) => {
			const technologyId: number = parseInt(params.id, 10);
			this.technology = await this.technologyService.getItem(technologyId);

			const refTo = this.technology.specification.match('\\/assets\\/([a-zA-Z0-9]+\\/)+[a-zA-Z0-9]+\\.md');
      if (refTo)
        this.http.get(refTo[0], { responseType: 'text' }).subscribe((data: any) => {
          this.markdownContent = data;
        });

		});
	}

}
