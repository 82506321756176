import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AbstractCRUDService } from "./AbstractCRUDService";
import { Service } from '../../entity';

@Injectable()
export class ServiceService extends AbstractCRUDService<Service, number> {

  public static actionUrl: string= environment.api_server + 'services';;

  constructor(private http: HttpClient) {
    super(http);
    
  }

	public async getAll(): Promise<Service[]> {
		return await 
			this.http.get<Service[]>(ServiceService.actionUrl + '?projection=serviceInline')
        .pipe(map((result:any) => {
          return result._embedded
        }))
        .toPromise();
	}

	public async getItem(id: number): Promise<Service> {
		return await this.http.get<Service>(`${ServiceService.actionUrl}/${id}?projection=serviceInline`).toPromise();
	}

	public createItem(orgId:number): Observable<Service> {
		return this.http.post<any>(environment.api_server + 'services', {organization:orgId, name: '', 'cardImage': '/assets/images/no_image.jpg'});
	}

	public updateItem(service: Service): Observable<Service> {
		return this.http.put<any>(ServiceService.actionUrl + '/' + service.id, service);
	}
		
	public deleteItem(id: number): Observable<any> {
		return this.http.delete(ServiceService.actionUrl + '/' + id);
	}

}
