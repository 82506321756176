import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { OrganizationService } from "../../../services/OrganizationService";

@Component({
	selector: "community",
	templateUrl: "./community.html",
	styleUrls: ["./community.scss"],
})
export class CommunityComponent implements OnInit {

	private constructor(private router: Router, private organizationService: OrganizationService) {}
  
	public ngOnInit() {	}

}
