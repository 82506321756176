import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { OrganizationService } from "../../../services/OrganizationService";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SignupForm } from '../../../../entity/SignupFrom';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { RegisterConfirmDialog } from './register-config-dialog/register-config-dialog';
import { CustomErrorStateMatcher } from './register-error-matcher/CustomErrorMatcher';

// Legal Modal imports
import * as templates from '../../../../assets/templates/privacy-templates/_imports';

@Component({
	selector: "register",
	templateUrl: "./register.html",
	styleUrls: ["./register.scss"],
})
export class RegisterComponent implements OnInit {

	// Legal Modal variables
	public complaints_claims: string;
	public data_protection: string;
	public general_information: string;
	public legislation_jurisdiction: string;
	public membership_remove: string;
	public operations: string;
	public privacy_policy: string;
	public purpose: string;
	public registration: string;
	public responsabilities: string;
	public security_measures: string;

	matcher = new CustomErrorStateMatcher();
	profileForm: FormGroup;
	typologiesList: string[] = [
		'NCP',
		'CLUSTER',
		'INDUSTRIAL',
		'RESEARCH_DEVELOPMENT',
		'TECHNOLOGY',
		'HIGH_EDUCATION_UNIVERSITY',
		'ASSOCIATION',
		'THINK_TANK',
		'DESIGNERS',
		'SMARTEES_PARTNER',
	];

	private constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private organizationService: OrganizationService) {
		this.loadLegalVariables();
	}
	
	public acceptTerms: boolean = false;

	public signup() {
		let form = new SignupForm();
		form.name = this.profileForm.get('name').value;
		form.description = this.profileForm.get('description').value;
		form.typologies = this.profileForm.get('typologies').value
		form.contactName = this.profileForm.get('contactName').value;
		form.email = this.profileForm.get('email').value;
		form.phone = this.profileForm.get('phone').value;
		form.position = this.profileForm.get('position').value;
		form.password = this.profileForm.get('password').value;

		this.organizationService.signup(form).subscribe(() => {
			const dialogRef = this.dialog.open(RegisterConfirmDialog, { disableClose: false });
			dialogRef.afterClosed().subscribe(() => {
				this.router.navigateByUrl("");
			}
			);
		},
			() => {

				this.snackBar.open("Failed to signup", null, {
					duration: 1000,
					verticalPosition: 'top'
				});
			}
		);
	}

	public ngOnInit() {
		this.profileForm = new FormGroup({
			name: new FormControl('', Validators.required),
			description: new FormControl('', Validators.required),
			typologies: new FormControl(''),
			contactName: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, Validators.email], [this.isEmailUnique.bind(this)]),
			phone: new FormControl('', Validators.required),
			position: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required),
		
			confirm_password: new FormControl('', Validators.required),
		}, { validators: [this.checkPasswords] });
	}

	private isEmailUnique(email: FormControl) {
		if (!email) return of(false);
		return this.organizationService.isEmailUnique(-1, email.value)
			.pipe(map(res => res ? null : { emailTaken: true })).toPromise();
	}

	private checkPasswords(group: FormGroup) {
		let pass = group.controls.password.value;
		let confirmPass = group.controls.confirm_password.value;
		return pass == confirmPass ? null : { notSamePass: true }
	}

	public updateCheckbox(status: boolean) {
		this.acceptTerms = status;
	}

	private loadLegalVariables():void {
		// Legal Modal variables
		this.complaints_claims = templates.complaints_claims;
		this.data_protection = templates.data_protection;
		this.general_information = templates.general_information;
		this.legislation_jurisdiction = templates.legislation_jurisdiction;
		this.membership_remove = templates.membership_remove;
		this.operations = templates.operations;
		this.privacy_policy = templates.privacy_policy;
		this.purpose = templates.purpose;
		this.registration = templates.registration;
		this.responsabilities = templates.responsabilities;
		this.security_measures = templates.security_measures;
	}
}
