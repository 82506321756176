import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AbstractCRUDService } from './AbstractCRUDService';

import { ApplicationType } from "../../entity/ApplicationType";

import { Technology } from "../../entity/Technology";
import { TechnologyService } from "./TechnologyService";
import { TechnologyCategory } from "../../entity/TechnologyCategory";

import { ApplicationTypeNavHomeViewModel } from "../components/nav-bar/nav-bar.view-model";

import { environment } from '../../environments/environment';

@Injectable()
export class ApplicationTypeService  extends AbstractCRUDService<ApplicationType, number> {
  private actionUrl: string;

  constructor(private http: HttpClient) {
      super(http);
      this.actionUrl = environment.api_server + 'applications';
  }

	public async getAll(): Promise<ApplicationType[]> {
		return await 
			this.http.get<ApplicationType[]>(this.actionUrl + '?projection=applicationTypeInline')
			.pipe(map((result:any) => { return result._embedded.applications }))
			.toPromise();
	}

	public async getItem(id: number): Promise<ApplicationType> {
		return await this.http.get<ApplicationType>(`${this.actionUrl}/${id}?projection=applicationTypeInline`).toPromise();
	}

	public async getNavHomeComponent(): Promise<ApplicationTypeNavHomeViewModel[]> {
		return (await this.getAll()).map((applicationType: ApplicationType): ApplicationTypeNavHomeViewModel =>
			({
				applicationTypeId: applicationType.id,
				applicationTypeName: applicationType.name,
			}));
	}

	public async getItems(technologyService: TechnologyService, technologyCategory: TechnologyCategory): Promise<ApplicationType[]> {
		const technologies: Technology[] = await technologyService.getAllByTechnologyCategory(technologyCategory);
		const result: Map<number, ApplicationType> = new Map<number, ApplicationType>();
		for (const technology of technologies) {
			for (const applicationType of technology.applicationTypes) {
				result.set(applicationType.id, applicationType);
			}
		}
		return Array.from(result.values());
	}

}
