import { Component } from "@angular/core";

@Component({
	selector: "smartees-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements Component{
	
	

}
