

<nav-bar-view></nav-bar-view>

<div class="wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer-home></app-footer-home>


