import { Component } from "@angular/core";

@Component({
	selector: "tech",
	templateUrl: "./tech.html",
	styleUrls: ["./tech.scss"],
})
export class TechComponent { 
	
}
