export var registration = `<div style="text-align: justify; padding-right: 1em;">
    It is an essential requirement being registered as a member on the website to become part of the community. <br/>
    During this registration process, these data on the company must be provided:<br/>

    <ul style="padding-top: .5em">
        <li>Name</li>
        <li>Location</li>
        <li>Description</li>
    </ul>
    
    And these data on the contact person:<br/>
    
    <ul style="padding-top: .5em">
        <li>Name and surnames</li>
        <li>Email</li>
        <li>Position/title at company</li>
    </ul>
    
    After registering, the member will receive a confirmation of successful registration, which will be sent to the email entered.<br/><br/>
    
    If the member does not receive the confirmation of registration within 24 hours, please contact FUNDACIÓ EURECAT by ringing +34 93 238 14 00 or by sending an email to <a href="mailto:legal@eurecat.org">legal@eurecat.org</a>.<br/><br/>
    
    Members can modify their personal data by accessing the profile section on the platform. A user with the role and power of modifying information on the company can modify this information on the profile, as well as adding other contact people, products and services for each company.<br/><br/>
    Likewise, if a member forgets the password, it can be recovered from the login page by clicking on ‘Did you forget your password?’. After following this procedure, FUNDACIÓ EURECAT will send a new password for logging on to the system.<br/>
</div>`