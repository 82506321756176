import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {

  tokenKey: string = "currentUser"

  constructor(private http: HttpClient, private router:Router) {
    window.setInterval(this.validateToken.bind(this),5000);
    
   }
   
  
  validateToken()
  {
    if(this.isAuthenticated())
    { 
      this.getInfo().then((e) => null
        // if((!e.id || e.id))
        // console.log("Token is ok");}
      ).catch(e=>{
        console.log("Get info failed. Asume logout");
        this.removeToken();
        this.router.navigateByUrl("/");

      });
     
    }

  } 

  isAdmin()
  {
  
    let tokenInfo = this.getToken();
    if(!tokenInfo)return false;
    let jwtData = tokenInfo.token.split('.')[1]
    let decodedJwtJsonData = window.atob(jwtData)
    let decodedJwtData = JSON.parse(decodedJwtJsonData)
    return (decodedJwtData.roles && decodedJwtData.roles.indexOf("ADMIN")!=-1)?true:false;
  }

  login(username, password) {


    return this.http.post<any>( environment.api_server + 'auth/signIn',{
      'username':username,
      "password":password,

    },
    {
      headers: {
     
        'Content-Type': 'application/json'
      },
    })
    .pipe(map(tokenInfo => {
      if (tokenInfo && tokenInfo.token) {
        this.setToken(tokenInfo);

        return true;
      } else
          return false;
    }));
  }

  logout() {
    this.removeToken();
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey));
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, JSON.stringify(token));
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['token'];
  }

  isAuthenticated() {
    let token = localStorage.getItem(this.tokenKey);
    
    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

  refreshToken() {
    let token = this.getToken();
    token.exp = new Date((new Date().getDate() + 1));
    this.setToken(token);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
  }

  async getInfo(): Promise<any> {
    let tokenType: String = JSON.parse(localStorage.getItem(this.tokenKey))['token_type'];
    return await this.http.get<any>(environment.api_server + 'organizations/info', 
      {
        headers: {
          'authorization': tokenType + " " + this.getAccessToken()
        },
      }).toPromise();
	}

}
