import { Component, HostListener, Input } from "@angular/core";
import { Technology } from "src/entity";
import { ProductPopupDialog } from "../../organization-editor/organization-editor.component";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TechnologyService, OrganizationService } from "src/app/services";
import { Router } from "@angular/router";
@Component({
	selector: "technology-card-view",
	templateUrl: "./technology-card.component.html",
	styleUrls: ["./technology-card.component.scss"],
})
export class TechnologyCardComponent {
	@Input()
	public technology: Technology;

	private constructor(private dialog: MatDialog,private router: Router, 
		private snackBar: MatSnackBar,
		public organizationService: OrganizationService, private technologyService: TechnologyService) { }

		@HostListener("click")
		public async onClick(): Promise<void> {
			await this.router.navigate([`/technology/${this.technology.id}`]);
		}

	public imgReady(option: any): boolean {
		return ;
	}
}
