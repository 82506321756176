import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Location } from '@angular/common';

import { HttpClient } from "@angular/common/http";

import { MatSnackBar } from "@angular/material";

import { Service } from "../../../entity/Service";
import { ServiceService } from "../../services/ServiceService";

@Component({
	selector: "service-view",
	templateUrl: "./service.component.html",
	styleUrls: ["./service.component.scss"],
})
export class ServiceComponent implements OnInit {

	public service: Service;
	public markdownContent: string;

	private constructor(private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private http: HttpClient, 
		private location: Location,
		private serviceService: ServiceService) {	}

	public ngOnInit(): void {
		this.ngOnInitAsync().catch((reason: Error) => {
			console.error(reason);
			this.snackBar.open(reason.name, "Close", {
				duration: 5000,
			});
		});
	}
	private back()
	{   this.location.back();

	}


	private async ngOnInitAsync(): Promise<void> {
		this.activatedRoute.params.subscribe(async (params: Params) => {
			const serviceId: number = parseInt(params.id, 10);
			this.service = await this.serviceService.getItem(serviceId);

			const refTo = this.service.specification.match('\\/assets\\/([a-zA-Z0-9]+\\/)+[a-zA-Z0-9]+\\.md');
      if (refTo)
        this.http.get(refTo[0], { responseType: 'text' }).subscribe((data: any) => {
          this.markdownContent = data;
        });

		});
	}

}
