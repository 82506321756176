import { Component, ViewChild, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router'

import { OrganizationService } from '../../services/OrganizationService';
import { Typology } from '../../../entity/Typology';

import { AgmMap, LatLngBounds } from '@agm/core';
import { Organization } from '../../../entity';

interface IOrganizationMarker {
  id: number;
  lat: number;
  lng: number;
  name: string;
  logo: string;
  url: string;
}

interface ITypologyLegend {
  name: string;
  color: string;
  appear: boolean;
  icon: string;
}

@Component({
  selector: 'app-organization-map',
  templateUrl: './organization_map.html',
  styleUrls: ['./organization_map.scss'],
})
export class OrganizationMapComponent implements OnChanges, OnInit {
  lat: number = 41.390205;
  lng: number = 2.154007;
  zoom: number = 12;
  @ViewChild(AgmMap) map: AgmMap;
  mapEnlarged = false;
  markers: IOrganizationMarker[] = [];

  @Input() organizations: Organization[];

  legend:{ [key:string]:any; } = { };
  visibleLegend:any;


  private constructor(private router: Router, private organizationService: OrganizationService) {


    this.legend[Typology.NCP]={ name: 'NCP', color: '#72AF26', appear: false, icon: 'ncp' };
    this.legend[Typology.INVESTOR]={ name: 'Investor', color: '#EE921E', appear: false, icon: 'cluster' };
    this.legend[Typology.CLUSTER]={ name: 'Cluster', color: '#EE922E', appear: false, icon: 'cluster' };
    this.legend[Typology.INDUSTRIAL]={ name: 'Industrial', color: '#CB4FB2', appear: false, icon: 'industrial' };
    this.legend[Typology.RESEARCH_DEVELOPMENT]={ name: 'Research and Development Centre', color: '#93EDCF', appear: false, icon: 'research-development' };
    this.legend[Typology.TECHNOLOGY]={ name: 'Technology Center', color: '#EADD48', appear: false, icon: 'technology' };
    this.legend[Typology.HIGH_EDUCATION_UNIVERSITY]={ name: 'High Education University', color: '#E89E6D', appear: false, icon: 'high-education-university' };
    this.legend[Typology.ASSOCIATION]={ name: 'Association', color: '#E54B81', appear: false, icon: 'association' };
    this.legend[Typology.THINK_TANK]={ name: 'Think Tank', color: '#5FCDE2', appear: false, icon: 'think-tank' };
    this.legend[Typology.DESIGNERS]={ name: 'Designers', color: '#82E0A1', appear: false, icon: 'designers' };
    this.legend[Typology.SMARTEES_PARTNER]={ name: 'SmartEEs Partner', color: '#DD8BD5', appear: false, icon: 'smartees-partner' };
  }

  public ngOnChanges(changes: SimpleChanges) {


    if (changes["organizations"]) {
      if (this.organizations == null) {
        this.organizationService.getAll().then((entities: any) => {this.loadMarkers(entities); }).catch((reason: Error) => console.error(reason));
      }
      else {
        this.loadMarkers(this.organizations);
      }
    }




  }
  public ngOnInit() {

  }


  public loadMarkers(org: any[]) {

    Object.values(this.legend).forEach(l=>l.appear = false);

    this.markers = org.reduce((fromEntity, toEntity) => {

      Object.keys(Typology).indexOf(toEntity.typologies[0])
      const typologies = (toEntity.typologies.length > 0)?toEntity.typologies[0]:null;
      const icon = typologies && this.legend[typologies]?this.legend[typologies].icon :"undefined";

     if(typologies && this.legend[typologies]) this.legend[typologies].appear = true;

      return (toEntity.locations == null || toEntity.locations === undefined) ? [] :
        fromEntity.concat(toEntity.locations.reduce((fromCountry, toCountry) => {
          return fromCountry.concat({
            id: toEntity.id,
            name: toEntity.name,
            logo: this.organizationService.dynamicUri(toEntity.logo),
            lat: toCountry.latitude,
            lng: toCountry.longitude,
            url: 'assets/images/icons/legend/' + icon + '-mark.png',
          });
        }, []));
    }, []);
    this.visibleLegend = Object.values(this.legend).filter((l: ITypologyLegend) => l.appear);
    this.updateBounds();

  }
  public updateBounds() {
    if(!window['google'])return;
    const bounds: LatLngBounds = new window['google'].maps.LatLngBounds();
    for (const mm of this.markers) {
      bounds.extend(new window['google'].maps.LatLng(mm.lat, mm.lng));
    }

    (this.map as any)._mapsWrapper.fitBounds(bounds);
  }

  public resizeMap() {
    this.mapEnlarged = !this.mapEnlarged;
    let h = this.mapEnlarged ? "600px" : "300px";
    (document.querySelector("agm-map") as HTMLElement).style.height = h;
    this.map.triggerResize();
  }



  public gotoMarket(id: string) {
    this.router.navigate(['/organization/' + id]);
  }

}
