import { Component, Inject, OnInit, HostListener, Input, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';

import {
	Organization, OrganizationState, OrganizationStateList,
	Contact, Technology, Country,Location, GLOBAL_COUNTRIES, ApplicationType, Service, ServiceType, TechnologyCategory
} from "../../../entity";

import { OrganizationService, TechnologyService, ApplicationTypeService, AuthenticationService, TechnologyCategoryService } from '../../services';

import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { MatSnackBar } from '@angular/material';

// hh invested improving and reformating this website: 16
@Component({
	selector: "organization-editor",
	templateUrl: "./organization-editor.component.html",
	styleUrls: ["./form-editor.component.scss", "./organization-editor.component.scss"],
})
export class OrganizationEditorComponent implements OnInit {
	@ViewChild("logoFile") logoFile;

	private id: number;
	private stateList = Array.from(OrganizationStateList.values());

	profileForm = new FormGroup({
		name: new FormControl('', Validators.required),
		logo: new FormControl('', Validators.required),
		description: new FormControl('', Validators.required),
		typologies: new FormControl('')
	}, );

	// hide = true;  -- Not used in the scope!
	matcher = new FormFieldStateMatcher();

	typologiesList: string[] = [
		'NCP',
		'CLUSTER',
		'INDUSTRIAL',
		'RESEARCH_DEVELOPMENT',
		'TECHNOLOGY',
		'HIGH_EDUCATION_UNIVERSITY',
		'ASSOCIATION',
		'THINK_TANK',
		'DESIGNERS',
		'SMARTEES_PARTNER',
	];

	private status_msg: string;
	private status_color: string;
	private status: OrganizationState;

	contactList: Contact[];
	locationList: Location[] = [];
	displayedContactColumns = ['name', 'position', 'email', 'phone', 'remove'];
	displayedLocationColumns = ['city', 'country','remove'];

	products: Technology[];

	private countriesList: Country[] = GLOBAL_COUNTRIES;

	services: Service[];

	isAdmin: boolean;

	private constructor(private router: Router, private route: ActivatedRoute,
		private http: HttpClient, private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public organizationService: OrganizationService,
		private authentication: AuthenticationService,
		private servicesService: ServiceService,
		private technologyCategoryService: TechnologyCategoryService,
		private technologyService: TechnologyService) {

		this.route.params.subscribe(params => this.id = parseInt(params.id, 10));

		this.organizationService.getAllServices().subscribe(serviceList => {
			this.services = serviceList;
		});
	}


	public ngOnInit(): void {
		this.isAdmin = this.authentication.isAdmin();

			this.organizationService.getItem(this.id).then((org: Organization) => {

				console.log(org);

				this.status = org.state;
				let msgColor = OrganizationStateList.get(this.status);

				this.status_msg = msgColor.msg;
				this.status_color = msgColor.color;

				this.profileForm.patchValue({
					name: org.name,
					logo: org.logo,
					typologies: org.typologies,
				});

				this.refreshContacts();
				this.refreshTechnologies();
				this.refreshServices();
				this.refreshLocations();

				this.organizationService.dynamicContent(org.description).subscribe((data: any) => {
					this.profileForm.patchValue({ description: data });
				});
			}, (reason) => {
				console.log(reason);
			});
	}

	updateDetails() {
		let organization = new Organization();
		organization.id = this.id;
		organization.state = OrganizationState.ACTIVE;
		organization.name = this.profileForm.get('name').value;
		organization.logo = this.profileForm.get('logo').value;
		organization.typologies = this.profileForm.get('typologies').value;


		organization.description = this.profileForm.get('description').value;

		this.organizationService.updateItem(organization).subscribe(() => {
			console.log("Organizations updated's");
			this.snackBar.open("Organization updated", null, {
				duration: 1000,
				verticalPosition: 'top'
			});
		});
	}

	private changeStatus() {
		this.organizationService.updateStatus(this.id, this.status).subscribe(() => {
			console.log("Organizations updated's");
			this.snackBar.open("Organization status changed!", null, {
				duration: 1000,
				verticalPosition: 'top'
			});

		});
	}

	private createOrganization() {
		let organization = new Organization();
		organization.name = this.profileForm.get('name').value;
		organization.logo = this.profileForm.get('logo').value;
		organization.typologies = this.profileForm.get('typologies').value;


		organization.description = this.profileForm.get('description').value;
		/*this.organizationService.create(organization).subscribe(() => {
			console.log("Organizations created");
			this.snackBar.open("Organization created", null, {
				duration: 1000,
				verticalPosition: 'top'
			});
		});*/

	}

	addLogoTypeImage() {
		let imageFile = this.logoFile.nativeElement;
		let upload_file = (imageFile && imageFile.files && imageFile.files.length == 1 && imageFile.files[0]) ? imageFile.files[0] : null;
		if (upload_file)
			this.organizationService.uploadImage(upload_file).then((result: any) => {
				if (result.success)
					this.profileForm.patchValue({ logo: "uri-ref://" + result.uuid });
			}, (reason) => {
				console.log(reason);
			});

	}

	private refreshContacts() {
		this.organizationService.getContactsById(this.id).then(contacts => {
			this.contactList = contacts;

			if (contacts.length > 0) {
				this.profileForm.patchValue({
					contact: contacts[0].name,
					email: contacts[0].email,
				});
			}

		});
	}

	private editContact(index) {
		const dialogRef = this.dialog.open(ContactPopupDialog, {
			width: '450px',
			data: { index: index, contact: this.contactList[index] }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.id > -1) {
				let contact = new ContactAndPassword();
				contact.id = this.contactList[result.id].id;
				contact.name = result.form.get('name').value;
				contact.email = result.form.get('email').value;
				contact.position = result.form.get('position').value;
				contact.phone = result.form.get('phone').value;
				contact.password = result.form.get('password').value;
				this.organizationService.updateContact(contact).subscribe(() => {
					this.snackBar.open("Contact updated", null, {
						duration: 1000,
						verticalPosition: 'top'
					});

					this.refreshContacts()
				}, () => {
					this.snackBar.open("Failed to updated contact", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
			}
		});
	}

	private deleteContact(index) {
		const dialogRef = this.dialog.open(ConfirmationDialog, { disableClose: false });
		dialogRef.componentInstance.confirmMessage = "Are you sure to delete '" + this.contactList[index].name + "' contact...?";

		dialogRef.afterClosed().subscribe(result => {
			if (result)
				this.organizationService.deleteContact(this.contactList[index].id).subscribe(() => {
					this.snackBar.open("Contact deleted", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.refreshContacts()
				}, () => {
					this.snackBar.open("Failed to delete contact", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
		});
	}

	addContact() {

		const dialogRef = this.dialog.open(ContactPopupDialog, {
			width: '450px',
			data: { index: -1, contact: new ContactAndPassword() }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				let contact = new ContactAndPassword();
				contact.name = result.form.get('name').value;
				contact.organization = this.id;
				contact.email = result.form.get('email').value;
				contact.position = result.form.get('position').value;
				contact.phone = result.form.get('phone').value;
				contact.password = result.form.get('password').value;

				this.organizationService.addContact(contact).subscribe(() => {
					this.snackBar.open("Contact added", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.refreshContacts()
				}, () => {
					this.snackBar.open("Failed to add contact", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
			}
		});
	}

	private refreshLocations() {
		this.organizationService.getLocationsById(this.id).then(locations => {
			this.locationList = locations;
		});
	}

	private editLocation(index) {

		const dialogRef = this.dialog.open(LocationPopupDialog, {
			width: '450px',
			data: { index: index, location: this.locationList[index] }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.id > -1) {
				let location = new Location();
				location.id = this.locationList[result.id].id;
				location.country = result.form.get('country').value;
				location.organization = this.id;
				location.city = result.form.get('city').value;
				location.latitude = result.form.get('latitude').value;
				location.longitude = result.form.get('longitude').value;
				this.organizationService.updateLocation(location).subscribe(() => {
					this.snackBar.open("Location updated", null, {
						duration: 1000,
						verticalPosition: 'top'
					});

					this.refreshContacts()
				}, () => {
					this.snackBar.open("Failed to updated location", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
			}
		});
	}

	private deleteLocation(id) {
		const dialogRef = this.dialog.open(ConfirmationDialog, { disableClose: false });
		dialogRef.componentInstance.confirmMessage = "Are you sure to delete the location...?";

		dialogRef.afterClosed().subscribe(result => {
			if (result)
				this.organizationService.deleteLocation(id).subscribe(() => {
					this.snackBar.open("Location deleted", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.refreshLocations()
				}, () => {
					this.snackBar.open("Failed to delete location", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
		});
	}

	addLocation() {
		const dialogRef = this.dialog.open(LocationPopupDialog, {
			width: '450px',
			data: { index: -1, location: new Location() }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				let location = new Location();
				location.country = result.form.get('country').value;
				location.organization = this.id;
				location.city = result.form.get('city').value;
				location.latitude = result.form.get('latitude').value;
				location.longitude = result.form.get('longitude').value;

				this.organizationService.addLocation(location).subscribe(() => {
					this.snackBar.open("Location added", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.refreshLocations()
				}, () => {
					this.snackBar.open("Failed to add location", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
			}
		});
	}

	private refreshTechnologies() {
		this.organizationService.getTechnologies(this.id).then(techList => {
			if (techList) {
				this.products = techList;
			}
		});
	}

	private refreshServices() {
		this.organizationService.getServices(this.id).then(servList => {
			if (servList) {
				this.services = servList;
			}
		});
	}


	addService() {

		this.servicesService.createItem(this.id).subscribe(result => {
			let serv = new Service();
			serv.id = result.id;
			serv.cardImage = '/assets/images/no_image.jpg';

			const dialogRef = this.dialog.open(ServicePopupDialog, {
				width: '1250px',
				data: serv
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result !== undefined) {
					let service = new Service();
					service.id = serv.id;
					service.name = result.form.get('name').value;
					service.cardImage = result.form.get('cardimage').value;
					service.description = result.form.get('description').value;
					service.specification = result.form.get('specification').value;
					service.type = result.form.get('type').value;
					service.cost = result.form.get('cost').value;
					this.servicesService.updateItem(service).subscribe(() => {
							this.snackBar.open("Service added", null, {
								duration: 1000,
								verticalPosition: 'top'
							});

							this.refreshServices()
						}, () => {
							this.snackBar.open("Failed to add service", null, {
								duration: 1000,
								verticalPosition: 'top'
							});
						});
				} else
					this.servicesService.deleteItem(serv.id).subscribe(() => { });
			});

		});
	}

	private deleteService(id) {

		const dialogRef = this.dialog.open(ConfirmationDialog, { disableClose: false });
		dialogRef.componentInstance.confirmMessage = "Are you sure to delete this service...?";
		dialogRef.afterClosed().subscribe(result => {
			if (result){
		console.log("Deleting technology... on index " + id);
			this.servicesService.deleteItem(id).subscribe(() => {
				this.snackBar.open("Service deleted", null, {
					duration: 1000,
					verticalPosition: 'top'
				});
				this.refreshServices()
			}, () => {
				this.snackBar.open("Failed to delete service", null, {
					duration: 1000,
					verticalPosition: 'top'
				});
			});
		}});
	}

	addTechnology() {

		this.technologyService.createItem().subscribe(result => {
			let tech = new Technology();
			tech.id = result.id;
			tech.cardImage = '/assets/images/no_image.jpg';

			const dialogRef = this.dialog.open(ProductPopupDialog, {
				width: '1250px',
				data: tech
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result !== undefined) {
					let technology = new Technology();
					technology.id = tech.id;
					technology.name = result.form.get('name').value;
					technology.cardImage = result.form.get('cardimage').value;
					technology.carouselImageUrl = result.form.get('carouselimage').value;
					technology.showInCarousel = result.form.get('showOnCarousel').value;
					technology.description = result.form.get('description').value;
					technology.specification = result.form.get('specification').value;
					technology.applicationTypes = result.form.get('applicationType').value;
					technology.technologyCategories = result.form.get('technologyCategories').value;

					this.technologyService.updateItem(technology).subscribe(() => {
						this.organizationService.addTechnology(this.id, tech.id).subscribe(() => {
							this.snackBar.open("Product added", null, {
								duration: 1000,
								verticalPosition: 'top'
							});

							this.refreshTechnologies()
						},
							() => {
								this.snackBar.open("Failed to add product", null, {
									duration: 1000,
									verticalPosition: 'top'
								});
							});
					});
				} else
					this.technologyService.deleteItem(tech.id).subscribe(() => { });
			});

		});
	}

	private deleteTechnology(id) {
		console.log("Deleting technology... on index " + id);

		this.organizationService.deleteTechnology(this.id, id).subscribe(() => {
			this.technologyService.deleteItem(id).subscribe(() => {

				this.snackBar.open("Technology deleted", null, {
					duration: 1000,
					verticalPosition: 'top'
				});
				this.refreshTechnologies()
			},
				() => {
					this.snackBar.open("Failed to delete technology", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				});
		});
	}
}

export class FormFieldStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

export interface ContactData {
	index: number;
	contact: Contact;
}

@Component({
	selector: 'app-contact-popup',
	templateUrl: './contact-popup.html',
	styleUrls: ["./form-editor.component.scss"],
})
export class ContactPopupDialog implements OnInit {
	profileForm: FormGroup;
	matcher = new FormFieldStateMatcher();

	hide = true;
	public isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

	constructor(private contactPopup: MatDialogRef<ContactPopupDialog>,
		public organizationService: OrganizationService,
		@Inject(MAT_DIALOG_DATA) public data: ContactData) { }

	ngOnInit(): void {

		//password is only required on creation
		let passwordValidator = (this.data.contact && this.data.contact.id) ? [] : [Validators.required];
		this.profileForm = new FormGroup({
			name: new FormControl(this.data.contact.name, Validators.required),
			position: new FormControl(this.data.contact.position, Validators.required),
			email: new FormControl(this.data.contact.email, [Validators.required, Validators.email], [this.isEmailUnique.bind(this)]),
			phone: new FormControl(this.data.contact.phone, Validators.required),
			password: new FormControl("", passwordValidator),
			confirm_password: new FormControl("")





		}, { validators: [this.checkPasswords] });
	}

	private isEmailUnique(email: FormControl) {
		if (!email) return of(false);
		return this.organizationService.isEmailUnique(this.data.contact && this.data.contact.id ? this.data.contact.id : -1, email.value)
			.pipe(map(res => res ? null : { emailTaken: true })).toPromise();
	}

	private checkPasswords(group: FormGroup) {

		let pass = group.controls.password.value;
		let confirmPass = group.controls.confirm_password.value;
		return pass == confirmPass ? null : { notSamePass: true }
	}

	cancel() {
		this.contactPopup.close();
	}

}



export interface LocationData {
	index: number;
	location: Location;
}

@Component({
	selector: 'app-location-popup',
	templateUrl: './location-popup.html',
	styleUrls: ["./form-editor.component.scss"],
})
export class LocationPopupDialog implements OnInit {
	locationForm: FormGroup;
	matcher = new FormFieldStateMatcher();
	locationsList=[];

	public isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
	
	@ViewChild(AgmMap) map: AgmMap;

	constructor(private locationPopup: MatDialogRef<LocationPopupDialog>,
		public organizationService: OrganizationService,
		@Inject(MAT_DIALOG_DATA) public data: LocationData) { }

	ngOnInit(): void {	

		this.locationsList = GLOBAL_COUNTRIES;
		this.locationForm = new FormGroup({
			country: new FormControl(this.data.location.country, Validators.required),
			city: new FormControl(this.data.location.city, Validators.required),
			longitude: new FormControl(this.data.location.longitude, [Validators.required, ]),
			latitude: new FormControl(this.data.location.latitude, Validators.required),
		});
	}

	changePickupMarkerLocation($event: { coords:LatLngLiteral}) {

		this.locationForm.get("longitude").setValue($event.coords.lng);
		this.locationForm.get("latitude").setValue($event.coords.lat);
		}


	cancel() {
		this.locationPopup.close();
	}

}


@Component({
	selector: "app-technology-editable-card",
	templateUrl: "../technologies/technology-card.component/technology-card.component.html",
	styleUrls: ["../technologies/technology-card.component/technology-card.component.scss", "./form-editor.component.scss", "./organization-editor.component.scss"]
})
export class TechnologyEditCardComponent {

	@Input()
	public technology: Technology;

	private constructor(private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public organizationService: OrganizationService, private technologyService: TechnologyService) { }

	@HostListener("click")
	public async onClick(): Promise<void> {

		const dialogRef = this.dialog.open(ProductPopupDialog, {
			width: '1250px',
			data: this.technology
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.id > -1) {
				let technology = new Technology();
				technology.id = this.technology.id;
				technology.name = result.form.get('name').value;
				technology.cardImage = result.form.get('cardimage').value;
				technology.carouselImageUrl = result.form.get('carouselimage').value;
				technology.showInCarousel = result.form.get('showOnCarousel').value;
				technology.description = result.form.get('description').value;
				technology.specification = result.form.get('specification').value;
				technology.applicationTypes = result.form.get('applicationType').value;
				technology.technologyCategories = result.form.get('technologyCategories').value;

				this.technologyService.updateItem(technology).subscribe(() => {

					this.snackBar.open("Product updated", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.technology = technology;
				},
					() => {
						this.snackBar.open("Failed to update product", null, {
							duration: 1000,
							verticalPosition: 'top'

						})

					});
			}
		});
	}

	public imgReady(option: any): boolean {
		return ;
	}

}

import { environment } from '../../../environments/environment';
import { ServiceService } from "../../services/ServiceService";
import { ContactAndPassword } from "../../../entity/ContactAndPassword";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { AgmMap, LatLngLiteral } from "@agm/core";

@Component({
	selector: 'app-product-popup',
	templateUrl: './product-popup.html',
	styleUrls: ["./form-editor.component.scss"],
})
export class ProductPopupDialog implements OnInit {
	@ViewChild("carouselFile") carouselFile;
	@ViewChild("cardimageFile") cardimageFile;

	@ViewChild("imageFile") imageFile;

	profileForm: FormGroup;
	matcher = new FormFieldStateMatcher();

	public images: string[];
	techCat: TechnologyCategory[];
	applicationTypes: ApplicationType[];
	isAdmin : boolean;
	constructor(private productPopup: MatDialogRef<ProductPopupDialog>,
		private technologyService: TechnologyService,
		public organizationService: OrganizationService,
		private authService: AuthenticationService,
		private applicationTypeService: ApplicationTypeService,
		private technologyCategoryService: TechnologyCategoryService,
		@Inject(MAT_DIALOG_DATA) public technology: Technology) { }

	ngOnInit(): void {
		this.isAdmin =this.authService.isAdmin();
		this.profileForm = new FormGroup({
			name: new FormControl(this.technology.name, Validators.required),
			applicationType: new FormControl('', Validators.required),
			technologyCategories: new FormControl('', Validators.required),
			description: new FormControl(this.technology.description, Validators.required),
			cardimage: new FormControl(this.technology.cardImage, Validators.required),
			carouselimage: new FormControl(this.technology.carouselImageUrl),
			showOnCarousel: new FormControl(this.technology.showInCarousel),
			specification: new FormControl(this.technology.specification, Validators.required),
			imageFileName: new FormControl(''),
		});

		this.refreshImages();

		this.applicationTypeService.getAll().then(appTypeList => {
			this.applicationTypes = appTypeList;
		});

		this.technologyCategoryService.getAll().then(techCat => {
			this.techCat = techCat;
		});



		this.technologyService.getItem(this.technology.id).then(tech => {
			this.profileForm.patchValue({
				name: tech.name,
				description: tech.description,
				cardimage: tech.cardImage,
				carouselimage: tech.carouselImageUrl,
				showOnCarousel: tech.showInCarousel,
				specification: tech.specification
			});

			this.technology = tech;

			if (this.technology.applicationTypes !== undefined)
				this.profileForm.patchValue({ applicationType: this.technology.applicationTypes.map(tech => tech.id) });

			if (this.technology.technologyCategories !== undefined)
				this.profileForm.patchValue({ technologyCategories: this.technology.technologyCategories.map(tech => tech.id) });

		})
	}

	cancel() {
		this.productPopup.close();
	}

	addImageFile(product) {
		let upload = (product) ? this.imageFile.nativeElement : this.carouselFile.nativeElement;
		let upload_file = (upload.files && upload.files.length == 1 && upload.files[0]) ? upload.files[0] : null;

		if (upload_file)
			this.organizationService.uploadImage(upload_file).then((result: any) => {
				if (result.success)
					if (product)
						this.technologyService.addImage(this.technology.id, result.uuid).subscribe(() => {
							this.refreshImages();
						})
					else
						this.profileForm.patchValue({ carouselimage: "uri-ref://" + result.uuid });
				else
					console.log('!! Image product upload error');
			}, (reason) => {
				console.log(reason);
			});

	}
	addCardImage() {
		let imageFile = this.cardimageFile.nativeElement;
		let upload_file = (imageFile && imageFile.files && imageFile.files.length == 1 && imageFile.files[0]) ? imageFile.files[0] : null;
		if (upload_file)
			this.organizationService.uploadImage(upload_file).then((result: any) => {
				if (result.success)
					this.profileForm.patchValue({ cardimage: "uri-ref://" + result.uuid });
			}, (reason) => {
				console.log(reason);
			});

	}
	private refreshImages() {
		this.technologyService.getImages(this.technology.id).then(imageList => {
			this.images = imageList;
		});
	}

	private deleteImage(id) {
		
		this.technologyService.deleteImage(this.technology.id, id).subscribe(() => { this.refreshImages() });
	}
}




@Component({
	selector: "app-service-editable-card",
	templateUrl: "../services/service-card.component/service-card.component.html",
	styleUrls: ["../services/service-card.component/service-card.component.scss", "./form-editor.component.scss","./organization-editor.component.scss"],
})
export class ServiceEditCardComponent {

	@Input()
	public service: Service;

	private constructor(private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public organizationService: OrganizationService, private serviceService: ServiceService) { }

	@HostListener("click")
	public async onClick(): Promise<void> {

		const dialogRef = this.dialog.open(ServicePopupDialog, {
			width: '1250px',
			data: this.service
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.id > -1) {
				let service = new Service();
				service.id = this.service.id;
				service.name = result.form.get('name').value;
				service.cardImage = result.form.get('cardimage').value;
				service.description = result.form.get('description').value;
				service.specification = result.form.get('specification').value;
				service.type = result.form.get('type').value;
				service.cost = result.form.get('cost').value;
				this.serviceService.updateItem(service).subscribe(() => {
					this.snackBar.open("Service updated", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
					this.service = service;


				 },()=>{
					this.snackBar.open("Failed to update service", null, {
						duration: 1000,
						verticalPosition: 'top'
					});
				 });
			}
		});

	}

}

@Component({
	selector: 'app-service-popup',
	templateUrl: './service-popup.html',
	styleUrls: ["./form-editor.component.scss"],
})
export class ServicePopupDialog implements OnInit {
	@ViewChild("imageFile") imageFile;

	serviceForm: FormGroup;
	matcher = new FormFieldStateMatcher();

	serviceTypes = [
		{ id: ServiceType.FINANCE, name: "Finance" },
		{ id: ServiceType.LEGAL, name: "Legal" },
		{ id: ServiceType.NETWORKING, name: "Networking" },
		{ id: ServiceType.TECHNICAL, name: "Technical" },
		{ id: ServiceType.TRAINING, name: "Training" },
		{ id: ServiceType.OTHER, name: "Other" },

	]

	constructor(private servicePopup: MatDialogRef<ServicePopupDialog>,
		private serviceService: ServiceService,
		public organizationService: OrganizationService,
		@Inject(MAT_DIALOG_DATA) public service: Service) { }

	ngOnInit(): void {

		this.serviceForm = new FormGroup({
			name: new FormControl(this.service.name, Validators.required),
			type: new FormControl(this.service.type, Validators.required),
			description: new FormControl(this.service.description, Validators.required),
			cardimage: new FormControl(this.service.cardImage, Validators.required),
			specification: new FormControl(this.service.specification, Validators.required),
			cost: new FormControl(this.service.cost, Validators.required),
			imageFileName: new FormControl(''),
		});




		this.serviceService.getItem(this.service.id).then(serv => {
			this.serviceForm.patchValue({
				name: serv.name,
				description: serv.description,
				type: serv.type,
				cardimage: serv.cardImage,
				specification: serv.specification,
				cost: serv.cost
			});

			this.service = serv;
		})
	}

	cancel() {
		this.servicePopup.close();
	}

	addCardImage() {
		let imageFile = this.imageFile.nativeElement;
		let upload_file = (imageFile && imageFile.files && imageFile.files.length == 1 && imageFile.files[0]) ? imageFile.files[0] : null;
		if (upload_file)
			this.organizationService.uploadImage(upload_file).then((result: any) => {
				if (result.success)
					this.serviceForm.patchValue({ cardimage: "uri-ref://" + result.uuid });
			}, (reason) => {
				console.log(reason);
			});

	}


}

// TODO: Property 'isNew' does not exist on type 'OrganizationEditorComponent'.
// search: 'isNew' in HTML linked file.