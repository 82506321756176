import { Image } from "./Image";

export class TechnologyCategory {

	public description: string;

	public image: Image;

	public id: number;

	public name: string;

	public subTitle: string;

}
