<mat-dialog-content>
  <h1>Product</h1>
    <form class="detail-form" [formGroup]="profileForm">
      <div>
          <span class="left-side">

              <mat-form-field class="detail-field ninety-percent">
                <input matInput formControlName="name" placeholder="Name" required>
              </mat-form-field>
              
              <mat-form-field class="detail-field ninety-percent">
                <mat-label>Application type:</mat-label>
                <mat-select formControlName="applicationType" multiple>
                  <mat-option *ngFor="let option of applicationTypes" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="detail-field ninety-percent">
                <textarea matInput placeholder="Description" formControlName="description" maxlength="1000" matTextareaAutosize matAutosizeMinRows=8 matAutosizeMaxRows=8></textarea>
                
              </mat-form-field>

          </span>
  				<span class="right-side">

              <mat-form-field class="detail-field ninety-percent">
                	<label class="custom-label">Card image</label>
                  <input matInput placeholder="" formControlName="cardimage"  class="uploadFile upload-label" readonly (click)="cardimageFile.click()">           
                  <button class="button" (click)="cardimageFile.click()">Add image</button>         
                    <input #cardimageFile id="cardimageFile" type="file" class="uploadFile" (change)="addCardImage()" />
                    <img class="img-preview" [src]="organizationService.dynamicUri(profileForm.get('cardimage').value)" />
              </mat-form-field>

              <section class="example-section" *ngIf="isAdmin">

                  <mat-form-field class="detail-field sixty-five-percent">
                      <label class="custom-label">Carousel image</label>
                    <input matInput placeholder="" formControlName="carouselimage" class="uploadFile upload-label" readonly (click)="carouselFile.click()">                    
                    <button class="button"  (click)="carouselFile.click()">Add image</button>
                    <input #carouselFile id="carouselFile" type="file" class="uploadFile" (change)="addImageFile(false)" />
                    <img class="img-preview" [src]="organizationService.dynamicUri(profileForm.get('carouselimage').value)" />
                  </mat-form-field>
    
                  <mat-checkbox formControlName="showOnCarousel" class="detail-field mat-form-field">Show in carousel</mat-checkbox>

              </section>

              <mat-form-field class="detail-field ninety-percent">
                <textarea matInput placeholder="Specification" formControlName="specification" matTextareaAutosize matAutosizeMinRows=8 matAutosizeMaxRows=8></textarea>
                <div class="markdown-supported"><a target="_blank" tabindex="-1" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a> is supported</div>
              </mat-form-field>
              
          </span>
          
          <mat-form-field class="detail-field ninety-five-percent">
            <mat-label>Technology Categories:</mat-label>
            <mat-select formControlName="technologyCategories" multiple>
              <mat-option *ngFor="let option of techCat" [value]="option.id">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <span>Images</span>
          <mat-divider></mat-divider>
          <div class="flex-container" style="max-height: 100px">
            <div class="element-item" *ngFor="let option of images; let idx = index">
              <mat-icon (click)="deleteImage(option)">cancel</mat-icon>
              <img class="thumbnail" [src]="organizationService.dynamicUri(option)" />
            </div>
          </div>
          <div>
            <label class="upload-label">
                <input #imageFile id="imageFile" type="file" class="uploadFile" (change)="addImageFile(true)" />
                <button mat-button (click)="imageFile.click()">Add</button>
            </label>
          </div>
   
      </div>
    </form>

</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{id: technology.id, form: profileForm}" [disabled]="!profileForm.valid" cdkFocusInitial>Ok</button>
  <button mat-button (click)="cancel()">Cancel</button>
</div>