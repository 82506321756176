import { Component, OnInit } from "@angular/core";
import { TechnologyService, OrganizationService } from "../../../services";
import { HomeCarouselViewModel } from "./home-carousel.view-model";

@Component({
	selector: "home-carousel-view",
	templateUrl: "./home-carousel.component.html",
	styleUrls: ["./home-carousel.component.scss"],
})
export class HomeCarouselComponent implements OnInit {

	public viewModels: HomeCarouselViewModel[];

	private constructor(private technologyService: TechnologyService, private organizationService: OrganizationService) { }

	public ngOnInit(): void {
		this.ngOnInitAsync().catch((reason: Error): void => {
			console.error(reason);
		});
	}

	public async ngOnInitAsync(): Promise<void> {
		this.viewModels = await this.technologyService.getHomeCarouselComponent();
	}

}
