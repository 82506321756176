import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export abstract class AbstractCRUDService<Type, Key> {
	
	protected constructor(private httpClient: HttpClient) { }

	public abstract async getAll(): Promise<Type[]>;

	public abstract async getItem(id: Key): Promise<Type>;

}
