<div>
	<h1>Welcome to the SmartEEs Marketplace</h1>
</div>

<div class="flex-container">

	<mat-card routerLink="/showroom" routerLinkActive="active">
		<mat-card-header>
			<mat-card-title><h4>Showroom</h4></mat-card-title>
			<mat-card-subtitle><b>Discover more than 35 flexible electronics technologies</b></mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<figure><img src="/assets/images/showroom/Showroom.png"/></figure>
		</mat-card-content>
	</mat-card>

	<mat-card routerLink="/community" routerLinkActive="active">
		<mat-card-header>
			<mat-card-title><h4>Community</h4></mat-card-title>
			<mat-card-subtitle>Discover and join the SmartEEs Community</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<figure><img src="/assets/images/showroom/Community.png"/></figure>
		</mat-card-content>
	</mat-card>

	<mat-card routerLink="/brokerage" routerLinkActive="active">
		<mat-card-header>
			<mat-card-title><h4>Brokerage</h4></mat-card-title>
			<mat-card-subtitle>Make business between flexible electronics providers</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content class="my-mat-card-content">
			<figure><img src="/assets/images/showroom/MatchMaking.png"/></figure>
		</mat-card-content>
	</mat-card>

</div>

