export var data_protection = `
    FUNDACIÓ EURECAT, pursuant to Regulation (EU) 2016/679 of the European Parliament and Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, as the controller of the personal data provided, provides you with the information set out below:<br/><br/>
    <ul>
        <li style="list-style: none;">
            <ul>
                <li>Identity: <b>FUNDACIÓ EURECAT</b></li>
                <li>Spanish Tax ID: <b>G66210345</b></li>
                <li>Company address: <b>Parc Tecnològic del Vallès, Avinguda Universitat Autònoma, 23 – 08290 Cerdanyola del Vallès (Barcelona)</b></li>
                <li>Email:  <a href="mailto:legal@eurecat.org">legal@eurecat.org</a></li>
                <li>Phone number: <b>+34 93 238 14 00</b></li>
                <li>Contact for the Data Protection Officer: <a href="mailto:dpo@eurecat.org">dpo@eurecat.org</a></li>
            </ul>
        </li><br/>
        <li>
            <b>Processing purpose:</b> The purpose of processing is to manage the registration of users and to create a community around flexible electronics technology to cause and produce advances and insights in its use and promotion. To do so, each registered member can chat and dialogue with other members of the community with the aim of collaboratively creating specific products, launching products on the market and/or hiring services to promote this technology.<br/><br/>
            There may be forms on our website that compile members’ data for these specific purposes. In these cases, it will be subject to the specific terms and conditions for each case. <br/><br/>
            <b>Is it obligatory to provide all the information requested on the forms on the website?</b><br/><br/>
            With respect to the registration form, members must fill out the spaces that are indicated as ‘compulsory’ (with ‘*’), as they are necessary for the correct provision of optimal service to members when using the platform. Not completing the personal data required or only doing so partially may lead to FUNDACIÓ EURECAT not being able to manage registration on the SmartEEs platform and, thus, the FUNDACIÓ EURECAT will be exempt from any responsibility for not being able to complete registration of the member in question. <br/><br/>
            The personal data that members provide to FUNDACIÓ EURECAT must be up-to-date and correct information so that the information in our records is up-to-date and free from errors. Members will be responsible for providing truthful and correct data. <br/><br/>
        </li>
        <li>
            <b>Legitimisation:</b> The legal basis for data processing is based on accepting the present usage conditions and privacy policy. <br/><br/>
        </li>
        <li>
            <b>Criteria for keeping data:</b> Personal data shall be kept as long as the member is still a part of the SmartEEs platform. After the member permanently cancels registration on the platform, all data related to the member will be properly deleted pursuant to GDPR regulations. <br/><br/>
        </li>
        <li>
            <b>Data communication:</b> We herein inform you that when an interest in a product/service is seen among registered members, the contact person will be provided to materialise technology providers’ interests and those interested in employing it, with the aim of fulfilling the purpose of the platform. <br/><br/>
            Other third parties will not be notified, except by legal obligation or the express consent of the interested party. <br/><br/>
        </li>
        <li>
            <b>International data transfers:</b> The data on this platform are housed on Amazon Web Services. This company maintains the servers in Europe pursuant to current regulations in force, so that there will be no international transfers of data involved. <br/><br/>
        </li>
    </ul>
    <div style="padding-left: 20px;">
        <b>Rights that assist the interested party:</b> Members have the right to remove the consent provided at any time. We also inform you that you can exercise your rights to access, rectify and erasure (right to be forgotten), data limitation and portability and opposition, by sending a signed letter to Parc Tecnològic del Vallès, Avinguda Universitat Autònoma, 23 – 08290 Cerdanyola del Vallès (Barcelona), or by email to <a href="mailto:dpo@eurecat.org">dpo@eurecat.org</a>. <br/><br/>
        You also have the right to withdraw consent at any time without this retroactively affecting the personal data processing done until that time. <br/><br/>
        The interested party may file a claim with the competent data protection control authority, which is currently the Spanish Data Protection Agency (Agencia Española de Protección de Datos, AEPD) via its electronic office: www.sedeagpd.gob.es/sede-electronica-web, or by sending a letter to C/ Jorge Juan, 6 – 28001 MADRID. <br/><br/>
    </div>
`;