<mat-card *ngIf="service">
	<mat-card-title><h1>{{service.name}}</h1></mat-card-title>
	<mat-card-content>
		<div>
			<p class="service-description">{{service.description}}</p>
		</div>
		<hr />
		<div class="content-div">
		
			<div class="content-div-section">
					<h1 class="">Specifications</h1>
					<hr/>
					<markdown class="markdown-body">{{service.specification}}</markdown>				
			</div>

			<div class="content-div-section">
					<h1 class="">Pricing</h1>
					<hr/>
					<markdown class="markdown-body">{{service.cost}}</markdown>				
			</div>
		</div>
	</mat-card-content>
</mat-card>
