import { Image } from "./Image";
import { Technology } from "./Technology";

export class ApplicationType {

	public description: string;

	public id: number;

	public image: Image;

	public name: string;

	public technologies: Technology[];

}
