<div #auth class="authentication mat-toolbar-row">
	<div *ngIf="!isAuthenticated()">
		<button mat-button (click)="openDialog()">Sign-in</button>
		<span class="separator">|</span>
		<button [ngClass]="{'active': active}" routerLink="/brokerage" mat-button [routerLink]="['/signup']" >Sign-up</button>
		<!-- Direct access without enter authentication values: don't forget to remove fa-ambulance modify style -->
	</div>
	<!-- Direct access without enter authentication values: don't forget to remove fa-ambulance modify style -->
	<div *ngIf="isAuthenticated()">
		<mat-menu #entityMenu="matMenu">
			<button *ngIf="!isAdmin" mat-menu-item [routerLink]="['/admin/organization/edit', id]">Organization info <mat-icon>work</mat-icon></button>
			<button *ngIf="isAdmin" class="link-btn" mat-menu-item [routerLink]="['/admin/organizations/']">Manage organizations <mat-icon>work</mat-icon></button>
			<button mat-menu-item (click)="logout()">Sign-out <mat-icon>exit_to_app</mat-icon></button>
		</mat-menu>
		<span *ngIf="!isAdmin" class="entity-logged" [matMenuTriggerFor]="entityMenu">
			<div class="avatar-image" [ngStyle]="{ 'background-image': 'url(' + logotype + ')' }" ></div>
			{{name}}
		</span>
		<span  *ngIf="isAdmin" class="entity-logged link-btn" [matMenuTriggerFor]="entityMenu">
			Admin
		</span>
	</div>

</div>
