import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuardService {

  constructor(private authentication: AuthenticationService, private router: Router) { }

  canActivate(): boolean | Promise<boolean> {
		let token = this.authentication.getToken();

		if (!token) {
			this.router.navigate(['/']);
			return false;
		}
		else if (this.authentication.isAuthenticated()) {
			return true;
		}
		else {
			this.authentication.refreshToken();
			return true;
		}
  }

}
