import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialsModule } from '../../materials.module';
import { CarouselModule } from "ngx-bootstrap";
import { MarkdownModule } from 'ngx-markdown';

import { ApplicationTypeService } from "../../services/ApplicationTypeService";
import { ImageService } from "../../services/ImageService";
import { TechnologyCategoryService } from "../../services/TechnologyCategoryService";
import { TechnologyService } from "../../services/TechnologyService";
import { TechnologyCarouselComponent } from "./technology-carousel/technology-carousel.component";

import { TechnologyComponent } from "./technology.component";

@NgModule({
	declarations: [
		TechnologyComponent,
		TechnologyCarouselComponent		
	],
	imports: [
		BrowserModule,
		CarouselModule.forRoot(),
		HttpClientModule,
		MarkdownModule.forRoot(),
		MaterialsModule,
	],
	exports: [
	],
	providers: [
		ApplicationTypeService,
		ImageService,
		TechnologyService,
		TechnologyCategoryService,
	],
} as NgModule)
export class TechnologyModule implements NgModule { }
