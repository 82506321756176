<mat-card>
    <mat-card-header id="btns">
    <div class="enlarge-btn" (click)="resizeMap()" *ngIf="mapEnlarged"><i class="fas fa-compress"></i></div>
    <div class="enlarge-btn" (click)="resizeMap()" *ngIf="!mapEnlarged"><i class="fas fa-expand"></i></div>
    </mat-card-header>
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="true" (mapReady)="updateBounds()">
      <agm-marker *ngFor="let m of markers" [latitude]="m.lat" [longitude]="m.lng" [iconUrl]="m.url">
        <agm-info-window>
          <a (click)="gotoMarket(m.id)">
            <img [src]="m.logo" width="64" height="64">
            &nbsp;&nbsp;
            <b>{{m.name}}</b>
          </a>
        </agm-info-window>
      </agm-marker>
    </agm-map>
    <mat-card-footer>
      <ul class="legend">
        <li *ngFor="let m of visibleLegend"><div class="item" [ngStyle]="{background:m.color}"></div>{{m.name}}</li>
      </ul>
    </mat-card-footer>
</mat-card>
