export class ContactAndPassword {

	public id: number;

	public name: string;

	public position: string;

	public organization:number;

	public email: string;

	public password: String;

	public phone: string | undefined;

}
