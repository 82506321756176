export class Navbar {
    // Navbar items
    public Showroom: NavItem;
    public Community: NavItem;
    public Brokerage: NavItem;
    public SignUp: NavItem;

    constructor(
        showroom: NavItem,
        community: NavItem,
        brokerage: NavItem,
        signup: NavItem
    ){
        this.Showroom = showroom;
        this.Community = community;
        this.Brokerage = brokerage;
        this.SignUp = signup;
    }
}

/**
 * @var type: (string) Navbar item name.
 * @var value: (boolean) Navbar item activated Y(true)/N(false)
 */
class NavItem {
    public type: string;
    public value: boolean;

    public constructor(type: string, value: boolean){
        this.type = type;
        this.value = value;
    }
}

// Smartees Navbar Design
export var smarteesNavbar = new Navbar(
        new NavItem('showroom',false), 
        new NavItem('community', false), 
        new NavItem('brokerage', false), 
        new NavItem('signup', false)
    );