import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ShowroomMatCardViewModel } from "./showroom-mat-card.view-model";
import { OrganizationService } from '../../../../services';

@Component({
	selector: "showroom-mat-card-view",
	templateUrl: "./showroom-mat-card.component.html",
	styleUrls: ["./showroom-mat-card.component.scss"],
})
export class ShowroomMatCardComponent {

	private readonly router: Router;

	@Input()
	public viewModel: ShowroomMatCardViewModel;

	private constructor(router: Router, public organizationService: OrganizationService) {
		this.router = router;
	}

	public async showTechnology(event: MouseEvent): Promise<void> {
       if ((event.target as HTMLElement).localName === "a") {
	       return;
       }
       await this.router.navigate([`/technologies/${this.viewModel.technologyCategoryId}`]);
	}
}
