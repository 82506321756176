import { Component, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material";
import { ActivatedRoute, Params } from "@angular/router";
import { List } from "linqts";
import { ApplicationType } from "../../../entity/ApplicationType";
import { Technology } from "../../../entity/Technology";
import { TechnologyCategory } from "../../../entity/TechnologyCategory";
import { ApplicationTypeService } from "../../services/ApplicationTypeService";
import { TechnologyCategoryService } from "../../services/TechnologyCategoryService";
import { TechnologyService } from "../../services/TechnologyService";

@Component({
	selector: "application-types-view",
	templateUrl: "./application-types.component.html",
	styleUrls: ["./application-types.component.scss"],
})
export class ApplicationTypesComponent implements OnInit {

	public modelView: {
		sideNavMode: string;
		sideNavOpened: boolean;
	} = {
		sideNavMode : "side",
		sideNavOpened : false,
	};

	public technologies: Technology[];
	public technologyCategories: TechnologyCategory[];
	public applicationType: ApplicationType;

	private technologyCategoriesIds: number[] = [];

	private activatedRoute: ActivatedRoute;
	private applicationTypeService: ApplicationTypeService;
	private technologyCategoryService: TechnologyCategoryService;
	private technologyService: TechnologyService;

	private constructor(activatedRoute: ActivatedRoute,
						               applicationTypeService: ApplicationTypeService,
						               technologyService: TechnologyService,
						               technologyCategoryService: TechnologyCategoryService) {
		this.activatedRoute = activatedRoute;
		this.applicationTypeService = applicationTypeService;
		this.technologyCategoryService = technologyCategoryService;
		this.technologyService = technologyService;
	}

	public async onCheckboxChange($event: MatCheckboxChange): Promise<void> {
		const technologyCategoryId: number = parseInt($event.source.name, 10);
		if ($event.checked) {
			this.technologyCategoriesIds.push(technologyCategoryId);
		} else {
			this.technologyCategoriesIds = new List(this.technologyCategoriesIds).Where((id: number) => id !== technologyCategoryId).ToArray();
		}
		this.technologies = await this.technologyService.getByApplicationTypeTechnologyCategoryIdsArray(this.applicationType, this.technologyCategoriesIds);
	}

	public ngOnInit(): void {
		this.onWindowScreenResize();
		window.addEventListener("resize", this.onWindowScreenResize.bind(this));

		this.activatedRoute.params.subscribe((params: Params) => {
			const technologyCategoryId: number = parseInt(params.id, 10);
			this.ngOnInitAsync(technologyCategoryId).catch((reason: Error) => {
				console.error(reason.message);
			});
		});
	}

	private async ngOnInitAsync(applicationTypeId: number): Promise<void> {
		this.applicationType = await this.applicationTypeService.getItem(applicationTypeId);
		this.technologyCategories = await this.technologyCategoryService.getItems(this.technologyService, this.applicationType);
		this.technologyCategoriesIds = this.technologyCategories.map((app: TechnologyCategory) => app.id);

		// Filter by technologies that have the same ApplicationType
		this.technologies = await this.technologyService.getAllByApplicationType(this.applicationType);
	}

	private onWindowScreenResize(): void {
		if (window.screen.width >= 1000) {
			this.modelView.sideNavMode = "side";
			this.modelView.sideNavOpened = true;
		} else {
			this.modelView.sideNavMode = "over";
			this.modelView.sideNavOpened = true;
		}
	}

}
