<mat-sidenav-container *ngIf="modelView">

	<mat-sidenav mode="{{modelView.sideNavMode}}" opened="{{modelView.sideNavOpened}}" #sidenav>

		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title>Applications</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="checkbox-container" *ngFor="let option of applicationTypes">
				<mat-checkbox (change)="onCheckboxChange($event)" name="{{option.id}}" checked="true">{{option.name}}</mat-checkbox>
				<img class="checkbox-img" src="{{option.image.url}}" style="float:right;" />
			</div>
		</mat-expansion-panel>
	</mat-sidenav>

	<mat-sidenav-content>
		<h1 *ngIf="technologyCategory">BRINGING {{technologyCategory.name.toUpperCase()}} TO YOUR PRODUCTS</h1>
		<h4 *ngIf="technologyCategory">with {{technologyCategory.subTitle}}</h4>

		<hr />

		<div class="technology-card-container">
			<technology-card-view *ngFor="let technology of technologies" [technology]="technology"></technology-card-view>
		</div>

	</mat-sidenav-content>

</mat-sidenav-container>
