<mat-card style="padding-left: 0px;">
	<mat-card-header style="padding-left: 24px;">
		<mat-card-title>
			<h3>{{contact.name}}</h3>
		</mat-card-title>
		<mat-card-subtitle>{{contact.position}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div class="contact-card mdl-card mdl-shadow--2dp">
			<div class="mdl-card__supporting-text" style="padding-bottom: 0px;padding-right: 0px;width: 100%;vertical-align: middle;">
				<ul class="mdl-list" style="padding-bottom: 0px;margin-bottom: 0px;padding-top: 0px;">
					<li class="mdl-list__item mdl-list__item--two-line">
						<span class="mdl-list__item-primary-content">
							<div *ngIf="contact.phone">
								<i class="material-icons mdl-list__item-icon" style="margin-top: 0px;">phone</i>
								<a style="color: rgba(0,0,0,.87);" href="tel:{{contact.phone}}">{{contact.phone}}</a>
							</div>

						</span>
					</li>
					<div *ngIf="contact.email!=null && contact.phone!=null" class="mdl-menu__item--full-bleed-divider"></div>
					<li class="mdl-list__item mdl-list__item--two-line" style="padding-bottom: 0px;">
						<span class="mdl-list__item-primary-content" style="top:50%">
							<div *ngIf="contact.email">
								<i class="material-icons mdl-list__item-icon" style="margin-top: 0px;">email</i>
								<a style="color: rgba(0,0,0,.87);" href="mailto:{{contact.email}}">{{contact.email}}</a>
							</div>

						</span>
					</li>
				</ul>
			</div>
		</div>
	</mat-card-content>
</mat-card>