import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { MaterialsModule } from '../materials.module';
import { AgmCoreModule } from '@agm/core';

import { DisableControlDirective } from './directives';

import { ConfirmationDialog } from "./confirmation-dialog/confirmation-dialog";
import { OrganizationMapComponent } from "./organization_map/organization_map";
import { AuthenticationComponent, LoginPopupDialog } from "./authentication/authentication.component";

import { OrganizationCardComponent } from "../components/organizations/organization-card.component/organization-card.component";
import { OrganizationsComponent } from "../components/organizations/organizations.component";


import { ApplicationTypeService } from "../services/ApplicationTypeService";
import { TechnologyCategoryService } from "../services/TechnologyCategoryService";
import { TechnologyService } from "../services/TechnologyService";
import { OrganizationService } from "../services/OrganizationService";

import { environment } from '../../environments/environment';

import { JwtInterceptorProvider } from '../services/_helpers';

@NgModule({
	schemas:  [ CUSTOM_ELEMENTS_SCHEMA ],
	declarations: [
		AuthenticationComponent,
		LoginPopupDialog, 
		ConfirmationDialog, 
		OrganizationsComponent,
		OrganizationCardComponent,
    	OrganizationMapComponent,
		DisableControlDirective,
    ],
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		RouterModule,
    MaterialsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_map_api_key
    })
	],
	exports: [
		AuthenticationComponent,
		OrganizationsComponent,
		OrganizationCardComponent,
    	OrganizationMapComponent,
    AgmCoreModule
    ],
	providers: [
		JwtInterceptorProvider,
		ApplicationTypeService,
		TechnologyService,
		TechnologyCategoryService,
    OrganizationService,
	],
	entryComponents: [ LoginPopupDialog, ConfirmationDialog ]
	} as NgModule)
export class SharedModule implements NgModule { }
