export enum Typology {
  NCP = "NCP",
  CLUSTER ="CLUSTER",
  INDUSTRIAL = "INDUSTRIAL",
  RESEARCH_DEVELOPMENT = "RESEARCH_DEVELOPMENT",
  TECHNOLOGY="TECHNOLOGY",
  HIGH_EDUCATION_UNIVERSITY="HIGH_EDUCATION_UNIVERSITY",
  ASSOCIATION="ASSOCIATION",
  THINK_TANK="THINK_TANK",
  DESIGNERS="DESIGNERS",
  SMARTEES_PARTNER="SMARTEES_PARTNER",
  INVESTOR="INVESTOR",
}
