export class Contact {

	public id: number;

	public name: string;

	public position: string;

	public organization:number;

	public email: string;
	
	public phone: string | undefined;

}
