<mat-card class="content-div" (click)="showTechnology($event)">
	<div class="content-div-section">
		<mat-card-title>
			<h1>Bringing {{viewModel.technologyCategoryName}} to your Product</h1>
		</mat-card-title>
		<mat-card-subtitle>with {{viewModel.technologyCategorySubTitle}}</mat-card-subtitle>
		<p>{{viewModel.technologyCategoryDescription}}</p>
		<a href="https://oe-a.org/viewer/-/v2article/render/26785800" target="_blank">See more in the OE-A Roadmap</a>
	</div>
	<div class="content-div-section">
		<img [src]="organizationService.dynamicUri(viewModel.technologyCategoryImage.url)" />
	</div>
</mat-card>
