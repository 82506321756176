import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "application-brochure",
	templateUrl: "./brochure.component.html",
	styleUrls: ["./brochure.component.scss"],
})
export class BrochureComponent {

	private constructor(private router: Router) { }

	public getApplicationType(application: Number) {
		this.router.navigate(['showroom/applicationTypes/' + application]);
	}

}
