import { ApplicationType } from "./ApplicationType";
import { TechnologyCategory } from "./TechnologyCategory";

export class Technology  {

	public applicationTypes: ApplicationType[];

	public cardImage: string;

	public carouselImageUrl: string | undefined;

	public description: string;

	public id: number;

	public images: string[];

	public index: number;

	public name: string;

	public showInCarousel: boolean;

	public specification: string;

	public technologyCategories: TechnologyCategory[];

}
