import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "home-view",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
	communityPage = false;
	brokeragePage = false;
	keyFiguresPage = false;
	signupPage = false;
	
	private constructor(private router: Router) {	
		
	router.events.subscribe((val:any) => {

		this.keyFiguresPage = (	val.url && val.url.indexOf("/keyFigures") != -1)
		this.communityPage = (	val.url && val.url.indexOf("/community") != -1)
		this.brokeragePage = (	val.url && val.url.indexOf("/brokerage") != -1)
		this.signupPage = (	val.url && val.url.indexOf("/signup") != -1)
		
		
	});
}

}
