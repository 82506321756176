import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ApplicationTypeService, TechnologyCategoryService } from "../../services";
import { NavBarViewModel } from "./nav-bar.view-model";
import { Router, NavigationEnd, Event } from "@angular/router";

import { smarteesNavbar } from '../../../assets/models/navbar';

@Component({
	selector: "nav-bar-view",
	templateUrl: "./nav-bar.component.html",
	styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  
	public viewModel: NavBarViewModel;
	public breadCrumbElements: BreadCrumbElement[];
	public navbarElements = smarteesNavbar;

	private constructor(
		private applicationTypeService: ApplicationTypeService, 
		private location: Location, 
		private router: Router,
		private technologyCategoryService: TechnologyCategoryService
	) {
		router.events.subscribe( (event: Event) => {
			if (event instanceof NavigationEnd) {
				this.parseURL(this.router.url);
			}
		});
	}

	private parseURL(url: string) {
		var urlParams: Array<string> = url.split('/');
		if (!urlParams) return;

		if (urlParams.includes(this.navbarElements.Showroom.type)) {
			this.activateElement('showroom');
		} else if (urlParams.includes(this.navbarElements.Community.type)) {
			this.activateElement('community');
		} else if (urlParams.includes(this.navbarElements.Brokerage.type)) {
			this.activateElement('brokerage');
		} else if (urlParams.includes(this.navbarElements.SignUp.type)) {
			this.activateElement('signup');
		}else {
			this.activateElement(null);
		}
	}

	private activateElement(active: string) {
		for (var elem in this.navbarElements){
			this.navbarElements[elem].value = (this.navbarElements[elem].type === active) ? true : false;
		}
	}

	private resetBreadcrumbs() {
		this.breadCrumbElements = [];
		this.breadCrumbElements.push({label:"Home",uri:"/"});
	}

	public ngOnInit(): void {
		this.resetBreadcrumbs();
		this.ngOnInitAsync().catch((reason: Error) => {
			console.error(reason.message);
		});
	}

	public href(url: string): void {
		window.location.href = url;
	}
	public navigate(index:number){
		if(index == 0) {
			this.resetBreadcrumbs();
			this.router.navigateByUrl( "/");
		} else {
			let dest = this.breadCrumbElements[index];
			this.breadCrumbElements = this.breadCrumbElements.slice(0,index);
			this.router.navigateByUrl(dest.uri);
		}
		
	}
	public async ngOnInitAsync(): Promise<void> {
		this.viewModel = {
			applicationTypesViewModel: await this.applicationTypeService.getNavHomeComponent(),
			technologyCategoriesNavHomeViewModel: await this.technologyCategoryService.getNavHomeComponent(),
		};


		this.router.events.subscribe( (event:Event) => {
			if (event instanceof NavigationEnd) {
				window.scrollTo(0, 0);

				let ga = (<any>window).ga;
				if (ga in window) {
					(<any>window).ga('set', 'page', event.urlAfterRedirects);
					let tracker = ga.getAll()[0];
					if (tracker)
						tracker.send("pageview",event.urlAfterRedirects);
				}

				if(event.url == "/showroom") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Showroom",uri:"/showroom"});
				}

				if(event.url == "/signup") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Sign-up",uri:"/signup"});
				}

				if(event.url == "/") {
					this.resetBreadcrumbs();
				}
				if(event.url == "/community") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Community",uri:"/community"});
				}

				if(event.url == "/keyFigures") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Key Figures",uri:"/keyFigures"});
				}

				if(event.url == "/admin/organizations") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Admin",uri:"/admin/organizations"});
				}

				
				if(event.url.startsWith("/admin/organization/edit")) {
					this.breadCrumbElements.push({label:"Organization",uri:null});
				}


				if(event.url.startsWith("/showroom/technologies/")) {
					let id = parseInt(event.url.split("/showroom/technologies/")[1]);
					let name = this.viewModel.technologyCategoriesNavHomeViewModel.find(e=>e.technologyCategoryId == id).technologyCategoryName;
					this.breadCrumbElements.push({label:name,uri:event.url});
				}

				if(event.url.startsWith("/showroom/applicationTypes/")) {
					let id = parseInt(event.url.split("/showroom/applicationTypes/")[1]);
					let name = this.viewModel.applicationTypesViewModel.find(e=>e.applicationTypeId == id).applicationTypeName;
					this.breadCrumbElements.push({label:name,uri:event.url});
				}

				if(event.url.startsWith("/organization/")) {
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Technology") this.breadCrumbElements.pop();
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Service") this.breadCrumbElements.pop();
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Organization") this.breadCrumbElements.pop();
					this.breadCrumbElements.push({label:"Organization",uri:event.url});
				}

				if(event.url.startsWith("/technology/")) {
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Technology") this.breadCrumbElements.pop();
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Service") this.breadCrumbElements.pop();
					this.breadCrumbElements.push({label:"Technology",uri:event.url});
				}

				if(event.url.startsWith("/service/")) {
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Technology") this.breadCrumbElements.pop();
					if(this.breadCrumbElements.length>1 && this.breadCrumbElements[this.breadCrumbElements.length-1].label == "Service") this.breadCrumbElements.pop();
					this.breadCrumbElements.push({label:"Service",uri:event.url});
				}

				if(event.url == "/organizations") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Community",uri:"/community"});
					this.breadCrumbElements.push({label:"Qualified Directory",uri:"/organizations"});
				}

				if(event.url == "/brokerage") {
					this.resetBreadcrumbs();
					this.breadCrumbElements.push({label:"Brokerage",uri:"/brokerage"});
				}

				if(event.url == "/brokerage/tech") {
					this.breadCrumbElements.push({label:"Technology providers",uri:"/brokerage/tech"});
				}

				if(event.url == "/brokerage/hiring") {
					this.breadCrumbElements.push({label:"Product providers",uri:"/brokerage/hiring"});
				}
				
				if(event.url == "/brokerage/services") {
					this.breadCrumbElements.push({label:"Other service providers",uri:"/brokerage/services"});
				}
			}

	});

	}
}
class BreadCrumbElement 
{
	public label;
	public uri;

}