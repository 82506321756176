import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCardModule, MatCheckboxModule, MatExpansionModule, MatSidenavModule } from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "../../app-routing.module";
import { ApplicationTypeService } from "../../services/ApplicationTypeService";
import { ImageService } from "../../services/ImageService";
import { TechnologyCategoryService } from "../../services/TechnologyCategoryService";
import { TechnologyService } from "../../services/TechnologyService";
import { TechnologyModule } from "../technology/technology.module";
import { ApplicationTypeCardComponent } from "./application-type-card.component/application-type-card.component";
import { ApplicationTypesComponent } from "./application-types.component";

@NgModule({
	declarations: [
		ApplicationTypesComponent,
		ApplicationTypeCardComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		MatCardModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatSidenavModule,
		TechnologyModule,
	],
	providers: [
		ApplicationTypeService,
		ImageService,
		TechnologyService,
		TechnologyCategoryService,
	],
} as NgModule)
export class ApplicationTypesModule implements NgModule {}
