<mat-sidenav-container>

	<mat-sidenav mode="{{modelView.sideNavMode}}" opened="{{modelView.sideNavOpened}}" #sidenav>

		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title>Technology Category</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="checkbox-container" *ngFor="let technologyCategory of technologyCategories">
				<mat-checkbox (change)="onCheckboxChange($event)" name="{{technologyCategory.id}}" checked="true">{{technologyCategory.name}}</mat-checkbox>
			</div>
		</mat-expansion-panel>
		<!--
		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title>Technology Type</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="checkbox-container">
				<mat-checkbox checked="true">Product</mat-checkbox>
				<img class="checkbox-img" src="/assets/images/icons/technology/product.svg" />
			</div>
			<div class="checkbox-container">
				<mat-checkbox checked="true">Application Experiment</mat-checkbox>
				<img class="checkbox-img" src="/assets/images/icons/technology/experiment.svg" />
			</div>
		</mat-expansion-panel>
		-->
	</mat-sidenav>

	<mat-sidenav-content>
		<h1 *ngIf="applicationType">{{applicationType.name.toUpperCase()}}</h1>

		<hr />

		<div class="technology-card-container">
			<application-type-card-view *ngFor="let technology of technologies" [technology]="technology"></application-type-card-view>
		</div>

	</mat-sidenav-content>

</mat-sidenav-container>
