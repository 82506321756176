import { Typology } from ".";

export class SignupForm
{
    
	public name: string;

	public description: string;

    public typologies: Typology[];
    

    
	public contactName: string;

	public position: string;

	public email: string;

	public password: String;

	public phone: string | undefined;
}