<mat-dialog-content>
  <h1>Contact</h1>
    <form class="detail-form" [formGroup]="profileForm">
        <mat-form-field style="margin: 0; padding: 0; display: inline;">
          <input matInput formControlName="name" placeholder="Name" required>
        </mat-form-field>
  
        <mat-form-field style="margin: 0; padding: 0; display: inline;">
            <input matInput formControlName="position" placeholder="Position" required>
        </mat-form-field>
                    
        <mat-form-field style="margin: 0; padding: 0; display: inline;">
          <input matInput formControlName="email" placeholder="Email" [errorStateMatcher]="matcher" required>
          <mat-error *ngIf="profileForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
  
          <mat-error *ngIf="profileForm.get('email').errors && profileForm.get('email').errors.emailTaken">
              😢 Oh noes, this email is already taken!
            </mat-error>
        </mat-form-field>
                    
        <mat-form-field style="margin: 0; padding: 0; display: inline;">
          <input matInput type="tel" formControlName="phone" placeholder="Telephone">
        </mat-form-field>
  
        You can only update your own password! {{isIEOrEdge}}
  
         <mat-form-field class="detail-field ninety-percent" style="margin: 0; padding: 0; display: inline;">
            <mat-icon matSuffix class="cursor-icon" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'">
          </mat-form-field>
          
  
          <mat-form-field class="detail-field ninety-percent" style="margin: 0; padding: 0; display: inline;">
            <input matInput placeholder="Password (Repeat)" formControlName="confirm_password" [type]="hide ? 'password' : 'text'" [errorStateMatcher]="matcher">
          </mat-form-field>
          <mat-error *ngIf="profileForm.hasError('notSamePass')">
            Please enter a same password than on previous field
          </mat-error>
      </form>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{id: data.index, form: profileForm}" [disabled]="!profileForm.valid" cdkFocusInitial>Ok</button>
  <button mat-button (click)="cancel()">Cancel</button>
</div>