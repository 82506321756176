<mat-card>
	<img style="max-width: 300px" [src]="organizationService.dynamicUri(technology.cardImage)" />
	<mat-card-header style="margin-top:1em">
		<mat-card-subtitle><h3>{{technology.name}}</h3></mat-card-subtitle>
		<mat-card-subtitle>Solutions lookin for</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content class="icon-container">

		<div *ngFor="let option of technology.applicationTypes">
			<img *ngIf="imgReady(option)" style="max-width:300px" class="icon" src="{{option.image.url}}"/>
		</div>
	</mat-card-content>
</mat-card>
