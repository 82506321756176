import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Technology } from "../../entity/Technology";
import { ApplicationType } from "../../entity/ApplicationType";
import { TechnologyService } from "./TechnologyService";
import { TechnologyCategory } from "../../entity/TechnologyCategory";

import { ShowroomViewModel } from "../components/home/showroom/showroom.view-model";
import { ShowroomMatCardViewModel } from "../components/home/showroom/showroom-mat-card/showroom-mat-card.view-model";
import { TechnologyCategoryNavHomeViewModel } from "../components/nav-bar/nav-bar.view-model";

import { environment } from '../../environments/environment';
import { AbstractCRUDService } from "./AbstractCRUDService";

@Injectable()
export class TechnologyCategoryService extends AbstractCRUDService<TechnologyCategory, number> {

    private actionUrl: string;

    constructor(private http: HttpClient) {
      super(http);
        this.actionUrl = environment.api_server + 'categories';
    }

	public async getAll(): Promise<TechnologyCategory[]> {
		return await 
			this.http.get<TechnologyCategory[]>(this.actionUrl + '?projection=categoryTechnoInline')
        .pipe(map((result:any) => { return result._embedded.categories }))
			    .toPromise();
	}

	public async getItem(id: number): Promise<TechnologyCategory> {
		return await this.http.get<TechnologyCategory>(`${this.actionUrl}/${id}?projection=categoryTechnoInline`).toPromise();
	}

	public async getShowroomComponent(): Promise<ShowroomViewModel> {
		return {
			showroomMatCardViewModels: (await this.getAll()).map((technologyCategory: TechnologyCategory): ShowroomMatCardViewModel =>
				({
					technologyCategoryDescription: technologyCategory.description,
					technologyCategoryId: technologyCategory.id,
					technologyCategoryImage: technologyCategory.image,
					technologyCategoryName: technologyCategory.name,
					technologyCategorySubTitle: technologyCategory.subTitle,
				})),
		};
	}

	public async getNavHomeComponent(): Promise<TechnologyCategoryNavHomeViewModel[]> {
		return (await this.getAll()).map((technologyCategory: TechnologyCategory): TechnologyCategoryNavHomeViewModel =>
			({
				technologyCategoryId: technologyCategory.id,
				technologyCategoryName: technologyCategory.name,
			}));
	}

	public async getItems(technologyService: TechnologyService, applicationType: ApplicationType): Promise<TechnologyCategory[]> {
		const technologies: Technology[] = await technologyService.getAllByApplicationType(applicationType);
		const result: Map<number, TechnologyCategory> = new Map<number, TechnologyCategory>();
		for (const technology of technologies) {
			for (const technologyCategory of technology.technologyCategories) {
				result.set(technologyCategory.id, technologyCategory);
			}
		}
		return Array.from(result.values());
	}
}
