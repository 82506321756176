
export enum ServiceType {

    TECHNICAL = "TECHNICAL",
    NETWORKING = "NETWORKING",
    TRAINING = "TRAINING",
    LEGAL = "LEGAL",
    FINANCE = "FINANCE",
    OTHER = "OTHER",
}

export class Service {

	public id: number;

	public name: string;

	public description: string;

    public type: ServiceType;
    

	public cardImage: string;

    public specification: string;

    public cost:string;
    
}
