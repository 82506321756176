import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ApplicationTypesComponent } from "./components/application-types/application-types.component";
import { BrokerageComponent } from "./components/home/brokerage/brokerage";
import { CommunityComponent } from "./components/home/community/community";
import { HomeComponent } from "./components/home/home.component";
import { MarketplaceComponent } from "./components/home/marketplace/marketplace.component";
import { ShowroomComponent } from "./components/home/showroom/showroom.component";
import { OrganizationsComponent } from "./components/organizations/organizations.component";
import { OrganizationComponent } from "./components/organization/organization.component";
import { TechnologiesComponent } from "./components/technologies/technologies.component";
import { TechnologyComponent } from "./components/technology/technology.component";

import { AuthGuardService } from './services/auth-guard.service';

import { OrganizationEditorComponent } from "./components/organization-editor/organization-editor.component";

import { SharedModule } from "./shared/shared.module";
import { HiringComponent } from "./components/home/brokerage/hiring/hiring";
import { ServicesComponent } from "./components/home/brokerage/services/services";
import { TechComponent } from "./components/home/brokerage/tech/tech";
import { AdminOrganizationsListComponent } from "./components/admin-organizations-list/admin-organizations-list.component";
import { ServiceComponent } from "./components/service/service.component";
import { RegisterComponent } from "./components/home/register/register";
import { BrochureComponent } from "./components/brochure/brochure.component";

const appRoutes: Routes = [
	{
		path: "", component: HomeComponent, children: [
			{ path: "", component: MarketplaceComponent },
			{ path: "brokerage/hiring", component: HiringComponent },
			{ path: "brokerage/tech", component: TechComponent },
			{ path: "brokerage/services", component: ServicesComponent },
			{ path: "brokerage", component: BrokerageComponent},
			{ path: "community", component: CommunityComponent },
			{ path: "keyFigures", component: BrochureComponent },
			{ path: "showroom/technologies/:id", component: TechnologiesComponent },
			{ path: "showroom/applicationTypes/:id", component: ApplicationTypesComponent },
			{ path: "showroom", component: ShowroomComponent },
			{ path: "signup", component: RegisterComponent },
		],
	},
	

	
	
	{ path: "admin/organizations", component: AdminOrganizationsListComponent },
	{ path: "applicationTypes/:id", component: ApplicationTypesComponent },
	{ path: "organizations", component: OrganizationsComponent },
	{ path: "organization/:id", component: OrganizationComponent },
	{ path: "technologies/:id", component: TechnologiesComponent },
	{ path: "technology/:id", component: TechnologyComponent },
	{ path: "service/:id", component: ServiceComponent },
	{ 
		path: "admin/organization/edit/:id", 
		canActivate: [AuthGuardService],
		component: OrganizationEditorComponent 
	},
] as Routes;

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes),
		SharedModule,
	],
	exports: [
		RouterModule,
	],
})
export class AppRoutingModule implements NgModule { }
