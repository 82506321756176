import { Country } from './Country';

export const GLOBAL_COUNTRIES: Country[] = [
  {
    "name": "Afghanistan",
    "iso_A2": "AF",
    "un_A3": "AFG",
    "num": 4,
    "dialing": 93
  },
  {
    "name": "Albania",
    "iso_A2": "AL",
    "un_A3": "ALB",
    "num": 8,
    "dialing": 355
  },
  {
    "name": "Algeria",
    "iso_A2": "DZ",
    "un_A3": "DZA",
    "num": 12,
    "dialing": 213
  },
  {
    "name": "American Samoa",
    "iso_A2": "AS",
    "un_A3": "ASM",
    "num": 16,
    "dialing": 684
  },
  {
    "name": "Andorra",
    "iso_A2": "AD",
    "un_A3": "AND",
    "num": 20,
    "dialing": 376
  },
  {
    "name": "Angola",
    "iso_A2": "AO",
    "un_A3": "AGO",
    "num": 24,
    "dialing": 244
  },
  {
    "name": "Anguilla",
    "iso_A2": "AI",
    "un_A3": "AIA",
    "num": 660,
    "dialing": 264
  },
  {
    "name": "Antarctica",
    "iso_A2": "AQ",
    "un_A3": "ATA",
    "num": 10,
    "dialing": 672
  },
  {
    "name": "Antigua and Barbuda",
    "iso_A2": "AG",
    "un_A3": "ATG",
    "num": 28,
    "dialing": 268
  },
  {
    "name": "Argentina",
    "iso_A2": "AR",
    "un_A3": "ARG",
    "num": 32,
    "dialing": 54
  },
  {
    "name": "Armenia",
    "iso_A2": "AM",
    "un_A3": "ARM",
    "num": 51,
    "dialing": 374
  },
  {
    "name": "Aruba",
    "iso_A2": "AW",
    "un_A3": "ABW",
    "num": 533,
    "dialing": 297
  },
  {
    "name": "Australia",
    "iso_A2": "AU",
    "un_A3": "AUS",
    "num": 36,
    "dialing": 61
  },
  {
    "name": "Austria",
    "iso_A2": "AT",
    "un_A3": "AUT",
    "num": 40,
    "dialing": 43
  },
  {
    "name": "Azerbaijan",
    "iso_A2": "AZ",
    "un_A3": "AZE",
    "num": 31,
    "dialing": 994
  },
  {
    "name": "Bahamas",
    "iso_A2": "BS",
    "un_A3": "BHS",
    "num": 44,
    "dialing": 242
  },
  {
    "name": "Bahrain",
    "iso_A2": "BH",
    "un_A3": "BHR",
    "num": 48,
    "dialing": 973
  },
  {
    "name": "Bangladesh",
    "iso_A2": "BD",
    "un_A3": "BGD",
    "num": 50,
    "dialing": 880
  },
  {
    "name": "Barbados",
    "iso_A2": "BB",
    "un_A3": "BRB",
    "num": 52,
    "dialing": 246
  },
  {
    "name": "Belarus",
    "iso_A2": "BY",
    "un_A3": "BLR",
    "num": 112,
    "dialing": 375
  },
  {
    "name": "Belgium",
    "iso_A2": "BE",
    "un_A3": "BEL",
    "num": 56,
    "dialing": 32
  },
  {
    "name": "Belize",
    "iso_A2": "BZ",
    "un_A3": "BLZ",
    "num": 84,
    "dialing": 501
  },
  {
    "name": "Benin",
    "iso_A2": "BJ",
    "un_A3": "BEN",
    "num": 204,
    "dialing": 229
  },
  {
    "name": "Bermuda",
    "iso_A2": "BM",
    "un_A3": "BMU",
    "num": 60,
    "dialing": 441
  },
  {
    "name": "Bhutan",
    "iso_A2": "BT",
    "un_A3": "BTN",
    "num": 64,
    "dialing": 975
  },
  {
    "name": "Bolivia",
    "iso_A2": "BO",
    "un_A3": "BOL",
    "num": 68,
    "dialing": 591
  },
  {
    "name": "Bonaire",
    "iso_A2": "BQ",
    "un_A3": "BES",
    "num": 535,
    "dialing": 599
  },
  {
    "name": "Bosnia and Herzegovina",
    "iso_A2": "BA",
    "un_A3": "BIH",
    "num": 70,
    "dialing": 387
  },
  {
    "name": "Botswana",
    "iso_A2": "BW",
    "un_A3": "BWA",
    "num": 72,
    "dialing": 267
  },
  {
    "name": "Bouvet Island",
    "iso_A2": "BV",
    "un_A3": "BVT",
    "num": 74,
    "dialing": 47
  },
  {
    "name": "Brazil",
    "iso_A2": "BR",
    "un_A3": "BRA",
    "num": 76,
    "dialing": 55
  },
  {
    "name": "British Indian Ocean Territory",
    "iso_A2": "IO",
    "un_A3": "IOT",
    "num": 86,
    "dialing": 246
  },
  {
    "name": "Brunei Darussalam",
    "iso_A2": "BN",
    "un_A3": "BRN",
    "num": 96,
    "dialing": 673
  },
  {
    "name": "Bulgaria",
    "iso_A2": "BG",
    "un_A3": "BGR",
    "num": 100,
    "dialing": 359
  },
  {
    "name": "Burkina Faso",
    "iso_A2": "BF",
    "un_A3": "BFA",
    "num": 854,
    "dialing": 226
  },
  {
    "name": "Burundi",
    "iso_A2": "BI",
    "un_A3": "BDI",
    "num": 108,
    "dialing": 257
  },
  {
    "name": "Cambodia",
    "iso_A2": "KH",
    "un_A3": "KHM",
    "num": 116,
    "dialing": 855
  },
  {
    "name": "Cameroon",
    "iso_A2": "CM",
    "un_A3": "CMR",
    "num": 120,
    "dialing": 237
  },
  {
    "name": "Canada",
    "iso_A2": "CA",
    "un_A3": "CAN",
    "num": 124,
    "dialing": 1
  },
  {
    "name": "Cape Verde",
    "iso_A2": "CV",
    "un_A3": "CPV",
    "num": 132,
    "dialing": 238
  },
  {
    "name": "Cayman Islands",
    "iso_A2": "KY",
    "un_A3": "CYM",
    "num": 136,
    "dialing": 345
  },
  {
    "name": "Central African Republic",
    "iso_A2": "CF",
    "un_A3": "CAF",
    "num": 140,
    "dialing": 236
  },
  {
    "name": "Chad",
    "iso_A2": "TD",
    "un_A3": "TCD",
    "num": 148,
    "dialing": 235
  },
  {
    "name": "Chile",
    "iso_A2": "CL",
    "un_A3": "CHL",
    "num": 152,
    "dialing": 56
  },
  {
    "name": "China",
    "iso_A2": "CN",
    "un_A3": "CHN",
    "num": 156,
    "dialing": 86
  },
  {
    "name": "Christmas Island",
    "iso_A2": "CX",
    "un_A3": "CXR",
    "num": 162,
    "dialing": 61
  },
  {
    "name": "Cocos (Keeling) Islands",
    "iso_A2": "CC",
    "un_A3": "CCK",
    "num": 166,
    "dialing": 61
  },
  {
    "name": "Colombia",
    "iso_A2": "CO",
    "un_A3": "COL",
    "num": 170,
    "dialing": 57
  },
  {
    "name": "Comoros",
    "iso_A2": "KM",
    "un_A3": "COM",
    "num": 174,
    "dialing": 269
  },
  {
    "name": "Congo",
    "iso_A2": "CG",
    "un_A3": "COG",
    "num": 178,
    "dialing": 242
  },
  {
    "name": "Democratic Republic of the Congo",
    "iso_A2": "CD",
    "un_A3": "COD",
    "num": 180,
    "dialing": 243
  },
  {
    "name": "Cook Islands",
    "iso_A2": "CK",
    "un_A3": "COK",
    "num": 184,
    "dialing": 682
  },
  {
    "name": "Costa Rica",
    "iso_A2": "CR",
    "un_A3": "CRI",
    "num": 188,
    "dialing": 506
  },
  {
    "name": "Croatia",
    "iso_A2": "HR",
    "un_A3": "HRV",
    "num": 191,
    "dialing": 385
  },
  {
    "name": "Cuba",
    "iso_A2": "CU",
    "un_A3": "CUB",
    "num": 192,
    "dialing": 53
  },
  {
    "name": "Curacao",
    "iso_A2": "CW",
    "un_A3": "CUW",
    "num": 531,
    "dialing": 599
  },
  {
    "name": "Cyprus",
    "iso_A2": "CY",
    "un_A3": "CYP",
    "num": 196,
    "dialing": 357
  },
  {
    "name": "Czech Republic",
    "iso_A2": "CZ",
    "un_A3": "CZE",
    "num": 203,
    "dialing": 420
  },
  {
    "name": "Cote d'Ivoire",
    "iso_A2": "CI",
    "un_A3": "CIV",
    "num": 384,
    "dialing": 225
  },
  {
    "name": "Denmark",
    "iso_A2": "DK",
    "un_A3": "DNK",
    "num": 208,
    "dialing": 45
  },
  {
    "name": "Djibouti",
    "iso_A2": "DJ",
    "un_A3": "DJI",
    "num": 262,
    "dialing": 253
  },
  {
    "name": "Dominica",
    "iso_A2": "DM",
    "un_A3": "DMA",
    "num": 212,
    "dialing": 767
  },
  {
    "name": "Dominican Republic",
    "iso_A2": "DO",
    "un_A3": "DOM",
    "num": 214,
    "dialing": 809
  },
  {
    "name": "Ecuador",
    "iso_A2": "EC",
    "un_A3": "ECU",
    "num": 218,
    "dialing": 593
  },
  {
    "name": "Egypt",
    "iso_A2": "EG",
    "un_A3": "EGY",
    "num": 818,
    "dialing": 20
  },
  {
    "name": "El Salvador",
    "iso_A2": "SV",
    "un_A3": "SLV",
    "num": 222,
    "dialing": 503
  },
  {
    "name": "Equatorial Guinea",
    "iso_A2": "GQ",
    "un_A3": "GNQ",
    "num": 226,
    "dialing": 240
  },
  {
    "name": "Eritrea",
    "iso_A2": "ER",
    "un_A3": "ERI",
    "num": 232,
    "dialing": 291
  },
  {
    "name": "Estonia",
    "iso_A2": "EE",
    "un_A3": "EST",
    "num": 233,
    "dialing": 372
  },
  {
    "name": "Ethiopia",
    "iso_A2": "ET",
    "un_A3": "ETH",
    "num": 231,
    "dialing": 251
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "iso_A2": "FK",
    "un_A3": "FLK",
    "num": 238,
    "dialing": 500
  },
  {
    "name": "Faroe Islands",
    "iso_A2": "FO",
    "un_A3": "FRO",
    "num": 234,
    "dialing": 298
  },
  {
    "name": "Fiji",
    "iso_A2": "FJ",
    "un_A3": "FJI",
    "num": 242,
    "dialing": 679
  },
  {
    "name": "Finland",
    "iso_A2": "FI",
    "un_A3": "FIN",
    "num": 246,
    "dialing": 358
  },
  {
    "name": "France",
    "iso_A2": "FR",
    "un_A3": "FRA",
    "num": 250,
    "dialing": 33
  },
  {
    "name": "French Guiana",
    "iso_A2": "GF",
    "un_A3": "GUF",
    "num": 254,
    "dialing": 594
  },
  {
    "name": "French Polynesia",
    "iso_A2": "PF",
    "un_A3": "PYF",
    "num": 258,
    "dialing": 689
  },
  {
    "name": "French Southern Territories",
    "iso_A2": "TF",
    "un_A3": "ATF",
    "num": 260,
    "dialing": 262
  },
  {
    "name": "Gabon",
    "iso_A2": "GA",
    "un_A3": "GAB",
    "num": 266,
    "dialing": 241
  },
  {
    "name": "Gambia",
    "iso_A2": "GM",
    "un_A3": "GMB",
    "num": 270,
    "dialing": 220
  },
  {
    "name": "Georgia",
    "iso_A2": "GE",
    "un_A3": "GEO",
    "num": 268,
    "dialing": 995
  },
  {
    "name": "Germany",
    "iso_A2": "DE",
    "un_A3": "DEU",
    "num": 276,
    "dialing": 49
  },
  {
    "name": "Ghana",
    "iso_A2": "GH",
    "un_A3": "GHA",
    "num": 288,
    "dialing": 233
  },
  {
    "name": "Gibraltar",
    "iso_A2": "GI",
    "un_A3": "GIB",
    "num": 292,
    "dialing": 350
  },
  {
    "name": "Greece",
    "iso_A2": "GR",
    "un_A3": "GRC",
    "num": 300,
    "dialing": 30
  },
  {
    "name": "Greenland",
    "iso_A2": "GL",
    "un_A3": "GRL",
    "num": 304,
    "dialing": 299
  },
  {
    "name": "Grenada",
    "iso_A2": "GD",
    "un_A3": "GRD",
    "num": 308,
    "dialing": 473
  },
  {
    "name": "Guadeloupe",
    "iso_A2": "GP",
    "un_A3": "GLP",
    "num": 312,
    "dialing": 590
  },
  {
    "name": "Guam",
    "iso_A2": "GU",
    "un_A3": "GUM",
    "num": 316,
    "dialing": 671
  },
  {
    "name": "Guatemala",
    "iso_A2": "GT",
    "un_A3": "GTM",
    "num": 320,
    "dialing": 502
  },
  {
    "name": "Guernsey",
    "iso_A2": "GG",
    "un_A3": "GGY",
    "num": 831,
    "dialing": 44
  },
  {
    "name": "Guinea",
    "iso_A2": "GN",
    "un_A3": "GIN",
    "num": 324,
    "dialing": 224
  },
  {
    "name": "Guinea-Bissau",
    "iso_A2": "GW",
    "un_A3": "GNB",
    "num": 624,
    "dialing": 245
  },
  {
    "name": "Guyana",
    "iso_A2": "GY",
    "un_A3": "GUY",
    "num": 328,
    "dialing": 592
  },
  {
    "name": "Haiti",
    "iso_A2": "HT",
    "un_A3": "HTI",
    "num": 332,
    "dialing": 509
  },
  {
    "name": "Heard Island and McDonald\A0Islands",
    "iso_A2": "HM",
    "un_A3": "HMD",
    "num": 334,
    "dialing": 672
  },
  {
    "name": "Holy See (Vatican City State)",
    "iso_A2": "VA",
    "un_A3": "VAT",
    "num": 336,
    "dialing": 379
  },
  {
    "name": "Honduras",
    "iso_A2": "HN",
    "un_A3": "HND",
    "num": 340,
    "dialing": 504
  },
  {
    "name": "Hong Kong",
    "iso_A2": "HK",
    "un_A3": "HKG",
    "num": 344,
    "dialing": 852
  },
  {
    "name": "Hungary",
    "iso_A2": "HU",
    "un_A3": "HUN",
    "num": 348,
    "dialing": 36
  },
  {
    "name": "Iceland",
    "iso_A2": "IS",
    "un_A3": "ISL",
    "num": 352,
    "dialing": 354
  },
  {
    "name": "India",
    "iso_A2": "IN",
    "un_A3": "IND",
    "num": 356,
    "dialing": 91
  },
  {
    "name": "Indonesia",
    "iso_A2": "ID",
    "un_A3": "IDN",
    "num": 360,
    "dialing": 62
  },
  {
    "name": "Iran, Islamic Republic of",
    "iso_A2": "IR",
    "un_A3": "IRN",
    "num": 364,
    "dialing": 98
  },
  {
    "name": "Iraq",
    "iso_A2": "IQ",
    "un_A3": "IRQ",
    "num": 368,
    "dialing": 964
  },
  {
    "name": "Ireland",
    "iso_A2": "IE",
    "un_A3": "IRL",
    "num": 372,
    "dialing": 353
  },
  {
    "name": "Isle of Man",
    "iso_A2": "IM",
    "un_A3": "IMN",
    "num": 833,
    "dialing": 44
  },
  {
    "name": "Israel",
    "iso_A2": "IL",
    "un_A3": "ISR",
    "num": 376,
    "dialing": 972
  },
  {
    "name": "Italy",
    "iso_A2": "IT",
    "un_A3": "ITA",
    "num": 380,
    "dialing": 39
  },
  {
    "name": "Jamaica",
    "iso_A2": "JM",
    "un_A3": "JAM",
    "num": 388,
    "dialing": 876
  },
  {
    "name": "Japan",
    "iso_A2": "JP",
    "un_A3": "JPN",
    "num": 392,
    "dialing": 81
  },
  {
    "name": "Jersey",
    "iso_A2": "JE",
    "un_A3": "JEY",
    "num": 832,
    "dialing": 44
  },
  {
    "name": "Jordan",
    "iso_A2": "JO",
    "un_A3": "JOR",
    "num": 400,
    "dialing": 962
  },
  {
    "name": "Kazakhstan",
    "iso_A2": "KZ",
    "un_A3": "KAZ",
    "num": 398,
    "dialing": 7
  },
  {
    "name": "Kenya",
    "iso_A2": "KE",
    "un_A3": "KEN",
    "num": 404,
    "dialing": 254
  },
  {
    "name": "Kiribati",
    "iso_A2": "KI",
    "un_A3": "KIR",
    "num": 296,
    "dialing": 686
  },
  {
    "name": "Korea, Democratic People's Republic of",
    "iso_A2": "KP",
    "un_A3": "PRK",
    "num": 408,
    "dialing": 850
  },
  {
    "name": "Korea, Republic of",
    "iso_A2": "KR",
    "un_A3": "KOR",
    "num": 410,
    "dialing": 82
  },
  {
    "name": "Kuwait",
    "iso_A2": "KW",
    "un_A3": "KWT",
    "num": 414,
    "dialing": 965
  },
  {
    "name": "Kyrgyzstan",
    "iso_A2": "KG",
    "un_A3": "KGZ",
    "num": 417,
    "dialing": 996
  },
  {
    "name": "Lao People's Democratic Republic",
    "iso_A2": "LA",
    "un_A3": "LAO",
    "num": 418,
    "dialing": 856
  },
  {
    "name": "Latvia",
    "iso_A2": "LV",
    "un_A3": "LVA",
    "num": 428,
    "dialing": 371
  },
  {
    "name": "Lebanon",
    "iso_A2": "LB",
    "un_A3": "LBN",
    "num": 422,
    "dialing": 961
  },
  {
    "name": "Lesotho",
    "iso_A2": "LS",
    "un_A3": "LSO",
    "num": 426,
    "dialing": 266
  },
  {
    "name": "Liberia",
    "iso_A2": "LR",
    "un_A3": "LBR",
    "num": 430,
    "dialing": 231
  },
  {
    "name": "Libya",
    "iso_A2": "LY",
    "un_A3": "LBY",
    "num": 434,
    "dialing": 218
  },
  {
    "name": "Liechtenstein",
    "iso_A2": "LI",
    "un_A3": "LIE",
    "num": 438,
    "dialing": 423
  },
  {
    "name": "Lithuania",
    "iso_A2": "LT",
    "un_A3": "LTU",
    "num": 440,
    "dialing": 370
  },
  {
    "name": "Luxembourg",
    "iso_A2": "LU",
    "un_A3": "LUX",
    "num": 442,
    "dialing": 352
  },
  {
    "name": "Macao",
    "iso_A2": "MO",
    "un_A3": "MAC",
    "num": 446,
    "dialing": 853
  },
  {
    "name": "Macedonia, the Former Yugoslav Republic of",
    "iso_A2": "MK",
    "un_A3": "MKD",
    "num": 807,
    "dialing": 389
  },
  {
    "name": "Madagascar",
    "iso_A2": "MG",
    "un_A3": "MDG",
    "num": 450,
    "dialing": 261
  },
  {
    "name": "Malawi",
    "iso_A2": "MW",
    "un_A3": "MWI",
    "num": 454,
    "dialing": 265
  },
  {
    "name": "Malaysia",
    "iso_A2": "MY",
    "un_A3": "MYS",
    "num": 458,
    "dialing": 60
  },
  {
    "name": "Maldives",
    "iso_A2": "MV",
    "un_A3": "MDV",
    "num": 462,
    "dialing": 960
  },
  {
    "name": "Mali",
    "iso_A2": "ML",
    "un_A3": "MLI",
    "num": 466,
    "dialing": 223
  },
  {
    "name": "Malta",
    "iso_A2": "MT",
    "un_A3": "MLT",
    "num": 470,
    "dialing": 356
  },
  {
    "name": "Marshall Islands",
    "iso_A2": "MH",
    "un_A3": "MHL",
    "num": 584,
    "dialing": 692
  },
  {
    "name": "Martinique",
    "iso_A2": "MQ",
    "un_A3": "MTQ",
    "num": 474,
    "dialing": 596
  },
  {
    "name": "Mauritania",
    "iso_A2": "MR",
    "un_A3": "MRT",
    "num": 478,
    "dialing": 222
  },
  {
    "name": "Mauritius",
    "iso_A2": "MU",
    "un_A3": "MUS",
    "num": 480,
    "dialing": 230
  },
  {
    "name": "Mayotte",
    "iso_A2": "YT",
    "un_A3": "MYT",
    "num": 175,
    "dialing": 262
  },
  {
    "name": "Mexico",
    "iso_A2": "MX",
    "un_A3": "MEX",
    "num": 484,
    "dialing": 52
  },
  {
    "name": "Micronesia, Federated States of",
    "iso_A2": "FM",
    "un_A3": "FSM",
    "num": 583,
    "dialing": 691
  },
  {
    "name": "Moldova, Republic of",
    "iso_A2": "MD",
    "un_A3": "MDA",
    "num": 498,
    "dialing": 373
  },
  {
    "name": "Monaco",
    "iso_A2": "MC",
    "un_A3": "MCO",
    "num": 492,
    "dialing": 377
  },
  {
    "name": "Mongolia",
    "iso_A2": "MN",
    "un_A3": "MNG",
    "num": 496,
    "dialing": 976
  },
  {
    "name": "Montenegro",
    "iso_A2": "ME",
    "un_A3": "MNE",
    "num": 499,
    "dialing": 382
  },
  {
    "name": "Montserrat",
    "iso_A2": "MS",
    "un_A3": "MSR",
    "num": 500,
    "dialing": 664
  },
  {
    "name": "Morocco",
    "iso_A2": "MA",
    "un_A3": "MAR",
    "num": 504,
    "dialing": 212
  },
  {
    "name": "Mozambique",
    "iso_A2": "MZ",
    "un_A3": "MOZ",
    "num": 508,
    "dialing": 258
  },
  {
    "name": "Myanmar",
    "iso_A2": "MM",
    "un_A3": "MMR",
    "num": 104,
    "dialing": 95
  },
  {
    "name": "Namibia",
    "iso_A2": "NA",
    "un_A3": "NAM",
    "num": 516,
    "dialing": 264
  },
  {
    "name": "Nauru",
    "iso_A2": "NR",
    "un_A3": "NRU",
    "num": 520,
    "dialing": 674
  },
  {
    "name": "Nepal",
    "iso_A2": "NP",
    "un_A3": "NPL",
    "num": 524,
    "dialing": 977
  },
  {
    "name": "Netherlands",
    "iso_A2": "NL",
    "un_A3": "NLD",
    "num": 528,
    "dialing": 31
  },
  {
    "name": "New Caledonia",
    "iso_A2": "NC",
    "un_A3": "NCL",
    "num": 540,
    "dialing": 687
  },
  {
    "name": "New Zealand",
    "iso_A2": "NZ",
    "un_A3": "NZL",
    "num": 554,
    "dialing": 64
  },
  {
    "name": "Nicaragua",
    "iso_A2": "NI",
    "un_A3": "NIC",
    "num": 558,
    "dialing": 505
  },
  {
    "name": "Niger",
    "iso_A2": "NE",
    "un_A3": "NER",
    "num": 562,
    "dialing": 227
  },
  {
    "name": "Nigeria",
    "iso_A2": "NG",
    "un_A3": "NGA",
    "num": 566,
    "dialing": 234
  },
  {
    "name": "Niue",
    "iso_A2": "NU",
    "un_A3": "NIU",
    "num": 570,
    "dialing": 683
  },
  {
    "name": "Norfolk Island",
    "iso_A2": "NF",
    "un_A3": "NFK",
    "num": 574,
    "dialing": 672
  },
  {
    "name": "Northern Mariana Islands",
    "iso_A2": "MP",
    "un_A3": "MNP",
    "num": 580,
    "dialing": 670
  },
  {
    "name": "Norway",
    "iso_A2": "NO",
    "un_A3": "NOR",
    "num": 578,
    "dialing": 47
  },
  {
    "name": "Oman",
    "iso_A2": "OM",
    "un_A3": "OMN",
    "num": 512,
    "dialing": 968
  },
  {
    "name": "Pakistan",
    "iso_A2": "PK",
    "un_A3": "PAK",
    "num": 586,
    "dialing": 92
  },
  {
    "name": "Palau",
    "iso_A2": "PW",
    "un_A3": "PLW",
    "num": 585,
    "dialing": 680
  },
  {
    "name": "Palestine, State of",
    "iso_A2": "PS",
    "un_A3": "PSE",
    "num": 275,
    "dialing": 970
  },
  {
    "name": "Panama",
    "iso_A2": "PA",
    "un_A3": "PAN",
    "num": 591,
    "dialing": 507
  },
  {
    "name": "Papua New Guinea",
    "iso_A2": "PG",
    "un_A3": "PNG",
    "num": 598,
    "dialing": 675
  },
  {
    "name": "Paraguay",
    "iso_A2": "PY",
    "un_A3": "PRY",
    "num": 600,
    "dialing": 595
  },
  {
    "name": "Peru",
    "iso_A2": "PE",
    "un_A3": "PER",
    "num": 604,
    "dialing": 51
  },
  {
    "name": "Philippines",
    "iso_A2": "PH",
    "un_A3": "PHL",
    "num": 608,
    "dialing": 63
  },
  {
    "name": "Pitcairn",
    "iso_A2": "PN",
    "un_A3": "PCN",
    "num": 612,
    "dialing": 870
  },
  {
    "name": "Poland",
    "iso_A2": "PL",
    "un_A3": "POL",
    "num": 616,
    "dialing": 48
  },
  {
    "name": "Portugal",
    "iso_A2": "PT",
    "un_A3": "PRT",
    "num": 620,
    "dialing": 351
  },
  {
    "name": "Puerto Rico",
    "iso_A2": "PR",
    "un_A3": "PRI",
    "num": 630,
    "dialing": 1
  },
  {
    "name": "Qatar",
    "iso_A2": "QA",
    "un_A3": "QAT",
    "num": 634,
    "dialing": 974
  },
  {
    "name": "Romania",
    "iso_A2": "RO",
    "un_A3": "ROU",
    "num": 642,
    "dialing": 40
  },
  {
    "name": "Russian Federation",
    "iso_A2": "RU",
    "un_A3": "RUS",
    "num": 643,
    "dialing": 7
  },
  {
    "name": "Rwanda",
    "iso_A2": "RW",
    "un_A3": "RWA",
    "num": 646,
    "dialing": 250
  },
  {
    "name": "Reunion",
    "iso_A2": "RE",
    "un_A3": "REU",
    "num": 638,
    "dialing": 262
  },
  {
    "name": "Saint Barthelemy",
    "iso_A2": "BL",
    "un_A3": "BLM",
    "num": 652,
    "dialing": 590
  },
  {
    "name": "Saint Helena",
    "iso_A2": "SH",
    "un_A3": "SHN",
    "num": 654,
    "dialing": 290
  },
  {
    "name": "Saint Kitts and Nevis",
    "iso_A2": "KN",
    "un_A3": "KNA",
    "num": 659,
    "dialing": 869
  },
  {
    "name": "Saint Lucia",
    "iso_A2": "LC",
    "un_A3": "LCA",
    "num": 662,
    "dialing": 758
  },
  {
    "name": "Saint Martin (French part)",
    "iso_A2": "MF",
    "un_A3": "MAF",
    "num": 663,
    "dialing": 590
  },
  {
    "name": "Saint Pierre and Miquelon",
    "iso_A2": "PM",
    "un_A3": "SPM",
    "num": 666,
    "dialing": 508
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "iso_A2": "VC",
    "un_A3": "VCT",
    "num": 670,
    "dialing": 784
  },
  {
    "name": "Samoa",
    "iso_A2": "WS",
    "un_A3": "WSM",
    "num": 882,
    "dialing": 685
  },
  {
    "name": "San Marino",
    "iso_A2": "SM",
    "un_A3": "SMR",
    "num": 674,
    "dialing": 378
  },
  {
    "name": "Sao Tome and Principe",
    "iso_A2": "ST",
    "un_A3": "STP",
    "num": 678,
    "dialing": 239
  },
  {
    "name": "Saudi Arabia",
    "iso_A2": "SA",
    "un_A3": "SAU",
    "num": 682,
    "dialing": 966
  },
  {
    "name": "Senegal",
    "iso_A2": "SN",
    "un_A3": "SEN",
    "num": 686,
    "dialing": 221
  },
  {
    "name": "Serbia",
    "iso_A2": "RS",
    "un_A3": "SRB",
    "num": 688,
    "dialing": 381
  },
  {
    "name": "Seychelles",
    "iso_A2": "SC",
    "un_A3": "SYC",
    "num": 690,
    "dialing": 248
  },
  {
    "name": "Sierra Leone",
    "iso_A2": "SL",
    "un_A3": "SLE",
    "num": 694,
    "dialing": 232
  },
  {
    "name": "Singapore",
    "iso_A2": "SG",
    "un_A3": "SGP",
    "num": 702,
    "dialing": 65
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "iso_A2": "SX",
    "un_A3": "SXM",
    "num": 534,
    "dialing": 721
  },
  {
    "name": "Slovakia",
    "iso_A2": "SK",
    "un_A3": "SVK",
    "num": 703,
    "dialing": 421
  },
  {
    "name": "Slovenia",
    "iso_A2": "SI",
    "un_A3": "SVN",
    "num": 705,
    "dialing": 386
  },
  {
    "name": "Solomon Islands",
    "iso_A2": "SB",
    "un_A3": "SLB",
    "num": 90,
    "dialing": 677
  },
  {
    "name": "Somalia",
    "iso_A2": "SO",
    "un_A3": "SOM",
    "num": 706,
    "dialing": 252
  },
  {
    "name": "South Africa",
    "iso_A2": "ZA",
    "un_A3": "ZAF",
    "num": 710,
    "dialing": 27
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "iso_A2": "GS",
    "un_A3": "SGS",
    "num": 239,
    "dialing": 500
  },
  {
    "name": "South Sudan",
    "iso_A2": "SS",
    "un_A3": "SSD",
    "num": 728,
    "dialing": 211
  },
  {
    "name": "Spain",
    "iso_A2": "ES",
    "un_A3": "ESP",
    "num": 724,
    "dialing": 34
  },
  {
    "name": "Sri Lanka",
    "iso_A2": "LK",
    "un_A3": "LKA",
    "num": 144,
    "dialing": 94
  },
  {
    "name": "Sudan",
    "iso_A2": "SD",
    "un_A3": "SDN",
    "num": 729,
    "dialing": 249
  },
  {
    "name": "Suriname",
    "iso_A2": "SR",
    "un_A3": "SUR",
    "num": 740,
    "dialing": 597
  },
  {
    "name": "Svalbard and Jan Mayen",
    "iso_A2": "SJ",
    "un_A3": "SJM",
    "num": 744,
    "dialing": 47
  },
  {
    "name": "Swaziland",
    "iso_A2": "SZ",
    "un_A3": "SWZ",
    "num": 748,
    "dialing": 268
  },
  {
    "name": "Sweden",
    "iso_A2": "SE",
    "un_A3": "SWE",
    "num": 752,
    "dialing": 46
  },
  {
    "name": "Switzerland",
    "iso_A2": "CH",
    "un_A3": "CHE",
    "num": 756,
    "dialing": 41
  },
  {
    "name": "Syrian Arab Republic",
    "iso_A2": "SY",
    "un_A3": "SYR",
    "num": 760,
    "dialing": 963
  },
  {
    "name": "Taiwan, Province of China",
    "iso_A2": "TW",
    "un_A3": "TWN",
    "num": 158,
    "dialing": 886
  },
  {
    "name": "Tajikistan",
    "iso_A2": "TJ",
    "un_A3": "TJK",
    "num": 762,
    "dialing": 992
  },
  {
    "name": "United Republic of Tanzania",
    "iso_A2": "TZ",
    "un_A3": "TZA",
    "num": 834,
    "dialing": 255
  },
  {
    "name": "Thailand",
    "iso_A2": "TH",
    "un_A3": "THA",
    "num": 764,
    "dialing": 66
  },
  {
    "name": "Timor-Leste",
    "iso_A2": "TL",
    "un_A3": "TLS",
    "num": 626,
    "dialing": 670
  },
  {
    "name": "Togo",
    "iso_A2": "TG",
    "un_A3": "TGO",
    "num": 768,
    "dialing": 228
  },
  {
    "name": "Tokelau",
    "iso_A2": "TK",
    "un_A3": "TKL",
    "num": 772,
    "dialing": 690
  },
  {
    "name": "Tonga",
    "iso_A2": "TO",
    "un_A3": "TON",
    "num": 776,
    "dialing": 676
  },
  {
    "name": "Trinidad and Tobago",
    "iso_A2": "TT",
    "un_A3": "TTO",
    "num": 780,
    "dialing": 868
  },
  {
    "name": "Tunisia",
    "iso_A2": "TN",
    "un_A3": "TUN",
    "num": 788,
    "dialing": 216
  },
  {
    "name": "Turkey",
    "iso_A2": "TR",
    "un_A3": "TUR",
    "num": 792,
    "dialing": 90
  },
  {
    "name": "Turkmenistan",
    "iso_A2": "TM",
    "un_A3": "TKM",
    "num": 795,
    "dialing": 993
  },
  {
    "name": "Turks and Caicos Islands",
    "iso_A2": "TC",
    "un_A3": "TCA",
    "num": 796,
    "dialing": 649
  },
  {
    "name": "Tuvalu",
    "iso_A2": "TV",
    "un_A3": "TUV",
    "num": 798,
    "dialing": 688
  },
  {
    "name": "Uganda",
    "iso_A2": "UG",
    "un_A3": "UGA",
    "num": 800,
    "dialing": 256
  },
  {
    "name": "Ukraine",
    "iso_A2": "UA",
    "un_A3": "UKR",
    "num": 804,
    "dialing": 380
  },
  {
    "name": "United Arab Emirates",
    "iso_A2": "AE",
    "un_A3": "ARE",
    "num": 784,
    "dialing": 971
  },
  {
    "name": "United Kingdom",
    "iso_A2": "GB",
    "un_A3": "GBR",
    "num": 826,
    "dialing": 44
  },
  {
    "name": "United States",
    "iso_A2": "US",
    "un_A3": "USA",
    "num": 840,
    "dialing": 1
  },
  {
    "name": "United States Minor Outlying Islands",
    "iso_A2": "UM",
    "un_A3": "UMI",
    "num": 581,
    "dialing": 1
  },
  {
    "name": "Uruguay",
    "iso_A2": "UY",
    "un_A3": "URY",
    "num": 858,
    "dialing": 598
  },
  {
    "name": "Uzbekistan",
    "iso_A2": "UZ",
    "un_A3": "UZB",
    "num": 860,
    "dialing": 998
  },
  {
    "name": "Vanuatu",
    "iso_A2": "VU",
    "un_A3": "VUT",
    "num": 548,
    "dialing": 678
  },
  {
    "name": "Venezuela",
    "iso_A2": "VE",
    "un_A3": "VEN",
    "num": 862,
    "dialing": 58
  },
  {
    "name": "Viet Nam",
    "iso_A2": "VN",
    "un_A3": "VNM",
    "num": 704,
    "dialing": 84
  },
  {
    "name": "British Virgin Islands",
    "iso_A2": "VG",
    "un_A3": "VGB",
    "num": 92,
    "dialing": 284
  },
  {
    "name": "US Virgin Islands",
    "iso_A2": "VI",
    "un_A3": "VIR",
    "num": 850,
    "dialing": 340
  },
  {
    "name": "Wallis and Futuna",
    "iso_A2": "WF",
    "un_A3": "WLF",
    "num": 876,
    "dialing": 681
  },
  {
    "name": "Western Sahara",
    "iso_A2": "EH",
    "un_A3": "ESH",
    "num": 732,
    "dialing": 212
  },
  {
    "name": "Yemen",
    "iso_A2": "YE",
    "un_A3": "YEM",
    "num": 887,
    "dialing": 967
  },
  {
    "name": "Zambia",
    "iso_A2": "ZM",
    "un_A3": "ZMB",
    "num": 894,
    "dialing": 260
  },
  {
    "name": "Zimbabwe",
    "iso_A2": "ZW",
    "un_A3": "ZWE",
    "num": 716,
    "dialing": 263
  }
];
