<mat-card>
	<img mat-card-image class="mat-card-image" [src]="organizationService.dynamicUri(technology.cardImage)" />
	<mat-card-header>
		<mat-card-title><h3>{{technology.name}}</h3></mat-card-title>
		<mat-card-subtitle>Solutions for</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content class="icon-container">
		<img *ngFor="let option of technology.applicationTypes" class="icon" [src]="organizationService.dynamicUri(option.image.url)" />
	</mat-card-content>
</mat-card>
