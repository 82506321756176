import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "../../../app-routing.module";
import { MaterialsModule } from '../../../materials.module';

import { ApplicationTypeService } from "../../../services/ApplicationTypeService";
import { ImageService } from "../../../services/ImageService";
import { TechnologyCategoryService } from "../../../services/TechnologyCategoryService";
import { TechnologyService } from "../../../services/TechnologyService";
import { ShowroomMatCardComponent } from "./showroom-mat-card/showroom-mat-card.component";
import { ShowroomComponent } from "./showroom.component";

@NgModule({
	declarations: [
		ShowroomComponent,
		ShowroomMatCardComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		MaterialsModule,
	],
	exports: [
	],
	providers: [
		ApplicationTypeService,
		ImageService,
		TechnologyService,
		TechnologyCategoryService,
	],
} as NgModule)
export class ShowroomModule implements NgModule { }
