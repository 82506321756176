import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Organization, OrganizationState } from "../../entity/Organization";
import { GLOBAL_COUNTRIES } from "../../entity/GlobalCountries";

import { environment } from '../../environments/environment';
import { AbstractCRUDService } from "./AbstractCRUDService";
import { OrganizationFilter } from '../../entity/OrganizationFilterOptions';

import { Contact, Technology, Service,Location } from 'src/entity';
import { ContactAndPassword } from '../../entity/ContactAndPassword';
import { SignupForm } from '../../entity/SignupFrom';

@Injectable()
export class OrganizationService extends AbstractCRUDService<Organization, number> {

  private actionUrl: string;

  constructor(private http: HttpClient) {
    super(http);
    this.actionUrl = environment.api_server + 'organizations';
  }

	public async getAll(): Promise<Organization[]> {
		return await this.http.get<Organization[]>(this.actionUrl + '?projection=organizationInline')
      .pipe(map((result: any) => {
        return result;
      })).toPromise();
  }
  
  public async getAllAdmin(): Promise<Organization[]> {
		return await this.http.get<Organization[]>(this.actionUrl + '/admin')
      .pipe(map((result: any) => {
        return result;
      })).toPromise();
	}


	public async getItem(id: number): Promise<Organization> {
    return await this.http.get<Organization>(`${this.actionUrl}/${id}?projection=organizationInline`).toPromise();
	}

	public updateItem(organization: Organization): Observable<Organization> {
		return this.http.put<any>(this.actionUrl + '/' + organization.id, organization);
  }


  
	public signup(data: SignupForm): Observable<void> {
		return this.http.post<any>(this.actionUrl + '/signup', data);
  }

  
	public updateStatus(organizationId: number, status: OrganizationState): Observable<Organization> {
		return this.http.put<any>(this.actionUrl + '/' + organizationId+"/setStatus/"+status,null);
  }
  

  public deleteOrganization(id:number):Observable<void>{
    return this.http.delete<any>(this.actionUrl+"/"+id);
  }
  
	public createEmpty(): Observable<Organization> {
		return this.http.post<any>(this.actionUrl+"/createEmpty", null);
	}
  
	public async getContactsById(id: number): Promise<Contact[]> {
    return await this.http.get<Organization>(`${environment.api_server + 'contacts/search/findByOrganizationId?id='}${id}`)
      .pipe(map((result: any) => {
        return result;
      })).toPromise();
  }
  
  public async getLocationsById(id: number): Promise<Location[]> {
    return await this.http.get<Organization>(`${environment.api_server + 'locations/search/findByOrganizationId?id='}${id}`)
      .pipe(map((result: any) => {
        return result;
      })).toPromise();
	}
  
  public async filterAsync(options:OrganizationFilter): Promise<Organization[]> {
		return await 
			this.http.post<Organization[]>(this.actionUrl + '/filter',options)
        .pipe(map((result:any) => { return result }))
          .toPromise();
	}

	public async getCountries(): Promise<any[]> {
		return await this.http.get<String[]>(this.actionUrl + '/search/countries')
      .pipe(map((result: any) => {
          return GLOBAL_COUNTRIES
            .filter( (it: any) => result.indexOf(it.iso_A2) >= 0)
            .map((it: any) => {
              return { name: it.name, iso_A2: it.iso_A2 };
            });
      })).toPromise();
	}

	public async getCities(): Promise<any[]> {
		return await this.http.get<String[]>(this.actionUrl + '/search/cities').toPromise();
	}

	public async uploadImage(file) {
    const formData: FormData = new FormData();
		formData.append('file', file, file.name);
		return await this.http.post(this.actionUrl + '/upload/image', formData).toPromise();
	}

  public getAllServices(): Observable<Service[]> {
    return this.http.get<Service[]>( environment.api_server + 'services');
  }
  
  public isEmailUnique(contactId: number, password: string): Observable<boolean> {
    return this.http.get<boolean>( environment.api_server + `contacts/isEmailUnique?contactId=${contactId}&email=${password}`);
  }

  public addContact(contact: ContactAndPassword): Observable<any> {
    return this.http.post<any>( environment.api_server + 'contacts', contact);
  }

  public updateContact(contact: ContactAndPassword): Observable<any> {
    return this.http.put<any>( environment.api_server + 'contacts/' + contact.id, contact);
  }
  
  public deleteContact(contact_id): Observable<any> {
    return this.http.delete( environment.api_server + 'contacts/' + contact_id);
  }


  public addLocation(location: Location): Observable<any> {
    return this.http.post<any>( environment.api_server + 'locations', location);
  }

  public updateLocation(location: Location): Observable<any> {
    return this.http.put<any>( environment.api_server + 'locations/' + location.id, location);
  }
  
  public deleteLocation(location_id): Observable<any> {
    return this.http.delete( environment.api_server + 'locations/' + location_id);
  }


	public async getTechnologies(id: number): Promise<Technology[]> {
    return await this.http.get<Organization>(`${this.actionUrl}/${id}?projection=organizationInline`)
      .pipe(map((result: any) => {
        return result.technologies;
      })).toPromise();
  }
  

  public async getServices(id: number): Promise<Service[]> {

    

    return await this.http.get<Organization>(`${this.actionUrl}/${id}?projection=organizationInline`)
      .pipe(map((result: any) => {
        return result.services;
      })).toPromise();
	}
  
  public addTechnology(id: number, tech_id: number): Observable<any> {
    return this.http.post<any>(this.actionUrl + '/' + id + '/technologies/'+tech_id,null);
  }

 
  public deleteTechnology(id: number, tech_id: number): Observable<any> {
    return this.http.delete(this.actionUrl + '/' + id + '/technologies/' + tech_id);
  }

  public dynamicContent(content): Observable<any> {
		let match;
		let targetUrl = environment.api_server;
	
		match = content.match('uri\\-ref:\\/\\/remote\\/markdown\\/(\\w+)\\.md');
    if (match)
      targetUrl += 'remote/markdown/' + match[1];
		else {
      match = content.match('(\\/assets\\/[a-zA-Z0-9]+\\/+\\w+\\.md)');
      if (match)
        targetUrl = match[1];
    }
    
		return (match) ? this.http.get(targetUrl, { responseType: 'text' }) : of(content);
  }
  
 

	public dynamicUri(source): string {
    if(!source)return source;
    let match = source.match('uri\\-ref:\\/\\/remote\\/images\\/([\\w\\/\\.\\-]+)');
    if (match)
      return this.actionUrl + '/remote/resource/' + match[1];
    else {
      match = source.match('uri\\-ref:\\/\\/([0-9a-f]{8}\\-[0-9a-f]{4}\\-[0-9a-f]{4}\\-[0-9a-f]{4}\\-[0-9a-f]{12})');
      return (match) ? this.actionUrl + '/remote/image/' + match[1] : source;
    }
	}

}
