export var privacy_policy = `<b>Who is the controller of your personal data?</b><br/>
Data Controller: FUNDACIÓ EURECAT (“EURECAT”)<br/>
Tax Identification Code (CIF):  G66210345<br/>
Address: Parc Tecnològic del Vallès. Avinguda Universitat Autònoma, 23 08290 Cerdanyola del Vallès, Spain<br/>
Email address: legal@eurecat.org<br/>
Telephone number: +34 93 238 14 00<br/>
The Data Protection Officer’s email address: dpo@eurecat.org
<br/><br/>
<b>Why do we process your personal data?</b><br/>
The data provided in the application, especially in the specific clients’ form will solely and exclusively be used to manage the users’ registration and to keep them up to date on the OLED products and services provided by the SMARTEES consortium, if they grant their express authorisation for the latter purpose.
The registration involves the viewing by the other members of the platform users personal contact details.
There could be forms on our website that collect users’ data for these specific purposes. In these cases, this will be subject to the specific terms and conditions in each case.
<br/><br/>
<b>Is it compulsory to provide all the information requested in the forms on the website?</b><br/>
Regarding the form on the website, Users must complete the spaces marked "compulsory" (denoted by ‘*’). If they do not complete the required personal data or only partially provide them, this could mean that Fundación Eurecat (on behalf of SMARTEES project) is unable to deal with their requests and therefore Fundación Eurecat (on behalf of SMARTEES project) will be exonerated from all liability for not rendering or not fully rendering the requested services.
The personal data that Users provide to Fundación Eurecat (on behalf of SMARTEES project) must be current data so that the information in our records is up-to-date and error-free. Users will be held liable for the data provided being true.
<br/><br/>
<b>How long will we keep your personal data?</b><br/>
The personal data will be kept while the User continues take part of the SMARTEES platform. Once the User, sign out the platform permanently, all related data will be correctly deleted according to the GDPR normative.
<br/><br/>
<b>What is the legal basis for processing users’ personal data?</b><br/>
The legal basis for processing personal data is the consent granted by accepting the personal data processing clause. 
<br/><br/>
<b>To whom will users’ data be disclosed?</b><br/>
Data will not be disclosed to third companies, unless there is a legal obligation to do so. If there is any intention to disclose them, the authorisation of the data subjects will be obtained beforehand.
<br/><br/>
<b>What are users’ rights regarding their personal data?</b><br/>
Users may exercise their right of access to their personal data and to request rectification of any incorrect data or, if need be, request deletion when the data are no longer necessary for the purposes for which they were obtained. They may also request restriction, portability and objection to their data being processed under certain circumstances and for reasons related to their particular situation. 
They are also entitled to withdraw their consent at any time without this retroactively affecting the personal data processing carried out up to such time. 
Users may exercise their aforementioned rights, according to the terms and conditions in the law in force, at the registered office of EURECAT or by submitting a request by email to legal@eurecat.org. 
If they do not obtain a satisfactory reply and wish to file a claim or obtain further information about any of these rights, they may contact the Spanish Data Protection Agency (www.agpd.es - C/ Jorge Juan, 6 in Madrid, Spain).
<br/><br/>
<b>Which security measures has the company implemented?</b>Which security measures has the company implemented?<br/>
Fundación Eurecat hereby informs users that it has implemented the required technical and organisational security measures to ensure the security of users’ personal data and prevent their alteration, loss, unauthorised processing and/or access according to the state of the art, the nature of the data stored and the risks to which they are exposed, whether due to a human action or physical or natural media, according to the provisions in the regulations in force.
<br/><br/>
<b>Social media</b><br/>
Fundación Eurecat has profiles on different social media websites in order to advertise and publish information about the services it renders through its website, to interact with the users and to use these networks as a channel for social encounters and interaction.
We provide links below to the privacy policies of the social media websites where Fundación Eurecat has an active profile: <br/><br/>
<ul>
    <li><b>Facebook:</b> <a href="https://ca-es.facebook.com/privacy/explanation ">https://ca-es.facebook.com/privacy/explanation </a></li>
    <li><b>Twitter:</b><a href="https://twitter.com/es/privacy">https://twitter.com/es/privacy</a></li>
    <li><b>Youtube:</b><a href="https://www.youtube.com/intl/es/yt/about/policies/#community-guidelines">https://www.youtube.com/intl/es/yt/about/policies/#community-guidelines</a></li>
    <li><b>LinkedIn:</b><a href="https://www.linkedin.com/legal/privacy-policy?_l=es_ES">https://www.linkedin.com/legal/privacy-policy?_l=es_ES</a></li>
</ul>
<br/>`