import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-bootstrap";
import { MarkdownModule } from 'ngx-markdown';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationTypesModule } from "./components/application-types/application-types.module";
import { HomeModule } from "./components/home/home.module";
import { TechnologyModule } from "./components/technology/technology.module";
import { SharedModule } from './shared/shared.module';
import { MaterialsModule } from './materials.module';

import { ContactCardComponent } from "./components/contact-card/contact-card.component";
import { FooterHomeComponent } from "./components/footer-home/footer-home.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";

import { OrganizationComponent } from "./components/organization/organization.component";
import { TechnologiesComponent } from "./components/technologies/technologies.component";
import { TechnologyCardComponent } from "./components/technologies/technology-card.component/technology-card.component";
import { ServiceCardComponent } from "./components/services/service-card.component/service-card.component";

import { OrganizationEditorComponent, ContactPopupDialog, ProductPopupDialog, TechnologyEditCardComponent, ServicePopupDialog, ServiceEditCardComponent, LocationPopupDialog } from "./components/organization-editor/organization-editor.component";

import { 
	AuthenticationService, AuthGuardService,
	OrganizationService, ImageService, ApplicationTypeService, TechnologyService, TechnologyCategoryService
} from "./services";
import { ServicesComponent } from "./components/home/brokerage/services/services";
import { HiringComponent } from "./components/home/brokerage/hiring/hiring";
import { TechComponent } from "./components/home/brokerage/tech/tech";

import { JwtInterceptorProvider } from './services/_helpers';
import { AdminOrganizationsListComponent } from "./components/admin-organizations-list/admin-organizations-list.component";
import { ServiceService } from "./services/ServiceService";
import { ServiceComponent } from "./components/service/service.component";
import { RegisterComponent } from "./components/home/register/register";
import { RegisterConfirmDialog } from "./components/home/register/register-config-dialog/register-config-dialog";
import { BrochureComponent } from "./components/brochure/brochure.component";
import { ModalLegalComponent } from "./components/modal-legal/modal-legal.component";
import { MatInputModule } from "@angular/material";

@NgModule({
	bootstrap: [
		AppComponent,
	],
	declarations: [
		AppComponent,
		BrochureComponent,
		FooterHomeComponent,
		NavBarComponent,
		ContactCardComponent,
		RegisterConfirmDialog,
		AdminOrganizationsListComponent,
		ServicesComponent,
		HiringComponent,
		TechComponent,
		RegisterComponent,
		OrganizationComponent,
		TechnologiesComponent,
		TechnologyCardComponent,
		ServiceCardComponent,
		OrganizationEditorComponent,
		ContactPopupDialog,
		LocationPopupDialog,
		ServiceComponent,
		ProductPopupDialog,
		ServicePopupDialog,
		ServiceEditCardComponent,
		TechnologyEditCardComponent,
		ModalLegalComponent
	],
	imports: [
		ApplicationTypesModule,
		FormsModule, ReactiveFormsModule, 
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		HomeModule,
		SharedModule,
		MaterialsModule,
		MarkdownModule.forRoot(),
		CarouselModule.forRoot(),
		TechnologyModule,
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		TechnologyModule,
		MatInputModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		HttpClientModule, 
		JwtInterceptorProvider,
		AuthenticationService,
		AuthGuardService,
		ApplicationTypeService,
		ImageService,
		OrganizationService,
		TechnologyService,
		ServiceService,
		TechnologyCategoryService,
	],
	entryComponents: [ ContactPopupDialog,LocationPopupDialog,RegisterConfirmDialog, ProductPopupDialog,ServicePopupDialog ],
} as NgModule)
export class AppModule implements NgModule { }
