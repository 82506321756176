<div style="margin-top: 0px;">

	<h1>Sign-up</h1>
	<p>
		Sign-up to SmartEES Community
	</p>
	<p>
			The SmartEES Community and Brokerage of the Marketplace provides access to products and on-demand services related to expertise, financial support, business models and access to flexible electronics technology.

			Our community is supported by more than 150 organizations worldwide. Do not miss up and join us. 
	</p>

	<mat-card>
		<mat-card-header>
		</mat-card-header>
		<mat-card-content>
			<form class="detail-form" [formGroup]="profileForm">

				<span class="left-side">

					<mat-form-field class="detail-field ninety-percent">
						<input matInput placeholder="Organization name" formControlName="name" required>
					</mat-form-field>


					<mat-form-field class="detail-field ninety-five-percent">
						<textarea matInput placeholder="Description" formControlName="description" style="min-height: 5em"></textarea>
					</mat-form-field>

					<mat-form-field class="detail-field ninety-percent">
						<mat-label>Typologies:</mat-label>
						<mat-select formControlName="typologies" multiple required>
							<mat-option *ngFor="let option of typologiesList; let i = index" [value]="option">
								{{option}}
							</mat-option>
						</mat-select>
					</mat-form-field>



				</span>

				<span class="right-side">




					<mat-form-field>
						<input matInput formControlName="contactName" placeholder="Contact name" required>
					</mat-form-field>

					<mat-form-field>
						<input matInput formControlName="position" placeholder="Position" required>
					</mat-form-field>

					<mat-form-field>
						<input matInput formControlName="email" placeholder="Email" [errorStateMatcher]="matcher" required>
						<mat-error *ngIf="profileForm.get('email').hasError('email')">
							Please enter a valid email address
						</mat-error>

						<mat-error *ngIf="profileForm.get('email').errors && profileForm.get('email').errors.emailTaken">
							😢 Oh noes, this email is already taken!
						</mat-error>
					</mat-form-field>

					<mat-form-field>
						<input matInput type="tel" formControlName="phone" placeholder="Telephone">
					</mat-form-field>

					<mat-form-field class="detail-field ninety-percent">
						<input matInput placeholder="Password" formControlName="password" type="password">
					</mat-form-field>
					

					<mat-form-field class="detail-field ninety-percent">
						<input matInput placeholder="Password (Repeat)" formControlName="confirm_password" type="password" [errorStateMatcher]="matcher">
					</mat-form-field>
					<mat-error *ngIf="profileForm.hasError('notSamePass')">
						Please enter a same password than on previous field
					</mat-error>

					
				</span>

			</form>
	
			<button mat-list-item class="button"  [disabled]="!acceptTerms || !profileForm.valid" (click)="signup()"> Sign-up! </button>
			&nbsp;
			<mat-checkbox #checkbox [(ngModel)]="acceptTerms" [disabled]="!acceptTerms" (click)="modal.show(acceptTerms)" style="color: black !important">
					I accept the Terms and Conditions and GDPR Privacy Policy
			</mat-checkbox>
		</mat-card-content>

	
	</mat-card>

</div>

<app-modal-legal #modal style="padding: 10px; overflow-y: initial !important; height: 80% ; max-height: 80vh">
    <div class="app-modal-header">
		<h3>PRIVACY POLICY</h3>
		</div>
    <div class="app-modal-body" style="height: 50vh; overflow-y: auto; text-align: justify; padding-right: 10px">
			<div [innerHTML]="privacy_policy"></div>

			<h3 style="font-weight: bold">USAGE CONDITIONS AND PRIVACY POLICY FOR THE PLATFORM SMARTEES</h3>
			<b>1. General information</b><br/>
			<div [innerHTML]="general_information"></div>

			<b>2. Purpose</b><br/>
			<div [innerHTML]="purpose"></div>

			<b>3. How the platform operates</b><br/>
			<div [innerHTML]="operations"></div>

			<b>4. Registration</b><br/>
			<div [innerHTML]="registration"></div><br/>

			<b>5. Data protection</b><br/>
			<div [innerHTML]="data_protection"></div>

			<b>6.	Security measures</b><br/>
			<div [innerHTML]="security_measures"></div>

			<b>7.	Responsibilities of the member</b><br/>
			<div [innerHTML]="responsabilities"></div>

			<b>8.	How to cancel membership on the platform</b><br/>
			<div [innerHTML]="membership_remove"></div>

			<b>9.	Complaints and claims</b> <br/>
			<div [innerHTML]="complaints_claims"></div>

			<b>10.	Applicable legislation and jurisdiction</b> <br/>
			<div [innerHTML]="legislation_jurisdiction"></div>

		</div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-default" (click)="modal.hide()" (click)="updateCheckbox(false)" style="margin-right: 1em;">Decline</button>
      <button type="button" class="btn btn-primary" (click)="modal.hide()" (click)="updateCheckbox(true)" >Accept</button>
    </div>
  </app-modal-legal>