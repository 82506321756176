<mat-dialog-content>
  <h1>Service</h1>
    <form class="detail-form" [formGroup]="serviceForm">
      <div>
          <span class="left-side">

              <mat-form-field class="detail-field ninety-percent">
                <input matInput formControlName="name" placeholder="Name" required>
              </mat-form-field>
              
              <mat-form-field class="detail-field ninety-percent">
                <mat-label>Service type:</mat-label>
                <mat-select formControlName="type">
                  <mat-option *ngFor="let option of serviceTypes" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="detail-field ninety-percent">
                <textarea matInput placeholder="Description" formControlName="description" maxlength="1000" matTextareaAutosize matAutosizeMinRows=8 matAutosizeMaxRows=8></textarea>
              </mat-form-field>

          </span>
  				<span class="right-side">
							<mat-form-field class="detail-field ninety-percent"> 
                  <label class="custom-label">Card image</label>
                  <input matInput placeholder="" formControlName="cardimage" class="uploadFile" readonly (click)="imageFile.click()" required>
                  <input #imageFile id="imageFile" type="file" class="uploadFile" (change)="addCardImage()" />
                  <button class="button" (click)="imageFile.click()">Add image</button>                  
                  <img  class="img-preview" [src]="organizationService.dynamicUri(serviceForm.get('cardimage').value)" />
                  
                  
                </mat-form-field>
               

          

              <mat-form-field class="detail-field ninety-percent">
                <textarea matInput placeholder="Specification" formControlName="specification" matTextareaAutosize matAutosizeMinRows=8 matAutosizeMaxRows=8></textarea>
                <div class="markdown-supported"><a target="_blank" tabindex="-1" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a> is supported</div>
              </mat-form-field>


              <mat-form-field class="detail-field ninety-percent">
                  <textarea matInput placeholder="Pricing description" formControlName="cost" matTextareaAutosize matAutosizeMinRows=8 matAutosizeMaxRows=8></textarea>
                  <div class="markdown-supported"><a target="_blank" tabindex="-1" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a> is supported</div>
                </mat-form-field>
              
          </span>
          
                    
       
   
      </div>
    </form>

</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{id: service.id, form: serviceForm}" [disabled]="!serviceForm.valid" cdkFocusInitial>Ok</button>
  <button mat-button (click)="cancel()">Cancel</button>
</div>