import { Component, Input } from "@angular/core";
import { OrganizationService } from "../../../services/OrganizationService";

@Component({
	selector: "technology-carousel-view",
	templateUrl: "./technology-carousel.component.html",
	styleUrls: ["./technology-carousel.component.scss"],
})
export class TechnologyCarouselComponent {

	@Input()
	public images: string[];

	private constructor(public organizationService: OrganizationService) { }

}
