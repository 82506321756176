<div>
	<h1>SmartEEs Brokerage</h1>

	<p>
		SmartEEs will help you facilitate and grow your business through a panel of high level brokerage activities:
	</p>

	<ul>
		<li>Get in contact with specialized and dedicated experts and services</li>
		<li>Offer your own services</li>
		<li>Take part to dedicated events and workshops with preferred access</li>
		<li>Post and answer business announces</li>
	</ul>
	<div style="display: flex; flex-basis: 0%;">
		<div class="options-holder ie-centered" >
			<a class="options" routerLink="/brokerage/hiring" routerLinkActive="active">Product providers</a>
			<a class="options" routerLink="/brokerage/tech" routerLinkActive="active">Technology providers</a>
			<a class="options" routerLink="/brokerage/services" routerLinkActive="active">Other service providers</a>
		</div>
	</div>
</div>