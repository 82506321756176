
<app-organization-map [organizations]="organizations"></app-organization-map>

<mat-sidenav-container>
	<mat-sidenav opened="true" mode="side">
		<mat-card *ngIf="showOrgNameFilter">
			<mat-card-title>Organization name</mat-card-title>
			<mat-card-content>
				<mat-form-field>
					<input matInput type="text" [(ngModel)]="filter.organizationName" (keyup)="filterOrganizations()">
				</mat-form-field>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-title>Country</mat-card-title>
			<mat-card-content>
				<mat-select multiple [placeholder]="filter.country.length==0 || filter.country.length==countries.length?'All':null" [(ngModel)]="filter.country" (selectionChange)="filter.city = []; filterOrganizations();">
						<mat-option disabled="disabled" class="all-option">

					<button mat-raised-button class="mat-primary fill text-sm" (click)="filter.city = []; selectAll('country',countries,'iso_A2')">
						Select All 
					</button>
					<button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll('country')">
						Deselect All
					</button>
					</mat-option>


					<mat-option *ngFor="let option of countries" [value]="option.iso_A2">{{option.name}}</mat-option>
				</mat-select>
			</mat-card-content>
		</mat-card>


		<mat-card>

			<mat-card-title>City</mat-card-title>

			<mat-card-content>

				<mat-select multiple [placeholder]="filter.city.length==0 || filter.city.length==cities.length?'All':null" [(ngModel)]="filter.city" (selectionChange)="filter.country = []; filterOrganizations();">
						<mat-option disabled="disabled" class="all-option">

								<button mat-raised-button class="mat-primary fill text-sm" (click)="filter.country = [];  selectAll('city',cities,'id')">
									Select All 
								</button>
								<button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll('city')">
									Deselect All
								</button>
								</mat-option>
					<mat-option *ngFor="let option of cities" [value]="option.id">{{option.name}}</mat-option>
				</mat-select>

			</mat-card-content>
		</mat-card>

		<mat-card>

			<mat-card-title>Organization type</mat-card-title>

			<mat-card-content>
				<mat-select multiple [placeholder]="filter.companyType.length==0||filter.companyType.length==organizationTypes.length ?'All':null" [(ngModel)]="filter.companyType" (selectionChange)="filterOrganizations();">
						<mat-option disabled="disabled" class="all-option">

								<button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll('companyType',organizationTypes,'id')">
									Select All 
								</button>
								<button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll('companyType')">
									Deselect All
								</button>
								</mat-option>
					<mat-option *ngFor="let option of organizationTypes" [value]="option.id">{{option.name}}</mat-option>
				</mat-select>

			</mat-card-content>
		</mat-card>





		<mat-card *ngIf="showTechTypeFilter">

			<mat-card-title>Technology type:</mat-card-title>

			<mat-card-content>

				<mat-select multiple [placeholder]="filter.technologyType.length==0?'Any':null" [(ngModel)]="filter.technologyType" (selectionChange)="filterOrganizations();">
				<mat-option disabled="disabled" class="all-option">

                    <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll('technologyType',applicationTypes,'id')">
							Select All 
						  </button>
						  <button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll('technologyType')">
							Deselect All
						  </button>
						</mat-option>

					<mat-option *ngFor="let option of applicationTypes" [value]="option.id">{{option.name}}</mat-option>
				</mat-select>

			</mat-card-content>
		</mat-card>

		<mat-card *ngIf="showServiceTypeFilter">

			<mat-card-title>Service type:</mat-card-title>

			<mat-card-content>

				<mat-select multiple [placeholder]="filter.serviceType.length==0?'Any':null" [(ngModel)]="filter.serviceType" (selectionChange)="filterOrganizations();">
				
						<mat-option disabled="disabled" class="all-option">

								<button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll('serviceType',serviceTypes,'id')">
										Select All 
									  </button>
									  <button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll('serviceType')">
										Deselect All
									  </button>
									</mat-option>
			

					<mat-option *ngFor="let option of serviceTypes" [value]="option.id">{{option.name}}</mat-option>
				</mat-select>

			</mat-card-content>
		</mat-card>



		<mat-card>
			<mat-card-title>
				Search
			</mat-card-title>
			<mat-card-content>
				<mat-form-field>
					<input  matInput type="text" [(ngModel)]="filter.text" (keyup)="clearFilterButFreeText();filterOrganizations()">
				</mat-form-field>
			</mat-card-content>

		</mat-card>
		<mat-card>
			<button mat-raised-button color="accent" style="width: 100%" (click)="clearFilter();filterOrganizations();">Clear</button>
		</mat-card>
	</mat-sidenav>

	<mat-sidenav-content>
		<!--		<h1 *ngIf="showTitle()">Organizations</h1>
			<hr />-->


		<div class="card-container">
			<organization-card-view style="padding: 10px; padding-bottom: 20px;" *ngFor="let organization of organizations" [organization]="organization">
			</organization-card-view>
		</div>
	
	</mat-sidenav-content>

</mat-sidenav-container>