<mat-card *ngIf="technology">
	<mat-card-title><h1>{{technology.name}}</h1></mat-card-title>
	<a target="_blank" href="https://smartees.eu/smartees-call-for-projects/" class="leftmenu">
		<p class="myP">Submit your Application Experiment</p >
	</a>

	<div class="categories-div">
		<img *ngFor="let option of technology.applicationTypes" class="tecnology-categories-img" src="{{option.image.url}}"/>
	</div>
	<mat-card-content>
		<div>
			<p class="technology-description">{{technology.description}}</p>
		</div>
		<hr />
		<div class="content-div">
			<div class="content-div-section">
				<technology-carousel-view [images]="technology.images"></technology-carousel-view>
			</div>
			<div class="content-div-section">
					<h1 class="specifications-title">Specifications</h1>
					<hr/>
					<markdown class="markdown-body">{{technology.specification}}</markdown>				
			</div>
		</div>
	</mat-card-content>
</mat-card>
