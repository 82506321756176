<mat-toolbar color="primary" *ngIf="viewModel">

	<!-- Compressed Navbar -->
	<mat-toolbar-row class="compressed-navbar">
		<div class="flex-container">
			<button mat-button class="link-btn" routerLinkActive="active" [matMenuTriggerFor]="showroomAll">
				<img src="../../../assets/media/icons/bars-solid.svg" alt="burger-menu" style="max-width: 1em;">
			</button>
			<mat-menu #showroomAll="matMenu">
				<a class="menu-button" [ngClass]="{'active_bg': navbarElements.Showroom.value}" mat-menu-item routerLink="/showroom" [matMenuTriggerFor]="showroomMenu">
					Showroom
				</a>
				<a class="menu-button" [ngClass]="{'active_bg': navbarElements.Community.value}" mat-menu-item routerLink="/community" [matMenuTriggerFor]="communityMenu">
					Community
				</a>
				<a class="menu-button" [ngClass]="{'active_bg': navbarElements.Brokerage.value}" mat-menu-item routerLink="/brokerage">
					Brokerage
				</a>
			</mat-menu>
		</div>
		<app-authentication [active]=navbarElements.SignUp.value style="position: absolute; top: .5em; right: 0em; height: 0;"></app-authentication>
		<div class="navbar-logo navbar-logo-compressed" (click)="href('https://smartees.eu/')" routerLinkActive="active"></div>
	</mat-toolbar-row>

	<!-- Expanded Navbar -->
	<mat-toolbar-row class="expanded-navbar">
		<div class="flex-container" >
			
			<button mat-button class="link-btn" [ngClass]="{'active': navbarElements.Showroom.value}" routerLink="/showroom" [matMenuTriggerFor]="showroomMenu">Showroom</button>
			<mat-menu #showroomMenu="matMenu">
				<a class="menu-button"   mat-menu-item  [matMenuTriggerFor]="technologyCategoryMenu">
					Technology Categories
				</a>
				<a class="menu-button"   mat-menu-item  [matMenuTriggerFor]="applicationTypeMenu">
					Application Types
				</a>
			</mat-menu>
			<mat-menu #technologyCategoryMenu="matMenu">
				<a class="menu-button" *ngFor="let technologyCategoryNavHomeViewModel of viewModel.technologyCategoriesNavHomeViewModel" 
				mat-menu-item routerLink="/showroom/technologies/{{technologyCategoryNavHomeViewModel.technologyCategoryId}}">
					Bringing {{technologyCategoryNavHomeViewModel.technologyCategoryName}}
				</a>
			</mat-menu>
			<mat-menu #applicationTypeMenu="matMenu">
				<button class="menu-button" *ngFor="let applicationTypeViewModel of viewModel.applicationTypesViewModel"
						mat-menu-item routerLink="/showroom/applicationTypes/{{applicationTypeViewModel.applicationTypeId}}" routerLinkActive="active">
					{{applicationTypeViewModel.applicationTypeName}}
				</button>
			</mat-menu>

			<button mat-button class="link-btn" [ngClass]="{'active': navbarElements.Community.value}" routerLink="/community" routerLinkActive="active" [matMenuTriggerFor]="communityMenu">
				Community</button>
			
				<mat-menu #communityMenu="matMenu">
					<a class="menu-button"   mat-menu-item  href="#" routerLink="/keyFigures">
						Key Figures
					</a>
					<a class="menu-button"   mat-menu-item  routerLink="/organizations">
							Qualified Directory
					</a>
				</mat-menu>

			<button mat-button class="link-btn" [ngClass]="{'active': navbarElements.Brokerage.value}" routerLink="/brokerage" routerLinkActive="active">Brokerage</button>
		</div>
		

		<span class="spacer"></span>

		<app-authentication [active]=navbarElements.SignUp.value></app-authentication>

		<div class="navbar-logo" (click)="href('https://smartees.eu/')" routerLinkActive="active"></div>



	</mat-toolbar-row>
	<mat-toolbar-row>
			<span *ngFor="let elem of breadCrumbElements; let i = index"><span class="custom-breadcrumb" [class.custom-breadcrumb-active]="elem.uri!=null && breadCrumbElements.length > i+1" (click)="navigate(i)">{{elem.label}}</span> <span class="custom-breadcrumb-separator" >{{breadCrumbElements.length == i+1? "" : "/"}}</span> </span>
	</mat-toolbar-row>
</mat-toolbar>