/**
 * Represents an Location
 */
export class Location {
	public id:number;

	public organization: number;

	public country: string;

	public city: string;

	public latitude: number;

	public longitude: number;
}
