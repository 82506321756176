/**
 * Represents an Location
 */
export class Country {

	public name: string;

	public iso_A2: string;

	public un_A3: string;

	public num: number;

	public dialing: number;
}