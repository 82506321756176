import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MatSnackBar } from "@angular/material";

import { AuthenticationService } from "../../services";

import { Organization } from "../../../entity/Organization";
import { OrganizationService } from "../../services/OrganizationService";

@Component({
	selector: "organization-view",
	templateUrl: "./organization.component.html",
	styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit {

	public organization: Organization;
	public markdownContent: string;

	private constructor(private activatedRoute: ActivatedRoute, private authentication: AuthenticationService, 
		private snackBar: MatSnackBar, private http: HttpClient, private organizationService: OrganizationService) { }

	public ngOnInit(): void {
		this.ngOnInitAsync().catch((reason: Error) => {
			console.error(reason);
			this.snackBar.open(reason.name, "Close", {
				duration: 5000,
			});
		});
	}
    
	isAuthenticated() {
		return this.authentication.isAuthenticated();
	}

	private async ngOnInitAsync(): Promise<void> {
		this.activatedRoute.params.subscribe(async (params: Params) => {
			const organizationId: number = parseInt(params.id, 10);
			this.organization = await this.organizationService.getItem(organizationId);

			console.log(this.organization.logo);
			this.organizationService.dynamicContent(this.organization.description).subscribe((data: any) => {
				this.markdownContent = data;
			});

		});
	}

	private markdownContentURL() {
	  return (this.markdownContent !== undefined || this.organization === undefined) ? null : this.organization.description;
  }

}
